import CryptoJS from 'crypto-js';
const secretKey = 'QAPlatform-2024';
import cookie from "js-cookie";

const state = {
    token: cookie.get('qa_token'),
    // userInfo: JSON.parse(window.localStorage.getItem('userInfo') || '{}'),
    userInfo: getAndDecryptData() || {},
    //主题
    theme: window.localStorage.getItem('theme') || 'light',
    otherUserInfo:{},
    //当前对话用户id
    acceptId:'',
    //当前用户消息的未读状态
    hasUnreadMessage:window.localStorage.getItem('hasUnreadMessage') || false,
}

function getAndDecryptData() {
    const encryptedData = window.localStorage.getItem('userInfo');
    if (encryptedData) {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8) || '{}' );
       return decryptedData;
    }
    return null;
}

const mutations = {
    login(state, data) {
        state.token = data.token
        state.userInfo = data.userInfo
        let dataString = JSON.stringify(data.userInfo)
        //加密存储用户信息,防止被篡改，使用
        const encryptedData = CryptoJS.AES.encrypt(dataString, secretKey).toString();
        // console.log("存放在store的用户信息为：", dataString)
        window.localStorage.setItem('userInfo', encryptedData)
        cookie.set('qa_token', data.token, {expires: 1/4})
    },
    logout(state) {
        state.token = ''
        state.userInfo = ''
        window.localStorage.setItem('userInfo', '')
        cookie.set('qa_token', '')
    }
}

const actions = {
    LOGIN({commit}, data) {
        commit('login', data)
    },
    LOGOUT({commit}, data) {
        commit('logout', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
