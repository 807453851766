<template>
  <div class="app-container">
    <el-input v-model="question" placeholder="请输入问题"></el-input>
    <el-input v-model="scene" placeholder="请输入场景"></el-input>
    <el-button @click="getText">获取</el-button>
    <div>{{text}}</div>
</div>
</template>

<script>


import axios from "axios";
import cookie from "js-cookie";

export default {
  data() {
    return {
      text: '',
      question: '',
      scene: '',
    };
  },
  created() {
  },
  methods: {
    async getText() {
      //"http://172.16.28.43:82/inf/http/app/robot/controller/askQuestion?question=+"+this.question+"&scene="+this.scene
      // const resp = await axios("http://172.16.30.24:19013/ida50/robot/app/askQuestion?question=+"+this.question+"&scene="+this.scene, {
      //   method: 'GET',
      //   headers: {
      //     'x-auth-token':"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJjY2EzMWM2NWViM2I0MDg4YjM1NDQ1MDU1ZGZhMDM0ZiIsImxvZ2luTmFtZSI6InNhIiwiZ3JheVJlZ2lvbiI6IiIsImV4cCI6MTcxMzQ0NDU5MiwibmJmIjoxNzEzNDIyOTkyfQ.oB_MTOxadHHK1Sc6DYUhUnTqDP61ap2DUzfKQIbyN5I"
      //   },
      // })
      //流式请求
      // const res = await fetch("https://v1.hitokoto.cn/", {
      //   method: 'GET',
      //   dataType: "text/event-stream",
      //   // headers: {
      //   //   'Content-Type': 'application/json',
      //   //   'token': cookie.get('qa_token')
      //   // },
      //   // body: JSON.stringify(toAiObj)
      // })
      // const reader = res.body.getReader()
      // let decoder = new TextDecoder();
      // let flag = true;
      // while (flag) {
      //   const {done, value} = await reader.read();
      //   if (done) {
      //     flag = false;
      //     break;
      //   }
      //   const txt = decoder.decode(value);
      //   console.log(txt);
      //   this.text += txt;
      // }
      await axios.get("https://v1.hitokoto.cn/")
        .then((res) => {
          //2个字 2个字输出
          const result = res.data.hitokoto;
          let i = 0;
          let timer = setInterval(() => {
            if (i < result.length) {
              this.text += result[i];
              i++;
            } else {
              clearInterval(timer);
            }
          }, 1000)

        })
        .catch((err) => {
          console.log(err);
        })
    }
  }
};
</script>
