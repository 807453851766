var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-page",style:('background: url('+ _vm.bgUrl +')')},[_c('div',{staticClass:"wrapper hor-ver-center"},[(_vm.isLoginState)?_c('el-form',{ref:"loginForm",staticClass:"login-form",attrs:{"model":_vm.loginInfo}},[_c('div',{staticClass:"avatar"},[_c('el-avatar',{attrs:{"size":100,"src":_vm.loginInfo.avatar},on:{"error":()=>true}},[_c('img',{attrs:{"src":"https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"}})])],1),_c('el-form-item',{attrs:{"prop":"account","rules":[
          { required: true, message: '请输入账号', trigger: ['blur', 'change']},
          { min: 3, max: 8, message: '长度在 3 到 8 个字符', trigger: ['blur', 'change'] }
      ]}},[_c('el-input',{attrs:{"autocomplete":"new-password","prefix-icon":"el-icon-user","placeholder":"请输入账号"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}},model:{value:(_vm.loginInfo.account),callback:function ($$v) {_vm.$set(_vm.loginInfo, "account", $$v)},expression:"loginInfo.account"}})],1),_c('el-form-item',{attrs:{"prop":"password","rules":[
          { required: true, message: '请输入密码', trigger: ['blur', 'change']},
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: ['blur', 'change'] }
      ]}},[_c('el-input',{attrs:{"autocomplete":"new-password","type":"password","prefix-icon":"el-icon-lock","placeholder":"请输入密码"},model:{value:(_vm.loginInfo.password),callback:function ($$v) {_vm.$set(_vm.loginInfo, "password", $$v)},expression:"loginInfo.password"}})],1),_c('el-form-item',{staticClass:"cv-code",attrs:{"prop":"cvCode","rules":[
          { required: true, message: '请输入验证码', trigger: ['blur', 'change']},
          { min: 4, max: 4, message: '长度为 4 个字符', trigger: ['blur', 'change'] }
      ]}},[_c('el-input',{staticClass:"cv-code-inp",attrs:{"autocomplete":"on","prefix-icon":"el-icon-lock","placeholder":"验证码(不区分大小写)"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}},model:{value:(_vm.loginInfo.cvCode),callback:function ($$v) {_vm.$set(_vm.loginInfo, "cvCode", $$v)},expression:"loginInfo.cvCode"}}),_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(!_vm.cvCodeIng),expression:"!cvCodeIng"}],ref:"loginCanvas",attrs:{"width":"120","height":"40"},on:{"click":_vm.getCVCode}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.cvCodeIng),expression:"cvCodeIng"}],staticStyle:{"width":"200px"},on:{"click":_vm.getCVCode}},[_vm._v("获取中...")])],1),_c('el-form-item',[_c('el-button',{staticClass:"login-btn",attrs:{"type":"primary"},on:{"click":_vm.login}},[_vm._v("登录")]),_c('span',[_vm._v("没有账号？"),_c('span',{staticClass:"operation-text",staticStyle:{"display":"inline"},on:{"click":function($event){return _vm.changeState(false)}}},[_vm._v("注册")])])],1)],1):_vm._e(),(!_vm.isLoginState)?_c('el-form',{ref:"registForm",staticClass:"register-form",attrs:{"model":_vm.registerInfo}},[_c('div',{staticClass:"avatar"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"点击切换头像","placement":"top"}},[_c('img',{staticStyle:{"border-radius":"50%","cursor":"pointer"},attrs:{"src":_vm.registerInfo.avatar,"alt":"","srcset":"","width":"100","height":"100"},on:{"click":_vm.chooseAvatar}})])],1),_c('el-form-item',{attrs:{"prop":"account","rules":[
              { required: true, message: '请输入账号', trigger: ['blur', 'change']},
              { min: 3, max: 8, message: '长度在 3 到 8 个字符', trigger: ['blur', 'change'] }
          ]}},[_c('el-input',{attrs:{"type":"text","autocomplete":"new-password","prefix-icon":"el-icon-user","placeholder":"请输入账号"},model:{value:(_vm.registerInfo.account),callback:function ($$v) {_vm.$set(_vm.registerInfo, "account", $$v)},expression:"registerInfo.account"}})],1),_c('el-form-item',{attrs:{"prop":"password","rules":[
          { required: true, message: '请输入密码', trigger: ['blur', 'change']},
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: ['blur', 'change'] }
      ]}},[_c('el-input',{attrs:{"type":"text","autocomplete":"new-password","onfocus":"this.type = 'password'","prefix-icon":"el-icon-lock","placeholder":"请输入密码"},model:{value:(_vm.registerInfo.password),callback:function ($$v) {_vm.$set(_vm.registerInfo, "password", $$v)},expression:"registerInfo.password"}})],1),_c('el-form-item',{attrs:{"prop":"rePassword","rules":[
          { required: true, message: '请确认密码', trigger: ['blur', 'change']},
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: ['blur', 'change'] }
      ]}},[_c('el-input',{attrs:{"type":"text","autocomplete":"new-password","onfocus":"this.type = 'password'","prefix-icon":"el-icon-lock","placeholder":"请确认密码"},model:{value:(_vm.registerInfo.rePassword),callback:function ($$v) {_vm.$set(_vm.registerInfo, "rePassword", $$v)},expression:"registerInfo.rePassword"}})],1),_c('el-form-item',{staticClass:"cv-code",attrs:{"rules":[
          { required: true, message: '请输入验证码', trigger: ['blur', 'change']},
          { min: 4, max: 4, message: '长度为 4 个字符', trigger: ['blur', 'change'] }
      ],"prop":"cvCode"}},[_c('el-input',{staticClass:"cv-code-inp",attrs:{"prefix-icon":"el-icon-lock","placeholder":"验证码(不区分大小写)"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.register.apply(null, arguments)}},model:{value:(_vm.registerInfo.cvCode),callback:function ($$v) {_vm.$set(_vm.registerInfo, "cvCode", $$v)},expression:"registerInfo.cvCode"}}),_c('canvas',{ref:"registerCanvas",attrs:{"width":"120","height":"40"},on:{"click":_vm.getCVCode}})],1),_c('el-form-item',{staticClass:"oper"},[_c('el-button',{staticClass:"login-btn",attrs:{"type":"primary"},on:{"click":_vm.register}},[_vm._v("注册")]),_c('span',[_vm._v("已有账号？"),_c('span',{staticClass:"operation-text",staticStyle:{"display":"inline"},on:{"click":function($event){return _vm.changeState(true)}}},[_vm._v("登录")])])],1)],1):_vm._e()],1),_c('copy-right')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }