<template>
  <div class="self-column">
    专栏
  </div>

</template>
<script>
export default {
  data() {
    return {

    }
  }
}
</script>
<style scoped>

.self-column{
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
}


</style>
