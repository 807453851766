<template>
  <div class="test" style="height:100vh;display: flex;">
    <!--侧边栏-->
    <!--侧边栏-->
    <sidebar class="sidebar" :class="{showSideBar:tohide!=true}"></sidebar>
    <div style="width: 100%; overflow-y:auto;" :class="{darkBg:theme=='dark',lightBg:theme=='light'}">
      <!--顶部栏-->
      <div class="topbar" :theme="theme">
        <!--顶部栏-->
        <div class="topbar-inner">
          <el-menu :default-active="$route.path" class="el-menu-demo topbar-left" mode="horizontal"
                   @select="handleSelect" :background-color="menuBackgroundColor"
                   :text-color="menuTextColor"
                   :active-text-color="menuActiveTextColor"
                   style="border-bottom: none">
            <el-menu-item index="/square/index">主页</el-menu-item>
            <el-menu-item index="/square/passage">文章</el-menu-item>
            <el-menu-item index="/square/resource">资源</el-menu-item>
            <el-menu-item index="/square/resume">简历</el-menu-item>
            <el-menu-item index="/square/interview">面经</el-menu-item>
          </el-menu>
          <div class="smallmenu">
            <el-dropdown placement="bottom-start" trigger="click">
              <span class="el-dropdown-link">
                <i class="icon-caidan1 iconfont" style="font-size: 20px"></i>
              </span>
              <el-dropdown-menu slot="dropdown" :class="{dropdownMenu:theme=='dark'}">
                <el-dropdown-item icon="el-icon-s-home" @click.native="handleSelect('/square/index')">主页
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-collection" @click.native="handleSelect('/square/passage')">文章
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-paperclip" @click.native="handleSelect('/square/resource')">资源
                </el-dropdown-item>
                <el-dropdown-item icon="iconfont icon-yulanjianli" @click.native="handleSelect('/square/resume')">简历
                </el-dropdown-item>
                <el-dropdown-item icon="iconfont icon-mianshitiku" @click.native="handleSelect('/square/interview')">面经
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-s-promotion" @click.native="handleSelect('/square/publish')">发布
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-search" @click.native="handleSelect('/square/search')">搜索
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="topbar-right" :class="{darkSearch:theme=='dark'}">
            <!--加一个搜索框输入框-->
            <el-input v-if="showTopbarSearchBox" placeholder="全站搜索" v-model="topbarsearchText"
                      class="topbarsearchInput" @keydown.native.enter="handleSearch">
              <el-button slot="append" icon="el-icon-search" type="primary" class="topbarsearchButton"
                         @click="handleSearch()"></el-button>
            </el-input>

            <!--发布按钮-->
            <el-button type="primary" icon="el-icon-edit" @click="handleSelect('/square/publish')" class="publishButton"
                       size="medium">发布
            </el-button>
            <!--个人头像，如果没有登录则点击跳转到登录页面-->
            <el-dropdown trigger="click" v-if="userInfo.avatar">
            <span class="el-dropdown-link">
              <!--未读消息-->
              <el-badge is-dot v-if="hasUnreadMessage=='true'">
                <el-avatar
                    shape="square"
                    :src="userInfo.avatar"
                    alt="avatar"
                >
              </el-avatar>
              </el-badge>
              <!--已读消息-->
              <el-avatar
                  v-else
                  shape="square"
                  :src="userInfo.avatar"
                  alt="avatar"
              >
              </el-avatar>
            </span>
              <el-dropdown-menu slot="dropdown" :class="{dropdownMenu:theme=='dark'}" class="indexdropdownMenu">
                <el-dropdown-item @click.native="toSelf()">
                  <i class="iconfont item icon-shouye"></i>
                  <span>我的</span>
                </el-dropdown-item>
                <el-dropdown-item @click.native="daka()">
                  <i class="iconfont item icon-qiandao"></i>
                  <span>打卡</span>
                </el-dropdown-item>
                <el-dropdown-item @click.native="toMess()">
<!--                  <i class="iconfont icon-duihua item"></i>-->
<!--                  <span>对话</span>-->
                  <el-badge is-dot v-if="hasUnreadMessage=='true'">
                      <i class="iconfont icon-duihua item" ></i>
                      <span>对话</span>
                  </el-badge>
                  <template v-else>
                      <i class="iconfont icon-duihua item"></i>
                      <span>对话</span>
                  </template>
                </el-dropdown-item>

                <el-dropdown-item @click.native="logout()">
                  <i class="el-icon-switch-button"></i>
                  <span>退出</span>
                </el-dropdown-item>


              </el-dropdown-menu>
            </el-dropdown>
            <!--去登录-->

            <el-tooltip v-else class="item" effect="dark" content="登录体验更多功能" placement="bottom-end">
              <el-avatar
                  shape="square"
                  alt="avatar"
                  @click.native="toLogin()"
                  class="loginAvatar"
              >
                登录
              </el-avatar>
            </el-tooltip>
          </div>
        </div>
      </div>
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <div class="backSideBarRight" @click="openSideBar()">
      <button style="background: none;border: none;z-index: 1501">
        <i class="iconfont icon-jiantou_yemian_xiangyou" style="color: deepskyblue" :class="{hide:tohide!=true}"></i>
      </button>
    </div>
    <div class="backSideBarLeft" @click="openSideBar()">
      <button style="background: none;border: none;z-index: 1501">
        <i class="iconfont icon-jiantou_yemian_xiangzuo" style="color: deepskyblue" :class="{hide:tohide==true}"></i>
      </button>
    </div>
  </div>
</template>
<script>


import sidebar from "@/components/sidebar";
import userApi from "@/api/user";
export default {
  components: {
    sidebar
  },
  data() {
    return {
      hasUnreadMessage: false,
      tohide: false,
      showTopbarSearchBox: true,
      theme: 'light',
      topbarsearchText: '',
      inputDisplay: '',
      menuTextColor: '',
      menuBackgroundColor: '',
      menuActiveTextColor: '',
    }
  },
  mounted() {

  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    }
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
        if (val == 'dark') {
          this.menuTextColor = '#fff'
          this.menuBackgroundColor = 'rgb(17,17,17)'
          this.menuActiveTextColor = '#409EFF'
        } else {
          this.menuTextColor = ''
          this.menuBackgroundColor = ''
          this.menuActiveTextColor = ''
        }
      },
      immediate: true
    },
    '$store.state.user.hasUnreadMessage': {
      handler: function (val) {
          this.hasUnreadMessage = val;
      },
      immediate: true
    }
  },
  methods: {
    daka(){
      //用户分数+10
      userApi.addScore().then(res=>{
        if (res.data.success) {
          this.$message({
            type: 'success',
            message: '打卡成功，积分+10'
          })
        }else{
          this.$message({
            type: 'warning',
            message: res.data.message
          })
        }
      })
    },
    openSideBar() {
      this.tohide = !this.tohide;
    },
    toLogin() {
      this.$router.push({
        path: '/login'
      });
    },
    toSelf() {
      this.$router.push({
        path: '/self/info'
      });
    },
    toMess() {
      this.$router.push({
        path: '/mess/index'
      });
    },
    logout() {
      this.$confirm('确定退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'logout-message-box'
      }).then(() => {
        //更新在线状态
        userApi.updateOnlineStatus(0).then(res=>{
          userApi.updateOnlineStatus(0).then(res=>{
            this.$message({
              type: "success",
              duration: 1000,
              message: "登出成功！",
            });
            this.$router.replace("/login");
            this.$store.dispatch("user/LOGOUT");
            if (!res.data.success){
              console.log(res);
            }
          })
          if (!res.data.success){
            console.log(res);
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          duration: 1000,
          message: '已取消退出'
        });
      });
    },
    handleSelect(path) {
      this.$router.push({
        path: path
      });
    },
    handleSearch() {
      if (this.topbarsearchText === '' || this.topbarsearchText === undefined || this.topbarsearchText === null) {
        this.$message({
          message: '搜索内容不能为空',
          type: 'warning'
        });
        return;
      }
      this.$router.push({
        path: '/search/passage',
        query: {
          searchText: this.topbarsearchText
        }
      });
    },
  }
}
</script>
<style lang="scss">
.el-badge__content.is-fixed.is-dot {
  right: 5px !important;
  top: 3px !important;
}
.indexdropdownMenu .el-badge__content.is-fixed.is-dot {
  right: 5px !important;
  top: 8px !important;
}
.el-dropdown-link{
  cursor: pointer;
}



.el-popper.dropdownMenu {
  background-color: rgb(17, 17, 17);
  border: 1px solid #fff;

  .el-dropdown-menu__item {
    color: #fff !important;
  }



  .popper__arrow::after {
    border-bottom-color: rgb(17, 17, 17) !important;
    border-top-color: rgb(17, 17, 17) !important;
  }

  .el-dropdown-menu__item:not(.is-disabled):hover {
    background: #40a8ee !important;
  }
}
</style>
<style scoped lang="scss">
.darkSearch /deep/ .el-input__inner {
  background-color: black;
  border: rgb(62, 62, 62) solid 1px !important;
  color: white;
}

.darkSearch /deep/ .el-input-group__append {
  background-color: black;
  border: rgb(62, 62, 62) solid 1px !important;
}

.darkSearch /deep/ .el-input-group__append :hover {
  background-color: rgba(0, 0, 0, 0);
  color: #1589fa;
}

.darkBg {
  background-color: rgb(34, 34, 34);
}

.lightBg {
  background-color: #f5f5f5;
}


.topbar-inner {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.sidebar {
  z-index: 1501;
}

.showSideBar {
  display: block !important;
}

.hide {
  display: none;
}

.item {
  margin-right: 5px;
}

a {
  text-decoration: none;
}

/* 改变elementui 侧边栏移入颜色 */
/*.el-menu-item:hover{*/
/*  background: rgb(142, 166, 236) !important;*/
/*}*/

.backSideBarRight {
  position: absolute;
  top: 300px;
  left: -10px;
  display: none;
  z-index: 1501;
}

.backSideBarLeft {
  position: absolute;
  top: 300px;
  left: 70px;
  z-index: 1501;
  display: none;
}

.topbar[theme='light'] {
  /*border-bottom: 1px solid #ebeef5;*/
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, .12);
  height: 60px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 1501;
  background-color: #ffffff;
  width: 100%;
}

.topbar[theme='dark'] {
  /*border-bottom: 1px solid #ebeef5;*/
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, .12);
  height: 60px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  color: #ffffff;
  z-index: 1501;
  background-color: rgb(17, 17, 17);
  width: 100%;
}


.topbar-left {
  height: 100%;
}

.topbar-right {
  float: right;
  height: 60px;
  display: flex;
  align-items: center;
}

.topbarsearchInput {
  width: 500px;
  margin-right: 20px;
}

.topbarsearchButton {
  /*background-color: rgb(24, 144, 255) !important;*/
  border-radius: 0 !important;
}

.topbarsearchButton:hover {
  background-color: rgb(214, 217, 220) !important;
}

.smallmenu {
  display: none;
}


.publishButton {
  margin-right: 20px;
}

.loginAvatar {
  margin-right: 20px;
}

.loginAvatar:hover {
  cursor: pointer;
}


@media screen and (max-width: 1070px) {
  .topbar-left {
    display: none;
  }

  .publishButton {
    display: none;
  }

  .smallmenu {
    display: flex;
    align-items: center;

  }
}


@media screen and (max-width: 768px) {
  .topbarsearchInput {
    display: none;
  }

  .sidebar {
    display: none;
    z-index: 1501;
  }

  .backSideBarRight {
    display: block;
  }

  .backSideBarLeft {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .logout-message-box {
    width: 100px !important;
  }
}
</style>
