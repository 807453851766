<template>
  <div class="test" style="height:100vh;display: flex;">
    <!--侧边栏-->
    <sidebar class="sidebar" :class="{showSideBar:tohide!=true}"></sidebar>
    <div style="width: 100%;overflow-y:auto;" :class="{darkBg:theme=='dark',lightBg:theme=='light'}">
      <!--顶部栏-->
      <div class="admin-tags" :class="{darkTab:theme=='dark'}" style="margin-bottom: 30px">
        <el-tabs v-model="activeName" type="card" :stretch="true" @tab-click="handleClick">
          <el-tab-pane
              :name="tag.path"
              style="background-color: #fff !important;"
              v-for="tag in admintags"
              :key="tag.id"
              :label="tag.tagName"
          >
          </el-tab-pane>
        </el-tabs>
      </div>
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <div class="backSideBarRight"  @click="openSideBar()">
      <button style="background: none;border: none;z-index: 999">
        <i class="iconfont icon-jiantou_yemian_xiangyou" style="color: deepskyblue" :class="{hide:tohide!=true}"></i>
      </button>
    </div>
    <div class="backSideBarLeft"  @click="openSideBar()" >
      <button style="background: none;border: none;z-index: 999">
        <i class="iconfont icon-jiantou_yemian_xiangzuo" style="color: deepskyblue" :class="{hide:tohide==true}"></i>
      </button>
    </div>
  </div>
</template>
<script>

import cookie from "js-cookie";
import sidebar from "@/components/sidebar";

export default {
  components: {
    sidebar
  },
  data() {
    return {
      activeName:'/admin/user',
      admintags:[
        {
          id:"1",
          tagName:'用户管理',
          path:'/admin/user'
        },
        {
          id:"2",
          tagName:'文章管理',
          path:'/admin/article'
        },
        {
          id:"3",
          tagName:'AI管理',
          path:'/admin/ai'
        },
        {
          id:"4",
          tagName:'标签管理',
          path:'/admin/tag'
        },
        {
          id:"5",
          tagName:'评论管理',
          path:'/admin/comment'
        },
        {
          id:"6",
          tagName:'收藏管理',
          path:'/admin/favorites'
        },
        {
          id:"7",
          tagName:'点赞管理',
          path:'/admin/like'
        },
        {
          id:"8",
          tagName:'关注管理',
          path:'/admin/follow'
        },
        {
          id:"9",
          tagName:'文章标签管理',
          path:'/admin/articleTag'
        },
        {
          id:"10",
          tagName:'得分管理',
          path:'/admin/score'
        },
        {
          id:"11",
          tagName:'待办事项管理',
          path:'/admin/todo'
        },
        {
          id:"12",
          tagName:'会话管理',
          path:'/admin/session'
        },
        {
          id:"13",
          tagName:'聊天记录管理',
          path:'/admin/record'
        },
      ],
      theme:'light',
      tohide: false,
      model: '夜间',
      isNight: false,
      menuBackgroundColor: '',
      menuTextColor:'',
      menuActiveTextColor: '',
    }
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  created() {
    this.activeName = this.$route.path.substring(0)
  },
  methods: {
    handleClick(tab){

      this.$router.push({
        path: tab.name
      });
    },
    openSideBar(){
      this.tohide = !this.tohide;
    },
  }
}
</script>
<style scoped>
.darkBg {
  background-color: rgb(17,17,17);
}


.item{
  margin-right: 5px;
}
a{
  text-decoration: none;
}
/* 改变elementui 侧边栏移入颜色 */
/*.el-menu-item:hover{*/
/*  background: rgb(142, 166, 236) !important;*/
/*}*/

.hide{
  display: none;
}
.backSideBarRight{
  position: absolute;
  top:300px;
  left: -10px;
  display: none;
  z-index: 999;
}
.backSideBarLeft{
  position: absolute;
  top:300px;
  left: 70px;
  z-index: 999;
  display: none;
}
.showSideBar{
  display: block !important;
}

.hide{
  display: none;
}
@media screen and (max-width: 768px) {

  .sidebar{
    display: none;
    z-index: 99;

  }
  .backSideBarRight{
    display: block;
  }
  .backSideBarLeft{
    display: block;
  }
}
</style>
