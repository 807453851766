<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="文章分类id" prop="categoryId">
        <el-select v-model="queryParams.categoryId" placeholder="请选择文章分类id" clearable>
          <el-option
            v-for="dict in sys_article_type"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="作者id" prop="userId">
        <el-input
          v-model="queryParams.userId"
          placeholder="请输入作者id"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="文章标题" prop="title">
        <el-input
          v-model="queryParams.title"
          placeholder="请输入文章标题"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="逻辑删除" prop="isDeleted">
        <el-select v-model="queryParams.isDeleted" placeholder="请选择逻辑删除" clearable>
          <el-option
            v-for="dict in sys_logic_deleted"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </el-col>

    </el-row>

    <el-table v-loading="loading" :data="articleList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键" align="center" prop="id" />
      <el-table-column label="文章分类id" align="center" prop="categoryId">
        <template slot-scope="scope">
          <el-tag>{{scope.row.categoryId==1?'文章':scope.row.categoryId==2?'资源':scope.row.categoryId==3?'简历':'面经'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="作者id" align="center" prop="userId" />
      <el-table-column label="文章标题" align="center" prop="title" />
      <el-table-column label="文章描述" align="center" prop="description" />
      <el-table-column label="封面" align="center" prop="cover" width="100">
        <template slot-scope="scope">
          <el-image :src="scope.row.cover" :preview-src-list="[scope.row.cover]" :width="50" :height="50"/>
        </template>
      </el-table-column>
      <el-table-column label="浏览次数" align="center" prop="viewCount" />
      <el-table-column label="点赞次数" align="center" prop="likeCount" />
      <el-table-column label="评论数" align="center" prop="commentCount" />
      <el-table-column label="收藏数" align="center" prop="collectCount" />
      <el-table-column label="pdf附件地址" align="center" prop="attachmentAddress" />
      <el-table-column label="逻辑删除" align="center" prop="isDeleted">
        <template slot-scope="scope">
          <el-tag>
            {{scope.row.isDeleted==0?'正常':'删除'}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-pagination
        v-if="articleList.length"
        :small="true"
        :pager-count="pageCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParams.pageSize"
        :layout="layout"
        :total="total">
    </el-pagination>

    <!-- 添加或修改用户文章管理对话框 -->
    <el-dialog :title="title" :visible.sync="open"  append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="文章分类id" prop="categoryId">
          <el-select v-model="form.categoryId" placeholder="请选择文章分类id">
            <el-option
              v-for="dict in sys_article_type"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="作者id" prop="userId">
          <el-input v-model="form.userId" placeholder="请输入作者id" />
        </el-form-item>
        <el-form-item label="文章标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入文章标题" />
        </el-form-item>
        <el-form-item label="文章描述" prop="description">
          <el-input v-model="form.description" type="textarea" placeholder="请输入内容" />
        </el-form-item>
        <el-form-item label="文章内容">
          <Editor
              :key="form.id"
              class="editos"
              :class="{darkEditor:theme=='dark'}"
              :plugins="plugins"
              :locale="zhHans"
              @change="handleChange"
              :uploadImages="uploadImage"
              :value="form.content"
          />
        </el-form-item>
        <el-form-item label="封面" prop="cover">
          <!--图片上传-->
          <el-upload
              class="avatar-uploader"
              action="https://47.120.61.197/oss/uploadOssFile"
              :show-file-list="false"
              :headers="{token: token}"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <el-image fit="contain" v-if="form.cover" :src="form.cover" class="avatar"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="浏览次数" prop="viewCount">
          <el-input v-model="form.viewCount" placeholder="请输入浏览次数" />
        </el-form-item>
        <el-form-item label="点赞次数" prop="likeCount">
          <el-input v-model="form.likeCount" placeholder="请输入点赞次数" />
        </el-form-item>
        <el-form-item label="评论数" prop="commentCount">
          <el-input v-model="form.commentCount" placeholder="请输入评论数" />
        </el-form-item>
        <el-form-item label="收藏数" prop="collectCount">
          <el-input v-model="form.collectCount" placeholder="请输入收藏数" />
        </el-form-item>
        <el-form-item label="置顶" prop="top">
          <el-input v-model="form.top" placeholder="请输入置顶" />
        </el-form-item>
        <el-form-item label="pdf附件地址" prop="attachmentAddress" v-if="form.categoryId==3">
          <!--简历上传-->
          <el-upload
              class="upload-demo"
              action="https://47.120.61.197/oss/uploadOssFile"

              :limit="1"
              :on-success="handlePdfSuccess"
              :before-upload="beforePdfUpload"
              :on-exceed="handleExceed"
              :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传pdf文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="逻辑删除" prop="isDeleted">
          <el-select v-model="form.isDeleted" placeholder="请选择逻辑删除">
            <el-option
              v-for="dict in sys_logic_deleted"
              :key="dict.value"
              :label="dict.label"
              :value="parseInt(dict.value)"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listArticle, getArticle, delArticle, addArticle, updateArticle } from "@/api/admin/article";
import 'bytemd/dist/index.css'  // 导入编辑器样式
import zhHans from 'bytemd/locales/zh_Hans.json'
import {Editor} from '@bytemd/vue'
import gfm from '@bytemd/plugin-gfm'
import highlightssr from '@bytemd/plugin-highlight-ssr'
import highlight from '@bytemd/plugin-highlight'
import breaks from '@bytemd/plugin-breaks'
import footnotes from '@bytemd/plugin-footnotes'
import frontmatter from '@bytemd/plugin-frontmatter'
import gemoji from '@bytemd/plugin-gemoji'
import math from '@bytemd/plugin-math'
import mermaid from '@bytemd/plugin-mermaid'
import gfm_zhHans from '@bytemd/plugin-gfm/locales/zh_Hans.json'
import math_zhHans from '@bytemd/plugin-math/locales/zh_Hans.json'
import mermaid_zhHans from '@bytemd/plugin-mermaid/locales/zh_Hans.json'
// import mediumZoom from '@bytemd/plugin-medium-zoom' //图片放大预览


import 'highlight.js/styles/vs.css'
import 'juejin-markdown-themes/dist/juejin.min.css'
import ossApi from "@/api/oss";  // 其实就是需要这个css文件

const gfmlocale = {
  locale: gfm_zhHans,
}

const mathlocale = {
  locale: math_zhHans,
}

const mermaidlocale = {
  locale: mermaid_zhHans,
}


const plugins = [
  // 将所有的扩展功能放入插件数组中，然后就可以生效了
  gfm(gfmlocale),
  highlight(),
  highlightssr(),
  breaks(),
  frontmatter(),
  footnotes(),
  gemoji(),
  math(mathlocale),
  mermaid(mermaidlocale),
  // mediumZoom()
]

export default {
  components: {
    Editor,
  },
  data() {

    return {
      fileList:[],
      theme: 'light',
      zhHans,
      plugins,
      pageCount:9,
      token: this.$store.state.user.token,
      layout:'total, sizes, prev, pager, next, jumper',
      sys_article_type: [
        { label: "文章", value: 1 },
        { label: "资源", value: 2 },
        { label: "简历", value: 3 },
        { label: "面经", value: 4 },
      ],
      sys_logic_deleted: [
        { label: "正常", value: 0 },
        { label: "删除", value: 1 },
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 用户文章管理表格数据
      articleList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        categoryId: null,
        userId: null,
        title: null,
        content: null,
        isDeleted: null
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      }
    };
  },
  created() {
    this.getList();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  methods: {
    beforePdfUpload(file) {
      const isPdf = file.type === 'application/pdf';
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isPdf) {
        this.$message.error('上传简历只能是 PDF 格式!');
      }
      if (!isLt10M) {
        this.$message.error('上传简历大小不能超过 10MB!');
      }
      return isPdf && isLt10M;
    },
    handlePdfSuccess(res, file) {
      this.fileList.push({name: file.name, url: res.data.url});
      this.articleInfo.attachmentAddress = res.data.url;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 获取书写文档内容
    handleChange(v) {
      this.form.content = v
    },
    // 上传图片 点击触发上传图片事件，大家获取文件把图片上传服务器然后返回url既可
    async uploadImage(files) {

      if (files.length > 3) {
        this.$message.error('一次最多只能上传3张图片');
        //取消上传
        return [];
      }

      this.$message({
        showClose: true,
        message: '正在上传中...',
        type: 'info'
      });

      console.log('files', files)
      let result = []
      for (let i = 0; i < files.length; i++) {
        let formData = new FormData()
        let fileName = new Date().getTime() + '.webp';
        formData.append('file', files[i], fileName)
        let res = await ossApi.uploadFileToOss(formData);
        result.push({
          title: files[i].name,
          url: res.data.data.url,
        })
      }
      this.$message({
        showClose: true,
        message: '上传成功!',
        type: 'success'
      });
      return result
    },

    handleAvatarSuccess(res, file) {
      this.form.cover = res.data.url;
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt5M = file.size / 1024 / 1024 < 5;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      // return isJPG && isLt2M;
      return isLt5M;
    },
    handleResize() {
      if (window.innerWidth < 700) {
        this.pageCount = 5;
        this.layout = 'total,prev, pager, next';
      }else {
        this.pageCount = 9;
        this.layout = 'total, sizes, prev, pager, next, jumper';
      }
    },
    handleCurrentChange(val){
      this.queryParams.pageNum = val;
      this.getList();
    },
    handleSizeChange(val){
      this.queryParams.pageSize = val;
      this.getList();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /** 查询用户文章管理列表 */
    getList() {
      this.loading = true;
      listArticle(this.queryParams).then(response => {
        this.articleList = response.data.data.list;
        this.total = response.data.data.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        categoryId: null,
        userId: null,
        title: null,
        description: null,
        content: null,
        cover: null,
        viewCount: null,
        likeCount: null,
        commentCount: null,
        collectCount: null,
        top: null,
        attachmentAddress: null,
        createTime: null,
        updateTime: null,
        isDeleted: null
      };
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加用户文章管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getArticle(id).then(response => {
        this.form = response.data.data.article;
        this.open = true;
        this.title = "修改用户文章管理";
        this.fileList.push({name:  this.form.attachmentAddress, url:  this.form.attachmentAddress});
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateArticle(this.form).then(response => {
              this.$message.success("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addArticle(this.form).then(response => {
              this.$message.success("添加成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除用户文章管理编号为"' + ids + '"的数据项？').then(function() {
        return delArticle(ids);
      }).then(() => {
        this.getList();
        this.$message.success("删除成功");
      }).catch(() => {});
    },

  }
};
</script>

<style >
 @media screen and (max-width: 600px) {
   .el-dialog{
     width: 300px !important;
   }
 }
</style>
