import request from '@/utils/request'
export default {
    //获取文章评论
    getComments(articleId,pageNo,pageSize,sort) {
        return request({
            url: `/comment/list/${articleId}/${pageNo}/${pageSize}`,
            method: 'get',
            params: {sort}
        })
    },

    //发表评论
    postComment(comment) {
        return request({
            url: `/comment/add`,
            method: 'post',
            data:comment
        })
    },
}
