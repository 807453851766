// 生成验证码
export function createCanvas(value, dom, imgUrl, loadedCb) {
    // 绘制背景
    const tempArr = value.split('');
    const canvas = dom;
    const ctx = canvas.getContext('2d');
    const x = canvas.width / 2;
    const oImg = new Image();
    oImg.src = imgUrl;
    oImg.width = 120;
    oImg.width = 40;
    oImg.onload = function() {
        loadedCb && typeof loadedCb === 'function' && loadedCb();
        const pattern = ctx.createPattern(oImg, 'repeat'); // 在指定的方向内重复指定的元素
        ctx.fillStyle = pattern; // 填充绘画的颜色
        ctx.clearRect(0, 0, canvas.width, canvas.height); // 清除canvas上的内容
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.textAlign = 'center';
        ctx.font = '26px Roboto Slab';
        ctx.setTransform(1, -0.12, 0.2, 1, 0, 0);

        const colors = ['#DC143C', '#FF8C00', '#008000', '#0000FF']; // 每一位数字对应的颜色

        const digitWidth = 20; // 每个数字的宽度
        const totalWidth = digitWidth * tempArr.length; // 所有数字的总宽度
        const startX = x - totalWidth / 2; // 开始绘制的起始位置

        tempArr.forEach((digit, index) => {
            const color = colors[index]; // 获取对应位数字的颜色
            ctx.fillStyle = color;
            ctx.fillText(digit, startX + index * digitWidth, 40);
        });
    };
}



const metaCode = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
// 生成四位随机数
export function generateVerificationCode(generateVerificationCodeLength){
    let code = '';
    for (let i = 0; i < generateVerificationCodeLength; i++) {
        const index = Math.floor(Math.random() * metaCode.length);
        code += metaCode[index];
    }
    return code;
}
