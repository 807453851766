<template>
  <div class="todo" :class="{darkToDo:theme=='dark'}">
    <div class="todo-head" :class="{darkInput:theme=='dark'}">
      <!--一个搜索框 -->
      <el-input
          placeholder="搜索待办事项"
          v-model="searchValue"
          class="search-input"

      />
      <!--分类-->
      <div class="todo-typeList">
        <span class="todo-typeList-item" :class="{activeStyle:activeItemName === item.name}" @click="selectType(item)"
              v-for="(item,index) in todoTypeList" :key="index" :theme="theme">
          {{ item.name }}
        </span>
      </div>
    </div>

    <div class="todo-bottom">
      <!--待办事项列表-->
      <div class="todo-list">
        <div class="todo-list-title">
          <el-checkbox v-model="checkAll" @change="handleCheckAllChange" :class="{darkInput:theme=='dark'}">全选
          </el-checkbox>
          <el-button type="text" @click="todeletedAll()">删除选中</el-button>
        </div>
        <template v-if="todoList.length">
          <div class="todo-item" v-for="(item) in filteredTodoList" :key="item.id">
            <div class="todo-item-top">
              <div class="todo-item-left">
                <el-checkbox class="todo-check" v-model="item.status===1" @change="finishTask(item)"></el-checkbox>
              </div>
              <div class="todo-item-mid">
                <el-input type="textarea" :class="{darkTextarea:theme=='dark'}"
                          :style="{ 'text-decoration': item.status===1 ? 'line-through' : 'none', 'text-decoration-color':theme=='dark'?'white':'black' }"
                          :disabled="item.status===1" :rows="1" autosize v-model="item.content"
                          @change="changeContent(item)" class="todo-item-content"></el-input>
              </div>
              <div class="todo-item-right">
                <el-tooltip placement="bottom-end" effect="light">
                  <div slot="content" class="priority-container">
                    <span>优先级</span>
                    <div class="priority-item-list">
                      <button class="priority-item" style="background-color:rgb(224, 49, 49);"
                              @click="changePriority(item,0)">
                      </button>
                      <button style="background-color:rgb(255, 176, 0);" class="priority-item"
                              @click="changePriority(item,1)">
                      </button>
                      <button style="background-color:rgb(71, 114, 250);" class="priority-item"
                              @click="changePriority(item,2)">
                      </button>
                    </div>
                  </div>
                  <i class="iconfont icon-xingjibie operation"
                     :style="{ color: item.priority===0 ? 'rgb(224, 49, 49)' : item.priority===1 ? 'rgb(255, 176, 0)' : 'rgb(71, 114, 250)'  }"
                     style="margin-right: 10px; "></i>
                </el-tooltip>

                <i class="iconfont icon-shanchu2 operation" @click="todeleted(item)"
                   :class="{darkDeleted:theme=='dark'}"></i>
              </div>
            </div>
            <div class="todo-item-bottom">
              <el-date-picker
                  :popper-class="theme=='dark'?'darkPickerPanel':'nodark'"
                  :class="{darkPicker:theme=='dark'}"
                  value-format="yyyy-MM-dd"
                  size="mini"
                  v-model="item.deadline"
                  type="date"
                  @change="changeDeadline(item)"
                  placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
        </template>
        <!--新增按钮-->
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="showAdd=true">添加</el-button>
      </div>

      <el-skeleton :rows="4" animated v-if="isLoading"/>
      <el-empty description="暂无数据" v-if="todoListIsEmpty"></el-empty>
      <!--新增待办事项-->
      <el-dialog title="新增待办事项" style="text-align: center" :visible.sync="showAdd" width="300px"
                 :custom-class="theme=='dark'?'darkDialog':'nodark'">
        <el-input type="textarea" style="width: 220px;margin-bottom: 10px" :rows="2" placeholder="请输入内容"
                  :class="{darkTextarea:theme=='dark'}"
                  v-model="todoInfo.content" class="todo-item-content"></el-input>
        <el-date-picker
            :popper-class="theme=='dark'?'darkPickerPanel':'nodark'"
            :class="{darkPicker:theme=='dark'}"
            style="margin-bottom: 10px"
            value-format="yyyy-MM-dd"
            v-model="todoInfo.deadline"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
        <el-select v-model="todoInfo.priority" placeholder="请选择" :class="{darkInput:theme=='dark'}"
                   :popper-append-to-body="false">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showAdd = false">取 消</el-button>
          <el-button type="primary" @click="addTodo">确 定</el-button>
        </span>
      </el-dialog>
      <el-pagination
          v-if="todoList.length"
          :small="true"
          :pager-count="pageCount"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          :layout="layout"
          :total="total">
      </el-pagination>

    </div>

    <div class="copyright-cmp" :theme="theme">
      <p class="copyright-item">Copyright©2024 | 粤ICP备号xxxxxx</p>
      <p class="copyright-item">作者：xiaolin</p>
      <p class="copyright-item">邮箱：xiaolin_zii@163.com</p>
    </div>
  </div>
</template>
<script>
import todoApi from '@/api/todo'

export default {
  data() {
    return {
      pageCount:9,
      layout:'total, sizes, prev, pager, next, jumper',
      todoListIsEmpty: false,
      isLoading: false,
      theme: 'light',
      options: [
        {
          value: 0,
          label: '普通(蓝色)'
        },
        {
          value: 1,
          label: '重要(黄色)'
        },
        {
          value: 2,
          label: '紧急(红色)'
        }
      ],
      todoInfo: {
        content: '',
        deadline: '',
        priority: null,
      },
      showAdd: false,
      total: 0,
      pagesize: 5,
      currentPage: 1,
      checkAll: true,
      // isIndeterminate: true,
      activeItemName: '所有',
      searchValue: '',
      todoTypeList: [
        {name: '所有', icon: 'icon-a-ziyuan686'},
        {name: '今天', icon: 'icon-jintian2'},
        {name: '最近七天', icon: 'icon-zuijinqitian'}
      ],
      todoList: [],
    }
  },
  created() {
    this.init();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    },
    todoList: {
      handler: function () {
        // console.log(val)
        //判断是否全选
        let flag = this.todoList.every(item => item.status == 1);
        this.checkAll = flag;
      },
      deep: true
    }
  },
  computed: {
    filteredTodoList() {
      if (this.searchValue) {
        return this.todoList.filter(item =>
            item.content.toLowerCase().includes(this.searchValue.toLowerCase())
        );
      } else {
        return this.todoList;
      }
    },
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 700) {
        this.pageCount = 5;
        this.layout = 'total,prev, pager, next';
      }else {
        this.pageCount = 9;
        this.layout = 'total, sizes, prev, pager, next, jumper';
      }
    },
    init() {
      this.isLoading = true;
      todoApi.getTodoListPage(this.currentPage, this.pagesize).then(res => {
        if (res.data.data.todoList.length === 0) {
          this.todoListIsEmpty = true;
        } else {
          this.todoListIsEmpty = false;
        }
        this.todoList = res.data.data.todoList;
        this.total = res.data.data.total;
        this.isLoading = false;
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.init();
    },
    addTodo() {
      todoApi.addTodoItem(this.todoInfo).then(() => {
        //重新获取数据
        this.currentPage = 1;
        this.init();
        this.showAdd = false;
        this.$message({
          type: "success",
          duration: 1000,
          message: "添加成功！",
        });
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.activeItemName == "今天") {
        this.isLoading = true;
        todoApi.getTodoListTodayPage(this.currentPage, this.pagesize).then(res => {
          if (res.data.data.todoList.length === 0) {
            this.todoListIsEmpty = true;
          } else {
            this.todoListIsEmpty = false;
          }
          this.todoList = res.data.data.todoList;
          this.total = res.data.data.total;
          this.isLoading = false;
        })
      } else if (this.activeItemName == "最近七天") {
        this.isLoading = true;
        todoApi.getTodoListRecentPage(this.currentPage, this.pagesize).then(res => {
          if (res.data.data.todoList.length === 0) {
            this.todoListIsEmpty = true;
          } else {
            this.todoListIsEmpty = false;
          }
          this.todoList = res.data.data.todoList;
          this.total = res.data.data.total;
          this.isLoading = false;
        })

      } else {
       this.init()
      }
    },

    changeContent(item) {
      //修改待办事项的内容
      todoApi.updateTodoContent(item.id, item.content).then(() => {
        // console.log(res)
      })
    },
    changeDeadline(item) {
      //修改待办事项的时间
      todoApi.updateTodoDeadline(item.id, item.deadline).then(() => {
        // console.log(res)
      })
    },
    handleCheckAllChange(val) {
      console.log(val)
      this.todoList.forEach(item => {
        item.status = val ? 1 : 0;
      });
      const status = val ? 1 : 0;
      //全选
      todoApi.updateTodoStatusAll(status).then(res => {
        console.log(res)
      })
      // this.isIndeterminate = false;
    },

    finishTask(item) {
      let status = item.status == 1 ? 0 : 1;
      todoApi.updateTodoStatus(item.id, status).then(() => {
        item.status = status;
        //判断是否全选
        let flag = this.todoList.every(item => item.status == 1);
        this.checkAll = flag;
      })
    },
    changePriority(item, priority) {
      item.priority = priority;
      //按优先级排序
      this.todoList.sort((a, b) => {
        return a.priority - b.priority;
      });
      todoApi.updateTodoPriority(item.id, priority).then(() => {
        // console.log(res)
      })
    },
    todeleted(item) {
      //提示
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'logout-message-box'
      }).then(() => {
        this.todoList.splice(this.todoList.indexOf(item), 1);
        todoApi.deleteTodoById(item.id).then(() => {
          // console.log(res)
          this.$message({
            type: "success",
            duration: 1000,
            message: "删除成功！",
          });
        })
      }).catch(() => {
        this.$message({
          type: "info",
          duration: 1000,
          message: "已取消删除",
        });
      });
    },
    todeletedAll() {
      //提示
      this.$confirm('确定删除选中项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'logout-message-box'
      }).then(() => {
        this.todoList = this.todoList.filter(item => item.status === 0);
        todoApi.deleteAllCompleted().then(() => {
          // console.log(res)
          this.$message({
            type: "success",
            duration: 1000,
            message: "删除成功！",
          });
        })

      }).catch(() => {
        this.$message({
          type: "info",
          duration: 1000,
          message: "已取消删除",
        });
      });
    },
    selectType(item) {
      this.currentPage = 1;
      this.activeItemName = item.name;
      //过滤时间
      console.log(item.name);
      if (item.name == "今天") {
        this.isLoading = true;
        todoApi.getTodoListTodayPage(this.currentPage, this.pagesize).then(res => {
          if (res.data.data.todoList.length === 0) {
            this.todoListIsEmpty = true;
          } else {
            this.todoListIsEmpty = false;
          }
          this.todoList = res.data.data.todoList;
          this.total = res.data.data.total;
          this.isLoading = false;
        })
      } else if (item.name == "最近七天") {
        this.isLoading = true;
        todoApi.getTodoListRecentPage(this.currentPage, this.pagesize).then(res => {
          if (res.data.data.todoList.length === 0) {
            this.todoListIsEmpty = true;
          } else {
            this.todoListIsEmpty = false;
          }
          this.todoList = res.data.data.todoList;
          this.total = res.data.data.total;
          this.isLoading = false;
        })

      } else {
        this.init()
      }
    }
  }
}
</script>
<style lang="scss">
.darkToDo {
  .el-pagination__jump {
    color: white;
  }

  .el-pagination__total {
    color: white;
  }
}

.darkPickerPanel.el-date-picker.el-picker-panel {
  background-color: rgb(17, 17, 17);
  color: white;
  border: rgb(62, 62, 62) solid 1px !important;

  .el-picker-panel__icon-btn {
    color: #ffffff;
  }

  .el-date-picker__header-label {
    color: #ffffff;
  }

  .el-date-table th {
    color: #ffffff;
  }

  .popper__arrow {
    border-bottom-color: rgb(62, 62, 62);
  }

  .popper__arrow::after {
    border-bottom-color: rgb(17, 17, 17) !important;
    border-top-color: rgb(17, 17, 17) !important;
  }
}

.darkDialog {
  background-color: rgb(33, 33, 33);
  color: white;

  .el-dialog__title {
    color: white;
  }

  .el-select-dropdown {
    background-color: rgb(17, 17, 17);
    color: white;
    border: rgb(62, 62, 62) solid 1px !important;

    .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
      background-color: #1589fa !important;
    }

    .el-select-dropdown__item {
      color: white;
    }

    .popper__arrow {
      border-bottom-color: rgb(62, 62, 62);
    }

    .popper__arrow::after {
      border-bottom-color: rgb(17, 17, 17) !important;
      border-top-color: rgb(17, 17, 17) !important;
    }
  }
}


</style>
<style scoped>
.copyright-cmp[theme='light']{
  text-align: center;
  color: rgba(0, 0, 0, .45);
  font-size: 14px;
}
.copyright-cmp[theme='dark']{
  text-align: center;
  color: rgba(255,255,255,.45);
  font-size: 14px;
}


.darkDeleted {
  color: white;
}

.darkToDo {
  background-color: rgb(17, 17, 17);
}

.darkInput /deep/ .el-input__inner {
  background-color: black;
  border: rgb(62, 62, 62) solid 1px !important;
  color: white;
}


.darkInput /deep/ .el-checkbox__label {
  color: white;
}

.todo-item-top {
  float: top;
}

.todo-item-bottom >>> .el-input__inner {
  border: 0;
  resize: none; /* 这个是去掉 textarea 下面拉伸的那个标志，如下图 */
  padding-left: 20px;
}

.darkPicker /deep/ .el-input__inner {
  background-color: rgb(17, 17, 17);
  border: 0;
  color: white;
}


.todo-item-bottom >>> .el-input__prefix {
  left: -5px;
}

.todo-item-mid >>> .el-textarea__inner {
  border: 0;
  resize: none; /* 这个是去掉 textarea 下面拉伸的那个标志，如下图 */
  background-color: #fff;
  color: black;
}


.darkTextarea /deep/ .el-textarea__inner {
  background-color: rgb(17, 17, 17);
  border: 0;
  resize: none; /* 这个是去掉 textarea 下面拉伸的那个标志，如下图 */
  color: white;
}

.search-input {
}

.todo {
  /*background-color: #f5f5f5;*/
  height: 100vh;
}

.todo-head {
  width: 80%;
  /**居中 */
  margin: 0 auto;
  padding: 20px;
}

.todo-typeList {
  display: flex;
  justify-content: space-between;
  max-width: 280px;
}


.todo-typeList-item[theme=dark] {
  color: #fff;
}


.todo-typeList-item:hover {
  cursor: pointer;
}

.activeStyle {
  border-bottom: 2px solid #1589fa;
}

.todo-typeList {
  margin-top: 20px;
}

.todo-check {
  width: 16px;
  height: 16px;
}

.todo-item {

  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
}

.todo-item-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.todo-item-left {
  width: 50px;
}


.todo-item-mid {
  width: 80%;
}

.todo-item-content {
  /*margin-left: 10px;*/
}

.todo-item-time {
  font-size: 12px;
  color: #999;
}

.todo-item-bottom {
  margin: 0;
  padding: 0;
}

.todo-bottom {
  width: 80%;
  margin: 0 auto;
}

.todo-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
}

.operation:hover {
  cursor: pointer;
}

.priority-container {
  width: 130px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.priority-item-list {
}

.priority-item {
  width: 16px; /* 设置按钮宽度 */
  height: 16px; /* 设置按钮高度 */
  border-radius: 50%; /* 将按钮的边框半径设置为50%（宽度和高度的一半） */
  border: none;
  margin-right: 10px;
}

.priority-item:hover {
  cursor: pointer;
  width: 18px; /* 设置按钮宽度 */
  height: 18px; /* 设置按钮高度 */
  transition: all 0.2s;
}
</style>
