<template>
  <div class="slef-edit">
    <!--添加一个返回的按钮在左上角-->
    <div style="position: sticky;top: 0px;left: 0px;width: 100%;height: 50px;z-index: 999">
      <el-button type="text" icon="el-icon-arrow-left" @click="goback()">返回</el-button>
    </div>
    <div class="edit-container" :class="{darkForm:theme=='dark'}">
      <div class="edit-title">
        修改信息
      </div>
      <el-form class="edit-form" ref="updateSelfForm" :label-position="labelPosition" label-width="60px" size="mini"
               :model="updateForm">
        <el-form-item label="昵称">
          <el-input v-model="updateForm.nickname"   maxlength="6" :class="{darkInput:theme=='dark'}"></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
              class="avatar-uploader"
              action="https://47.120.61.197/oss/uploadOssFile"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <img v-if="updateForm.avatar" :src="updateForm.avatar" class="avatar">
            <el-avatar
                v-else
                shape="square"
                alt="avatar"
                class="avatar"
            >
              无头像
            </el-avatar>
          </el-upload>
        </el-form-item>
        <el-form-item label="简介">
          <el-input type="textarea" autosize v-model="updateForm.signature"  :class="{darkTextarea:theme=='dark'}"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="updateForm.sex">
            <el-radio :label="0">女</el-radio>
            <el-radio :label="1">男</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="生日">
          <el-date-picker value-format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="updateForm.birthday"
                          style="width: 130px"
                          :popper-class="theme=='dark'?'darkPickerPanel':'nodark'"
                          :class="{darkPicker:theme=='dark'}"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="地区">
          <el-input v-model="updateForm.place" :class="{darkInput:theme=='dark'}"></el-input>
        </el-form-item>
        <el-form-item label="学校">
          <el-input v-model="updateForm.school" :class="{darkInput:theme=='dark'}"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="updateForm.jobStatus" placeholder="请选择工作状态" :class="{darkInput:theme=='dark'}" :popper-append-to-body="false">
            <el-option label="在校" :value="0"></el-option>
            <el-option label="求职中" :value="1"></el-option>
            <el-option label="实习中" :value="2"></el-option>
            <el-option label="已工作" :value="3"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="专业">
          <el-input v-model="updateForm.major" :class="{darkInput:theme=='dark'}"></el-input>
        </el-form-item>
        <el-form-item label="方向">
          <el-input v-model="updateForm.direction" :class="{darkInput:theme=='dark'}"></el-input>
        </el-form-item>
        <el-button type="primary" @click="submitForm('updateSelfForm')" size="mini">提交</el-button>
        <el-button @click="resetForm('updateSelfForm')" size="mini" style="margin-bottom: 20px">重置</el-button>
      </el-form>
    </div>
    <div class="copyright-cmp" :theme="theme">
      <p class="copyright-item">Copyright©2024  | 粤ICP备号xxxxxx</p>
      <p class="copyright-item">作者：xiaolin</p>
      <p class="copyright-item">邮箱：xiaolin_zii@163.com</p>
    </div>
  </div>
</template>
<script>
import userApi from '@/api/user'
import cookie from "js-cookie";
import CryptoJS from 'crypto-js';
const secretKey = 'QAPlatform-2024';
export default {
  data() {
    return {
      theme: 'light',
      labelPosition: 'left',
      updateForm: {
        nickname: '',
        avatar: '',
        signature: '',
        sex: 2,
        birthday: '',
        place: '',
        school: '',
        major: '',
        jobStatus: 4,
        direction: '',
        // phone:'',
        // email:'',
      },

    }
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  mounted() {
    const userInfo = this.$store.state.user.userInfo;
    this.updateForm = {
      nickname: userInfo.nickname,
      avatar: userInfo.avatar,
      signature: userInfo.signature,
      sex: userInfo.sex,
      birthday: userInfo.birthday,
      place: userInfo.place,
      school: userInfo.school,
      major: userInfo.major,
      jobStatus: userInfo.jobStatus,
      direction: userInfo.direction,
      // phone: userInfo.phone,
      // email: userInfo.email,
    }
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          userApi.updateUserInfo(this.updateForm).then(res => {
            if (res.data.success){
              //修改成功后，重新查询用户信息
              userApi.getLoginUserInfo().then(res=>{
                this.$store.state.user.userInfo = res.data.data.myInfo;
                let dataString = JSON.stringify(res.data.data.myInfo)
                const encryptedData = CryptoJS.AES.encrypt(dataString, secretKey).toString();
                window.localStorage.setItem('userInfo', encryptedData)
                this.$message({
                  message: '修改成功',
                  type: 'success'
                });
                //返回上一页
                this.$router.go(-1)
              })
            }else{
              this.$message({
                message: res.data.message,
                type: 'error'
              });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    goback() {
      this.$router.go(-1)
    },
    handleAvatarSuccess(res, file) {
      this.updateForm.avatar = res.data.url;
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt5M = file.size / 1024 / 1024 < 5;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      // return isJPG && isLt2M;
      return isLt5M;
    }
  }
}
</script>
<style lang="scss">
.darkPickerPanel.el-date-picker.el-picker-panel {
  background-color: rgb(17, 17, 17);
  color: white;
  border: rgb(62, 62, 62) solid 1px !important;
  .el-picker-panel__icon-btn {
    color: #ffffff;
  }
  .el-date-picker__header-label{
    color: #ffffff;
  }
  .el-date-table th {
    color:#ffffff;
  }
  .popper__arrow{
    border-bottom-color: rgb(62, 62, 62);
  }
  .popper__arrow::after {
    border-bottom-color:rgb(17,17,17) !important;
    border-top-color: rgb(17,17,17) !important;
  }
}
.darkForm{
  .el-select-dropdown{
    background-color: rgb(17, 17, 17);
    color: white;
    border: rgb(62, 62, 62) solid 1px !important;
    .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
      background-color: #1589fa !important;
    }
    .el-select-dropdown__item{
      color: white;
    }
    .popper__arrow{
      border-bottom-color: rgb(62, 62, 62);
    }
    .popper__arrow::after {
      border-bottom-color:rgb(17,17,17) !important;
      border-top-color: rgb(17,17,17) !important;
    }
  }
}
</style>
<style scoped>
.copyright-cmp[theme='light']{
  text-align: center;
  color: rgba(0, 0, 0, .45);
  font-size: 14px;
}
.copyright-cmp[theme='dark']{
  text-align: center;
  color: rgba(255,255,255,.45);
  font-size: 14px;
}
.darkPicker /deep/ .el-input__inner {
  background-color: rgb(17, 17, 17);
  border: rgb(62, 62, 62) solid 1px !important;
  color: white;
}

.darkTextarea /deep/ .el-textarea__inner {
  background-color: rgb(17, 17, 17);
  border: rgb(62, 62, 62) solid 1px !important;
  color: white;
}
.darkInput /deep/ .el-input__inner {
  background-color: black;
  border: rgb(62, 62, 62) solid 1px !important;
  color: white;
}
.edit-title {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, .06);
  padding: 0px 20px 0px 20px;
}

.edit-container {
  border: 1px solid rgba(0, 0, 0, .06);
  margin: 20px 20px 0px 20px;
  background-color: white;
}
.darkForm{
  background-color:rgb(17,17,17);
  margin: 20px 20px 0px 20px;
  border: 1px solid rgb(62, 62, 62);
}

.darkForm>>> .edit-title{
  color: white;
}

.darkForm>>> .el-form-item__label{
  color: #fff;
}


.edit-form {
  padding: 0px 20px 0px 20px;
  margin-top: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

@media screen and (max-width: 650px) {
  .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }
}
</style>
