import request from '@/utils/request'

// 查询前台用户管理列表
export function listFrontuser(query) {
  return request({
    url: `/system/frontuser/list/${query.pageNum}/${query.pageSize}`,
    method: 'post',
    data: {
      account: query.account,
      nickname: query.nickname,
      sex: query.sex,
      phone: query.phone,
      school: query.school,
      major: query.major,
      jobStatus: query.jobStatus,
      onlineStatus:query.onlineStatus,
      score: query.score,
      isDeleted: query.isDeleted,
      role: query.role,
    },
  })
}

// 查询前台用户管理详细
export function getFrontuser(id) {
  return request({
    url: '/system/frontuser/' + id,
    method: 'get'
  })
}

// 新增前台用户管理
export function addFrontuser(data) {
  return request({
    url: '/system/frontuser',
    method: 'post',
    data: data
  })
}

// 修改前台用户管理
export function updateFrontuser(data) {
  return request({
    url: '/system/frontuser',
    method: 'put',
    data: data
  })
}

// 删除前台用户管理
export function delFrontuser(id) {
  return request({
    url: '/system/frontuser/' + id,
    method: 'delete'
  })
}
