export function createWatemark({
                                   text = '水印',
                                   selectors = 'body',
                                   rotate = -20,
                                   color = 'rgba(144,147,153,0.1)',
                                    size = 60,
                               } = {}) {
    let can = document.createElement('canvas');
    let body = document.body;
    let nodes = document.querySelectorAll(selectors);
    body.appendChild(can);
    can.width = text.length * size;
    can.height = text.length * size;
    can.style.display = 'none';
    let cans = can.getContext('2d');
    cans.rotate(rotate * Math.PI / 180);
    //宋体
    cans.font = '16px 宋体';
    cans.fontFamily = 'SimSun,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Roboto,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol!important;'
    cans.fillStyle = color;
    cans.fillText(text, 0, can.height / 2);
    for (let i = 0; i < nodes.length; i++) {
        nodes[i].style.backgroundImage = "url(" + can.toDataURL("image/png") + ")" ;
        nodes[i].style.backgroundPosition = '420px 420px,0px 0px'
    }
}
