<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="发送人id" prop="sendId">
        <el-input
          v-model="queryParams.sendId"
          placeholder="请输入发送人id"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="接受者id" prop="acceptId">
        <el-input
          v-model="queryParams.acceptId"
          placeholder="请输入接受者id"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="发送时间">
        <el-date-picker
          v-model="daterangeSendTime"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="发送内容类型" prop="contentType">
        <el-select v-model="queryParams.contentType" placeholder="请选择发送内容类型" clearable>
          <el-option
            v-for="dict in sys_chat_content_type"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否已读" prop="isRead">
        <el-select v-model="queryParams.isRead" placeholder="请选择是否已读" clearable>
          <el-option
            v-for="dict in sys_0_1"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="逻辑删除" prop="isDeleted">
        <el-select v-model="queryParams.isDeleted" placeholder="请选择逻辑删除" clearable>
          <el-option
            v-for="dict in sys_logic_deleted"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </el-col>

    </el-row>

    <el-table v-loading="loading" :data="recordList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键id" align="center" prop="id" />
      <el-table-column label="发送人id" align="center" prop="sendId" />
      <el-table-column label="接受者id" align="center" prop="acceptId" />
      <el-table-column label="发送时间" align="center" prop="sendTime" width="180">
        <template slot-scope="scope">
          <span>{{scope.row.sendTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发送内容类型" align="center" prop="contentType">
        <template slot-scope="scope">
          <el-tag>{{scope.row.contentType=='message'?'文本':scope.row.contentType=='image'?'图片':'其他'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="发送内容" align="center" prop="content" />
      <el-table-column label="是否已读" align="center" prop="isRead">
        <template slot-scope="scope">
         <el-tag>{{scope.row.isRead==0?'未读':'已读'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="逻辑删除" align="center" prop="isDeleted">
        <template slot-scope="scope">
          <el-tag>
            {{scope.row.isDeleted==0?'正常':'删除'}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="删除改聊天记录用户id" align="center" prop="deletedIds" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        v-if="recordList.length"
        :small="true"
        :pager-count="pageCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParams.pageSize"
        :layout="layout"
        :total="total">
    </el-pagination>


    <!-- 添加或修改用户聊天记录管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="发送人id" prop="sendId">
          <el-input v-model="form.sendId" placeholder="请输入发送人id" />
        </el-form-item>
        <el-form-item label="接受者id" prop="acceptId">
          <el-input v-model="form.acceptId" placeholder="请输入接受者id" />
        </el-form-item>
        <el-form-item label="发送时间" prop="sendTime">
          <el-date-picker clearable
                          v-model="form.sendTime"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="请选择发送时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发送内容类型" prop="contentType">
          <el-select v-model="form.contentType" placeholder="请选择发送内容类型">
            <el-option
              v-for="dict in sys_chat_content_type"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送内容">
          <el-input type="textarea" v-model="form.content" :min-height="192"/>
        </el-form-item>
        <el-form-item label="是否已读" prop="isRead">
          <el-select v-model="form.isRead" placeholder="请选择是否已读">
            <el-option
              v-for="dict in sys_0_1"
              :key="dict.value"
              :label="dict.label"
              :value="parseInt(dict.value)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="逻辑删除" prop="isDeleted">
          <el-select v-model="form.isDeleted" placeholder="请选择逻辑删除">
            <el-option
              v-for="dict in sys_logic_deleted"
              :key="dict.value"
              :label="dict.label"
              :value="parseInt(dict.value)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="删除改聊天记录用户id" prop="deletedIds">
          <el-input v-model="form.deletedIds" placeholder="请输入删除改聊天记录用户id" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listRecord, getRecord, delRecord, addRecord, updateRecord } from "@/api/admin/record";

export default {
  data() {
    return {
      pageCount:9,
      token: this.$store.state.user.token,
      layout:'total, sizes, prev, pager, next, jumper',
      sys_logic_deleted: [
        { label: "正常", value: 0 },
        { label: "删除", value: 1 }
      ],
      sys_chat_content_type: [
        { label: "文本", value: 'message'},
        { label: "图片", value: 'image' },
      ],
      sys_0_1: [
        { label: "否", value: 0 },
        { label: "是", value: 1 }
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 用户聊天记录管理表格数据
      recordList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 删除改聊天记录用户id时间范围
      daterangeSendTime: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        sendId: null,
        acceptId: null,
        sendTime: null,
        contentType: null,
        content: null,
        isRead: null,
        isDeleted: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      }
    };
  },
  created() {
    this.getList();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 700) {
        this.pageCount = 5;
        this.layout = 'total,prev, pager, next';
      }else {
        this.pageCount = 9;
        this.layout = 'total, sizes, prev, pager, next, jumper';
      }
    },
    handleCurrentChange(val){
      this.queryParams.pageNum = val;
      this.getList();
    },
    handleSizeChange(val){
      this.queryParams.pageSize = val;
      this.getList();
    },
    /** 查询用户聊天记录管理列表 */
    getList() {
      this.loading = true;
      this.queryParams.params = {};
      if (null != this.daterangeSendTime && '' != this.daterangeSendTime) {
        this.queryParams.params["beginTime"] = this.daterangeSendTime[0];
        this.queryParams.params["endTime"] = this.daterangeSendTime[1];
      }
      listRecord(this.queryParams).then(response => {
        this.recordList = response.data.data.list;
        this.total = response.data.data.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        sendId: null,
        acceptId: null,
        sendTime: null,
        contentType: null,
        content: null,
        isRead: null,
        isDeleted: null,
        deletedIds: null,
        createTime: null,
        updateTime: null
      };

    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeSendTime = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加用户聊天记录管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getRecord(id).then(response => {
        this.form = response.data.data.messageRecord;
        this.open = true;
        this.title = "修改用户聊天记录管理";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateRecord(this.form).then(response => {
              this.$message.success("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addRecord(this.form).then(response => {
              this.$message.success("添加成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除用户聊天记录管理编号为"' + ids + '"的数据项？').then(function() {
        return delRecord(ids);
      }).then(() => {
        this.getList();
        this.$message.success("删除成功");
      }).catch(() => {});
    },

  }
};
</script>
