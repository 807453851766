<template>
  <div style="overflow-y: scroll;height:calc(100vh - 100px)" id="main" class="main">
    <div>
      <el-row type="flex" justify="center">
        <el-col :xs="22" :span="14" class="index-left">
          <div>
            <el-card class="watermarkContent" shadow="never" v-show="articleInfo.user.avatar"
                     :body-style="theme=='dark'?darkCard:''"
                     :class="{darkCard:theme=='dark'}">
              <el-row>
                <el-col :span="24">
                  <el-row>
                    <div class="article-list-item-title" style="display: flex;justify-content: space-between">
                      <div class="author-message">
                        <div>
                          <el-image
                              v-if="articleInfo.user.avatar"
                              style="width: 50px; height: 50px;border-radius: 50%"
                              :src="articleInfo.user.avatar"
                              :preview-src-list="[articleInfo.user.avatar]"
                          >
                          </el-image>
                        </div>
                        <el-popover
                            :popper-class="theme=='dark'?'popperClass':''"
                            placement="bottom"
                            width="350"
                            trigger="hover"
                        >
                          <div class="article-list-item-title">
                            <div class="author-message">
                              <el-image
                                  v-if="articleInfo.user.avatar"
                                  style="width: 50px; height: 50px;border-radius: 50%"
                                  :src="articleInfo.user.avatar"
                                  :preview-src-list="[articleInfo.user.avatar]"
                              >
                              </el-image>
                              <div class="author-message-right">
                                <span style="margin-left: 10px" class="articlAuthorName"
                                      @click="showUser(article.user.id)">{{ articleInfo.user.nickname }}</span>
                                <el-tag class="author-Title" type="info" size="mini"
                                        v-if="articleInfo.user.userLabel=='Lv1 关关雎鸠'"
                                >{{ articleInfo.user.userLabel }}
                                </el-tag>

                                <el-tag class="author-Title" type="danger" size="mini"
                                        v-else-if="articleInfo.user.userLabel=='Lv7 无人能敌'"
                                >{{ articleInfo.user.userLabel }}
                                </el-tag>

                                <el-tag class="author-Title" type="success" size="mini" v-else
                                >{{ articleInfo.user.userLabel }}
                                </el-tag>

                                <el-tag class="author-Title" type="danger" size="mini"
                                        v-if="articleInfo.user.account=='admin'">
                                  站长
                                </el-tag>
                                <!--关注按钮-->
                                <el-button type="primary" size="mini" style="margin-left: 10px"
                                           v-if="articleInfo.user.id!=userInfo.id&&articleInfo.user.followStatus==0"
                                           @click="followUser(articleInfo.user)">关注
                                </el-button>
                                <el-button type="primary" size="mini" style="margin-left: 10px"
                                           v-if="articleInfo.user.id==userInfo.id" @click="toMyHome">主页
                                </el-button>
                                <el-button type="primary" plain size="mini" style="margin-left: 10px"
                                           v-if="articleInfo.user.id!=userInfo.id&&articleInfo.user.followStatus==1"
                                           @click="followUser(articleInfo.user)">已关注
                                </el-button>
                              </div>
                            </div>
                            <div style="margin-top: 10px">
                              <!--简介-->
                              <div>
                                简介：
                                <span>{{ articleInfo.user.signature }}</span>
                              </div>
                              <el-divider></el-divider>
                              <!--粉丝关注积分-->
                              <div style="display: flex;width: 100%">
                                <span style="width: 33%;cursor: pointer;" class="hoverSpan"
                                      @click="toUserFan(articleInfo.user.id)">粉丝:<span
                                    style="margin-left: 5px">{{ articleInfo.user.fansCount }}</span></span>
                                <span style="width: 33%;cursor: pointer" class="hoverSpan"
                                      @click="toUserFollow(articleInfo.user.id)">关注:<span
                                    style="margin-left: 5px">{{ articleInfo.user.followCount }}</span></span>
                                <span style="width: 33%;">积分:<span style="margin-left: 5px">{{
                                    articleInfo.user.score
                                  }}</span></span>
                              </div>
                            </div>
                          </div>
                          <div slot="reference" class="author-message-right">
                            <span style="margin-left: 10px" class="articlAuthorName"
                                  @click="showUser(articleInfo.user.id)">{{ articleInfo.user.nickname }}</span>


                            <el-tag class="author-Title" type="info" size="mini"
                                    v-if="articleInfo.user.userLabel=='Lv1 关关雎鸠'"
                            >{{ articleInfo.user.userLabel }}
                            </el-tag>

                            <el-tag class="author-Title" type="danger" size="mini"
                                    v-else-if="articleInfo.user.userLabel=='Lv7 无人能敌'"
                            >{{ articleInfo.user.userLabel }}
                            </el-tag>

                            <el-tag class="author-Title" type="success" size="mini" v-else
                            >{{ articleInfo.user.userLabel }}
                            </el-tag>

                            <el-tag class="author-Title" type="danger" size="mini"
                                    v-if="articleInfo.user.account=='admin'">
                              站长
                            </el-tag>
                            <div
                                style="margin-left: 10px;margin-top:8px;height: 20px;font-size: 14px;color:rgb(117, 116, 115);line-height: 20px">
                              <span>{{ articleInfo.updateTime }}</span>
                              <span style="margin: 0 6px;color: rgba(0,0,0,.1);">|</span>
                              阅读<span style="margin-left: 5px">{{ articleInfo.viewCount }}</span>
                            </div>
                          </div>
                        </el-popover>
                      </div>
                      <!--关注按钮-->
                      <div>
                        <el-button type="primary" size="mini" style="margin-left: 30px"
                                   v-if="articleInfo.user.id!=userInfo.id&&articleInfo.user.followStatus==0"
                                   @click="followUser(articleInfo.user)">关注
                        </el-button>
                        <el-button type="primary" size="mini" style="margin-left: 30px"
                                   v-if="articleInfo.user.id==userInfo.id" @click="toMyHome">主页
                        </el-button>
                        <el-button type="primary" plain size="mini" style="margin-left: 30px"
                                   v-if="articleInfo.user.id!=userInfo.id&&articleInfo.user.followStatus==1"
                                   @click="followUser(articleInfo.user)">已关注
                        </el-button>
                      </div>
                    </div>
                  </el-row>
                  <el-divider></el-divider>
                  <h1>{{ articleInfo.title }}</h1>
                  <p v-html="articleInfo.description" class="postdes" :class="{darkpostdes:theme=='dark'}"></p>
                  <div>
                    <!--                      <mavon-editor-->
                    <!--                          previewBackground="#fff"-->
                    <!--                          v-model="articleInfo.content"-->
                    <!--                          :subfield="false"-->
                    <!--                          :boxShadow="false"-->
                    <!--                          defaultOpen="preview"-->
                    <!--                          :toolbarsFlag="false"-->
                    <!--                      />-->
                    <!--                    <v-md-editor :value="articleInfo.content" mode="preview"></v-md-editor>-->

                    <Viewer
                        class="viewer"
                        :tabindex="2"
                        :sanitize="23"
                        :value="articleInfo.content"
                        :plugins="plugins"
                    ></Viewer>
                    <!--显示简历-->
                    <!--默认隐藏，点击按钮显示-->
                    <div v-if="articleInfo.attachmentAddress" style="cursor: pointer;color: #3a8ee6;margin-bottom: 10px"
                         @click="isShowPdf==false?isShowPdf=true:isShowPdf=false">
                      <i class="iconfont icon-xiangyou1" v-if="!isShowPdf"></i>
                      <i class="iconfont icon-xiangxia4" v-if="isShowPdf"></i>
                      查看简历文件
                    </div>
                    <div v-if="isShowPdf">
                      <pdf-page :pdfUrl="articleInfo.attachmentAddress"></pdf-page>
                    </div>
                    <div
                        style="width: 100%;display: flex;justify-content: space-between;border-top:1px solid rgba(0, 0, 0, .06);">
                      <div style="width: 30%;text-align: center;margin-top: 10px">
                        <el-button v-if="articleInfo.likeStatus==1" @click="toLikeArticle(articleInfo)" type="text"
                                   icon="iconfont icon-dianzan_kuai" size="mini" :class="{darkButton:theme=='dark'}"
                                   style="color:rgba(0,0,0,.45);cursor: pointer ">
                          <span style="margin-left: 5px;color: rgba(0,0,0,.45);font-size: 14px"
                                :class="{darkButton:theme=='dark'}">{{ articleInfo.likeCount }}</span>
                        </el-button>
                        <el-button v-else type="text" @click="toLikeArticle(articleInfo)" icon="iconfont icon-dianzan"
                                   size="mini" :class="{darkButton:theme=='dark'}"
                                   style="color:rgba(0,0,0,.45);cursor: pointer ">
                          <span style="margin-left: 5px;color: rgba(0,0,0,.45);font-size: 14px"
                                :class="{darkButton:theme=='dark'}">{{ articleInfo.likeCount }}</span>
                        </el-button>
                      </div>
                      <div style="width: 30%;text-align: center;margin-top: 10px">

                        <el-button v-if="articleInfo.collectStatus==1" @click="toCollect(articleInfo)" type="text"
                                   icon="iconfont icon-shoucang3" size="mini"
                                   style="color:rgba(0,0,0,.45); cursor: pointer" :class="{darkButton:theme=='dark'}">
                          <span style="margin-left: 5px;color: rgba(0,0,0,.45);font-size: 14px"
                                :class="{darkButton:theme=='dark'}">{{ articleInfo.collectCount }}</span>
                        </el-button>

                        <el-button v-else type="text" @click="toCollect(articleInfo)" icon="iconfont icon-shoucang8"
                                   size="mini"
                                   style="color:rgba(0,0,0,.45); cursor: pointer" :class="{darkButton:theme=='dark'}">
                          <span style="margin-left: 5px;color: rgba(0,0,0,.45);font-size: 14px"
                                :class="{darkButton:theme=='dark'}">{{ articleInfo.collectCount }}</span>
                        </el-button>
                      </div>
                      <div style="width: 30%;text-align: center;margin-top: 10px">
                        <el-button type="text" icon="iconfont icon-fenxiang" size="mini"
                                   :class="{darkButton:theme=='dark'}" style="color:rgba(0,0,0,.45);cursor: pointer "
                                   @click="toshare" class="copy-btn">
                          <span style="margin-left: 5px;color: rgba(0,0,0,.45);font-size: 14px"
                                :class="{darkButton:theme=='dark'}">分享</span>
                        </el-button>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-card>
            <el-card shadow="never" v-if="loadingArticle" :body-style="theme=='dark'?darkCard:''"
                     :class="{darkCard:theme=='dark'}">
              <el-skeleton :rows="4" animated/>
            </el-card>
            <el-row style="margin-top: 10px">
              <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                  <el-card style="" shadow="never" :body-style="theme=='dark'?darkCard:''"
                           :class="{darkCard:theme=='dark'}">

                    <div slot="header" class="clearfix">
                      <div style="width: 100%;height: 30px;">
                        <span>评论（{{ total }}）</span>
                        <el-radio-group v-model="sort" style="float: right" size="mini"
                                        @input="getCommentList(articleInfo.id)">
                          <el-radio-button :label="0">最新</el-radio-button>
                          <el-radio-button :label="1">最热</el-radio-button>
                        </el-radio-group>
                      </div>
                    </div>
                    <div class="comment-card-body">
                      <div class="comment-wrapper" style="display: flex">
                        <div style="margin-right: 10px">
                          <el-avatar :size="40" :src="userInfo.avatar"></el-avatar>
                        </div>
                        <div style="border: 1px solid #ccc; width: calc(100% - 50px)">
                          <!-- 工具栏 -->
                          <Toolbar
                              style="border-bottom: 1px solid #ccc"
                              :editor="editor"
                              :defaultConfig="toolbarConfig"
                              :mode="mode"
                          />
                          <!-- 编辑器 -->
                          <Editor
                              style="height: 300px; overflow-y: hidden;"
                              :defaultConfig="editorConfig"
                              v-model="commentText"
                              @onCreated="onCreated"
                              :mode="mode"
                              @onChange="onChange"
                          />
                        </div>
                      </div>
                      <el-button type="primary" style="float: right;margin-top: 20px;" size="mini" @click="addComment">
                        评论
                      </el-button>

                      <div style="margin-top: 80px;margin-bottom: 80px">
                        <div class="comment-list" v-for="(item, index) in commentList" :key="item.id">
                          <div>

                            <div class="author-message">
                              <el-avatar
                                  :src="item.userVO.avatar"
                                  :size="40"
                              >
                              </el-avatar>
                              <el-popover
                                  :popper-class="theme=='dark'?'popperClass':''"
                                  placement="bottom"
                                  width="350"
                                  trigger="hover"
                              >
                                <div class="author-message">
                                  <el-avatar
                                      :src="item.userVO.avatar"
                                      :size="40"
                                  >
                                  </el-avatar>

                                  <div class="author-message-right">
                                    <span class="hoverSpan" @click="showUser(item.userVO.id)"
                                          style="margin-left: 10px;cursor: pointer; color: rgb(251, 124, 161)">{{
                                        item.userVO.nickname
                                      }}</span>
                                    <el-tag class="author-Title" type="info" size="mini"
                                            v-if="item.userVO.userLabel=='Lv1 关关雎鸠'"
                                    >{{ item.userVO.userLabel }}
                                    </el-tag>

                                    <el-tag class="author-Title" type="danger" size="mini"
                                            v-else-if="item.userVO.userLabel=='Lv7 无人能敌'"
                                    >{{ item.userVO.userLabel }}
                                    </el-tag>

                                    <el-tag class="author-Title" type="success" size="mini" v-else
                                    >{{ item.userVO.userLabel }}
                                    </el-tag>

                                    <el-tag class="author-Title" type="danger" size="mini"
                                            v-if="item.userVO.account=='admin'">
                                      站长
                                    </el-tag>
                                    <!--关注按钮-->
                                    <el-button type="primary" size="mini" style="margin-left: 10px"
                                               v-if="item.userVO.id!=userInfo.id&&item.userVO.followStatus==0"
                                               @click="followUser(item.userVO)">关注
                                    </el-button>
                                    <el-button type="primary" size="mini" style="margin-left: 10px"
                                               v-if="item.userVO.id==userInfo.id" @click="toMyHome">主页
                                    </el-button>
                                    <el-button type="primary" plain size="mini" style="margin-left: 10px"
                                               v-if="item.userVO.id!=userInfo.id&&item.userVO.followStatus==1"
                                               @click="followUser(item.userVO)">已关注
                                    </el-button>
                                  </div>
                                </div>
                                <div style="margin-top: 10px">
                                  <!--简介-->
                                  <div>
                                    简介：
                                    <span>{{ item.userVO.signature }}</span>
                                  </div>
                                  <el-divider></el-divider>
                                  <!--粉丝关注积分-->
                                  <div style="display: flex;width: 100%">
                                    <span style="width: 33%;cursor: pointer;" class="hoverSpan"
                                          @click="toUserFan(item.userVO.id)">粉丝:<span
                                        style="margin-left: 5px">{{ item.userVO.fansCount }}</span></span>
                                    <span style="width: 33%;cursor: pointer" class="hoverSpan"
                                          @click="toUserFollow(item.userVO.id)">关注:<span
                                        style="margin-left: 5px">{{ item.userVO.followCount }}</span></span>
                                    <span style="width: 33%;">积分:<span style="margin-left: 5px">{{
                                        item.userVO.score
                                      }}</span></span>
                                  </div>
                                </div>
                                <div slot="reference" class="author-message-right">
                                  <span class="hoverSpan" @click="showUser(item.userVO.id)"
                                        style="margin-left: 10px;cursor: pointer; color: rgb(251, 124, 161)">{{
                                      item.userVO.nickname
                                    }}</span>
                                  <el-tag class="author-Title" type="info" size="mini"
                                          v-if="item.userVO.userLabel=='Lv1 关关雎鸠'"
                                  >{{ item.userVO.userLabel }}
                                  </el-tag>

                                  <el-tag class="author-Title" type="danger" size="mini"
                                          v-else-if="item.userVO.userLabel=='Lv7 无人能敌'"
                                  >{{ item.userVO.userLabel }}
                                  </el-tag>

                                  <el-tag class="author-Title" type="success" size="mini" v-else
                                  >{{ item.userVO.userLabel }}
                                  </el-tag>

                                  <el-tag class="author-Title" type="danger" size="mini"
                                          v-if="item.userVO.account=='admin'">
                                    站长
                                  </el-tag>
                                </div>
                              </el-popover>
                            </div>

                            <div style="margin-left: 50px">
                              <p style="margin: 0;font-size: 15px" v-html="item.content"></p>
                              <p style="font-size: 14px;color: #8c939d">
                                <span style="color:rgba(138,138,137,0.8);">{{ item.createTime }}</span>

                                <span style="margin-left: 8px; margin-right: 8px;cursor: pointer" v-if="item.likeStatus==1" @click="likeComment(item)">
                                   <i class="iconfont icon-dianzan_kuai tubiao"
                                      :theme="theme"></i> {{item.likeCount}}人赞
                                </span>
                                <span v-else style="margin-left: 8px; margin-right: 8px;cursor: pointer" @click="likeComment(item)">
                                <i class="iconfont icon-dianzan"></i>赞
                                </span>
                                <span @click="showReply(item,item)" style="cursor: pointer;color: #8c939d">回复<i
                                    class="iconfont icon-chakantiezihuifu"></i></span>
                              </p>
                            </div>
                          </div>
                          <!--只展示6条评论-->
                          <div style="margin-left: 50px" v-for="(son) in  slicedReplies(item)" :key="son.id">
                            <div v-if="son.parentId==item.id">
                              <div class="author-message">
                                <el-avatar
                                    :src="son.userVO.avatar"
                                    :size="40"
                                >
                                </el-avatar>
                                <el-popover
                                    :popper-class="theme=='dark'?'popperClass':''"
                                    placement="bottom"
                                    width="350"
                                    trigger="hover"
                                >
                                  <div class="author-message">
                                    <el-avatar
                                        :src="son.userVO.avatar"
                                        :size="40"
                                    >
                                    </el-avatar>

                                    <div class="author-message-right">
                                    <span class="hoverSpan" @click="showUser(son.userVO.id)"
                                          style="margin-left: 10px;cursor: pointer; color: rgb(251, 124, 161)">{{
                                        son.userVO.nickname
                                      }}</span>
                                      <el-tag class="author-Title" type="info" size="mini"
                                              v-if="son.userVO.userLabel=='Lv1 关关雎鸠'"
                                      >{{ son.userVO.userLabel }}
                                      </el-tag>

                                      <el-tag class="author-Title" type="danger" size="mini"
                                              v-else-if="son.userVO.userLabel=='Lv7 无人能敌'"
                                      >{{ son.userVO.userLabel }}
                                      </el-tag>

                                      <el-tag class="author-Title" type="success" size="mini" v-else
                                      >{{ son.userVO.userLabel }}
                                      </el-tag>

                                      <el-tag class="author-Title" type="danger" size="mini"
                                              v-if="son.userVO.account=='admin'">
                                        站长
                                      </el-tag>
                                      <!--关注按钮-->
                                      <el-button type="primary" size="mini" style="margin-left: 10px"
                                                 v-if="son.userVO.id!=userInfo.id&&son.userVO.followStatus==0"
                                                 @click="followUser(son.userVO)">关注
                                      </el-button>
                                      <el-button type="primary" size="mini" style="margin-left: 10px"
                                                 v-if="son.userVO.id==userInfo.id" @click="toMyHome">主页
                                      </el-button>
                                      <el-button type="primary" plain size="mini" style="margin-left: 10px"
                                                 v-if="son.userVO.id!=userInfo.id&&son.userVO.followStatus==1"
                                                 @click="followUser(son.userVO)">已关注
                                      </el-button>
                                    </div>
                                  </div>
                                  <div style="margin-top: 10px">
                                    <!--简介-->
                                    <div>
                                      简介：
                                      <span>{{ son.userVO.signature }}</span>
                                    </div>
                                    <el-divider></el-divider>
                                    <!--粉丝关注积分-->
                                    <div style="display: flex;width: 100%">
                                      <span style="width: 33%;cursor: pointer;" class="hoverSpan"
                                            @click="toUserFan(son.userVO.id)">粉丝:<span
                                          style="margin-left: 5px">{{ son.userVO.fansCount }}</span></span>
                                      <span style="width: 33%;cursor: pointer" class="hoverSpan"
                                            @click="toUserFollow(son.userVO.id)">关注:<span
                                          style="margin-left: 5px">{{ son.userVO.followCount }}</span></span>
                                      <span style="width: 33%;">积分:<span style="margin-left: 5px">{{
                                          son.userVO.score
                                        }}</span></span>
                                    </div>
                                  </div>
                                  <div slot="reference" class="author-message-right">
                                  <span class="hoverSpan" @click="showUser(son.userVO.id)"
                                        style="margin-left: 10px;cursor: pointer; color: rgb(251, 124, 161)">{{
                                      son.userVO.nickname
                                    }}</span>
                                    <el-tag class="author-Title" type="info" size="mini"
                                            v-if="son.userVO.userLabel=='Lv1 关关雎鸠'"
                                    >{{ son.userVO.userLabel }}
                                    </el-tag>

                                    <el-tag class="author-Title" type="danger" size="mini"
                                            v-else-if="son.userVO.userLabel=='Lv7 无人能敌'"
                                    >{{ son.userVO.userLabel }}
                                    </el-tag>

                                    <el-tag class="author-Title" type="success" size="mini" v-else
                                    >{{ son.userVO.userLabel }}
                                    </el-tag>

                                    <el-tag class="author-Title" type="danger" size="mini"
                                            v-if="son.userVO.account=='admin'">
                                      站长
                                    </el-tag>

                                  </div>
                                </el-popover>
                              </div>
                              <div style="margin-left: 50px">
                                <p v-html="son.content" style="margin: 0;font-size: 15px"></p>
                                <p style="font-size: 14px;color: #8c939d">
                                  <span style="color:rgba(138,138,137,0.8);">{{ son.createTime }}</span>
                                  <span style="margin-left: 8px; margin-right: 8px;cursor: pointer" v-if="son.likeStatus==1" @click="likeComment(son)">
                                   <i class="iconfont icon-dianzan_kuai tubiao"
                                      :theme="theme"></i> {{son.likeCount}}人赞
                                </span>
                                  <span v-else style="margin-left: 8px; margin-right: 8px;cursor: pointer" @click="likeComment(son)">
                                <i class="iconfont icon-dianzan"></i>赞
                                </span>
                                  <span @click="showReply(item,son)" style="cursor: pointer;color: #8c939d">回复<i
                                      class="iconfont icon-chakantiezihuifu"></i></span>
                                </p>
                              </div>
                            </div>
                            <div v-else>
                              <div class="author-message">
                                <el-avatar
                                    :src="son.userVO.avatar"
                                    :size="40"
                                >
                                </el-avatar>
                                <el-popover
                                    :popper-class="theme=='dark'?'popperClass':''"
                                    placement="bottom"
                                    width="350"
                                    trigger="hover"
                                >
                                  <div class="author-message">
                                    <el-avatar
                                        :src="son.userVO.avatar"
                                        :size="40"
                                    >
                                    </el-avatar>
                                    <div class="author-message-right">
                                  <span class="hoverSpan"
                                        style="margin-left: 10px;cursor: pointer; color: rgb(251, 124, 161)">{{
                                      son.userVO.nickname
                                    }}</span>
                                      <el-tag class="author-Title" type="info" size="mini"
                                              v-if="son.userVO.userLabel=='Lv1 关关雎鸠'"
                                      >{{ son.userVO.userLabel }}
                                      </el-tag>

                                      <el-tag class="author-Title" type="danger" size="mini"
                                              v-else-if="son.userVO.userLabel=='Lv7 无人能敌'"
                                      >{{ son.userVO.userLabel }}
                                      </el-tag>

                                      <el-tag class="author-Title" type="success" size="mini" v-else
                                      >{{ son.userVO.userLabel }}
                                      </el-tag>

                                      <el-tag class="author-Title" type="danger" size="mini"
                                              v-if="son.userVO.account=='admin'">
                                        站长
                                      </el-tag>
                                      <!--关注按钮-->
                                      <el-button type="primary" size="mini" style="margin-left: 10px"
                                                 v-if="son.userVO.id!=userInfo.id&&son.userVO.followStatus==0"
                                                 @click="followUser(son.userVO)">关注
                                      </el-button>
                                      <el-button type="primary" size="mini" style="margin-left: 10px"
                                                 v-if="son.userVO.id==userInfo.id" @click="toMyHome">主页
                                      </el-button>
                                      <el-button type="primary" plain size="mini" style="margin-left: 10px"
                                                 v-if="son.userVO.id!=userInfo.id&&son.userVO.followStatus==1"
                                                 @click="followUser(son.userVO)">已关注
                                      </el-button>
                                    </div>
                                  </div>
                                  <div style="margin-top: 10px">
                                    <!--简介-->
                                    <div>
                                      简介：
                                      <span>{{ son.userVO.signature }}</span>
                                    </div>
                                    <el-divider></el-divider>
                                    <!--粉丝关注积分-->
                                    <div style="display: flex;width: 100%">
                                      <span style="width: 33%;cursor: pointer;" class="hoverSpan"
                                            @click="toUserFan(son.userVO.id)">粉丝:<span
                                          style="margin-left: 5px">{{ son.userVO.fansCount }}</span></span>
                                      <span style="width: 33%;cursor: pointer" class="hoverSpan"
                                            @click="toUserFollow(son.userVO.id)">关注:<span
                                          style="margin-left: 5px">{{ son.userVO.followCount }}</span></span>
                                      <span style="width: 33%;">积分:<span style="margin-left: 5px">{{
                                          son.userVO.score
                                        }}</span></span>
                                    </div>
                                  </div>
                                  <div slot="reference" class="author-message-right">
                                  <span class="hoverSpan"
                                        style="margin-left: 10px;cursor: pointer; color: rgb(251, 124, 161)">{{
                                      son.userVO.nickname
                                    }}</span>
                                    <el-tag class="author-Title" type="info" size="mini"
                                            v-if="son.userVO.userLabel=='Lv1 关关雎鸠'"
                                    >{{ son.userVO.userLabel }}
                                    </el-tag>

                                    <el-tag class="author-Title" type="danger" size="mini"
                                            v-else-if="son.userVO.userLabel=='Lv7 无人能敌'"
                                    >{{ son.userVO.userLabel }}
                                    </el-tag>

                                    <el-tag class="author-Title" type="success" size="mini" v-else
                                    >{{ son.userVO.userLabel }}
                                    </el-tag>

                                    <el-tag class="author-Title" type="danger" size="mini"
                                            v-if="son.userVO.account=='admin'">
                                      站长
                                    </el-tag>
                                  </div>
                                </el-popover>
                                <span style="color: rgba(101,101,95,0.8);margin-left: 10px;">回复</span>
                                <el-popover
                                    :popper-class="theme=='dark'?'popperClass':''"
                                    placement="bottom"
                                    width="350"
                                    trigger="hover"
                                >
                                  <div class="author-message">
                                    <el-avatar
                                        :src="son.replyUserVO.avatar"
                                        :size="40"
                                    >
                                    </el-avatar>
                                    <div class="author-message-right">
                                  <span class="hoverSpan"
                                        style="margin-left: 10px;cursor: pointer; color: rgb(251, 124, 161)">{{
                                      son.replyUserVO.nickname
                                    }}</span>
                                      <el-tag class="author-Title" type="info" size="mini"
                                              v-if="son.userVO.userLabel=='Lv1 关关雎鸠'"
                                      >{{ son.replyUserVO.userLabel }}
                                      </el-tag>

                                      <el-tag class="author-Title" type="danger" size="mini"
                                              v-else-if="son.replyUserVO.userLabel=='Lv7 无人能敌'"
                                      >{{ son.replyUserVO.userLabel }}
                                      </el-tag>

                                      <el-tag class="author-Title" type="success" size="mini" v-else
                                      >{{ son.replyUserVO.userLabel }}
                                      </el-tag>

                                      <el-tag class="author-Title" type="danger" size="mini"
                                              v-if="son.replyUserVO.account=='admin'">
                                        站长
                                      </el-tag>
                                      <!--关注按钮-->
                                      <el-button type="primary" size="mini" style="margin-left: 10px"
                                                 v-if="son.replyUserVO.id!=userInfo.id&&son.replyUserVO.followStatus==0"
                                                 @click="followUser(son.replyUserVO)">关注
                                      </el-button>
                                      <el-button type="primary" size="mini" style="margin-left: 10px"
                                                 v-if="son.replyUserVO.id==userInfo.id" @click="toMyHome">主页
                                      </el-button>
                                      <el-button type="primary" plain size="mini" style="margin-left: 10px"
                                                 v-if="son.replyUserVO.id!=userInfo.id&&son.replyUserVO.followStatus==1"
                                                 @click="followUser(son.replyUserVO)">已关注
                                      </el-button>
                                    </div>
                                  </div>
                                  <div style="margin-top: 10px">
                                    <!--简介-->
                                    <div>
                                      简介：
                                      <span>{{ son.replyUserVO.signature }}</span>
                                    </div>
                                    <el-divider></el-divider>
                                    <!--粉丝关注积分-->
                                    <div style="display: flex;width: 100%">
                                      <span style="width: 33%;cursor: pointer;" class="hoverSpan"
                                            @click="toUserFan(son.replyUserVO.id)">粉丝:<span
                                          style="margin-left: 5px">{{ son.replyUserVO.fansCount }}</span></span>
                                      <span style="width: 33%;cursor: pointer" class="hoverSpan"
                                            @click="toUserFollow(son.replyUserVO.id)">关注:<span
                                          style="margin-left: 5px">{{ son.replyUserVO.followCount }}</span></span>
                                      <span style="width: 33%;">积分:<span style="margin-left: 5px">{{
                                          son.replyUserVO.score
                                        }}</span></span>
                                    </div>
                                  </div>
                                  <div slot="reference" class="author-message-right">
                                  <span class="hoverSpan"
                                        style="margin-left: 10px;cursor: pointer; color: rgb(251, 124, 161)">@{{
                                      son.replyUserVO.nickname
                                    }}</span>
                                  </div>
                                </el-popover>
                              </div>
                              <div style="margin-left: 50px">
                                <p v-html="son.content" style="margin: 0;font-size: 15px"></p>
                                <p style="font-size: 14px;color: #8c939d">
                                  <span style="color:rgba(138,138,137,0.8);">{{ son.createTime }}</span>
                                  <span style="margin-left: 8px; margin-right: 8px;cursor: pointer" v-if="son.likeStatus==1" @click="likeComment(son)">
                                   <i class="iconfont icon-dianzan_kuai tubiao"
                                      :theme="theme"></i> {{son.likeCount}}人赞
                                </span>
                                  <span v-else style="margin-left: 8px; margin-right: 8px;cursor: pointer" @click="likeComment(son)">
                                <i class="iconfont icon-dianzan"></i>赞
                                </span>
                                  <span @click="showReply(item,son)" style="cursor: pointer;color: #8c939d">回复<i
                                      class="iconfont icon-chakantiezihuifu"></i></span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div style="margin-left: 50px" v-if="showReplyCount < item.replyComments.length">
                            <span style="font-size: 14px;color: #8c939d">共{{ item.replyComments.length }}条回复,</span>
                            <el-button type="text" @click="loadMoreReplies" style="color: #3a8ee6;cursor: pointer">
                              查看更多
                            </el-button>
                          </div>


                          <div style="margin-left: 50px; " v-if="item.id==showreplyId" id="replyBar">
                            <div class="comment-wrapper">
                              <div style="border: 1px solid #ccc; width:100%">
                                <!-- 工具栏 -->
                                <Toolbar
                                    style="border-bottom: 1px solid #ccc"
                                    :editor="editor2"
                                    :defaultConfig="toolbarConfig"
                                    :mode="mode"
                                />
                                <!-- 编辑器 -->
                                <Editor
                                    style="height: 300px; overflow-y: hidden;"
                                    :defaultConfig="editorConfig"
                                    v-model="commentText2"
                                    @onCreated="onCreated2"
                                    :mode="mode"
                                    @onChange="onChange2"
                                />
                              </div>
                            </div>
                            <div style="display: flex;justify-content: end">
                              <el-button type="primary" style=" margin-top: 20px;" size="mini" @click="replyComment">
                                回复
                              </el-button>
                            </div>
                          </div>
                          <el-divider></el-divider>
                        </div>
                        <el-pagination
                            v-show="commentList.length"
                            :small="true"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[10, 15, 20, 30]"
                            :page-size="pageSize"
                            :pager-count="pageCount"
                            :layout="layout"
                            :total="total">
                        </el-pagination>
                      </div>
                    </div>
                    <!--                    <el-empty  :image-size="100" description="暂无数据"></el-empty>-->
                  </el-card>
                  <el-card shadow="never" v-if="loadingComment" :body-style="theme=='dark'?darkCard:''"
                           :class="{darkCard:theme=='dark'}">
                    <el-skeleton :rows="4" animated/>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="8" class="index-right">
          <div class="grid-content bg-purple-light">
            <el-row style="margin-bottom: 20px" id="otherPostRef">
              <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                  <el-card  shadow="never" :body-style="theme=='dark'?darkCard:''"
                           :class="{darkCard:theme=='dark'}">
                    <div slot="header" class="clearfix">
                      <span>作者其他文章</span>
                      <el-button style="float: right; padding: 3px 0" type="text" @click="$router.push(`/user/${articleInfo.user.id}/post`)">更多</el-button>
                    </div>
                    <div v-if="authorOtherPost.length==0">
                      <el-empty :image-size="100" description="暂无数据"></el-empty>
                    </div>
                    <div v-else>
                      <div v-for="(item, index) in authorOtherPost" :key="index">
                        <el-card class="box-card"
                                 shadow="never" style="margin-bottom: 5px">
                          <div class="text item">
                            <div @click="toOtherPost(item.id)" style="cursor: pointer" class="hoverSpan">{{ item.title }}</div>
                            <div class="bottom clearfix" style="margin-top: 10px;color: #8c939d;font-size: 14px">
                              <span >{{ item.updateTime }}</span>
                              <span style="float: right">阅读{{ item.viewCount }}</span>
                            </div>
                          </div>
                        </el-card>
                      </div>
                    </div>


                  </el-card>
                </div>
              </el-col>
            </el-row>
            <el-row ref="navRow" :class="{fixed:isFixed==true}" v-if="cateList.length">
              <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                  <!--显示目录-->
                  <el-card class="navCard" style="max-height: 600px;overflow-y: scroll"
                           :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
                    <div slot="header" class="clearfix">
                      <span>目录</span>
                    </div>
                    <div class="marker-card">
                      <a v-for="(item, index) in cateList" :key="index"
                         :class="[{ active: anchor == index }, item.tagName + '-class']" class="marker-item"
                         @click="scrollToSection('head-' + index, index)">{{ item.text }}</a>
                    </div>
                  </el-card>
                </div>
                <div class="grid-content bg-purple-dark">
                  <div>
                    <div style="color: rgb(0, 11, 54);font-size: 14px" :class="{darkFooter:theme=='dark'}">
                      <p>
                        <el-link href="https://tobeyou.cn" target="_blank" :underline="false"
                                 :class="{darkFooter:theme=='dark'}"><i
                            class="iconfont icon-31wode"></i>站长：xiaolinzi
                        </el-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<el-link href="https://chat.tobeyou.cn"
                                                                               :class="{darkFooter:theme=='dark'}"
                                                                               target="_blank" :underline="false"><i
                          class="iconfont icon-duihua1"></i>WebSocket聊天室
                      </el-link>
                      </p>
                      <p>
                        <el-link href="https://tobeyou.cn" target="_blank" :underline="false"
                                 :class="{darkFooter:theme=='dark'}"><i
                            class="iconfont icon-beiwanglu"></i>建议反馈
                        </el-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;站长邮箱：xiaolin_zii@163.com
                      </p>
                      <p>Copyright©2024 | 粤ICP备号xxxxxx</p>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-backtop target=".main"></el-backtop>
  </div>
</template>
<script>

import clipboard from 'clipboard';
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css'
import {Viewer} from '@bytemd/vue'
// import watermark from '@/assets/js/watermark1.js'
// 这里就是引入所有的扩展的插件
import 'bytemd/dist/index.css'  // 导入编辑器样式
import mediumZoom from '@bytemd/plugin-medium-zoom' //图片放大预览
import 'highlight.js/styles/vs.css'
import 'juejin-markdown-themes/dist/juejin.min.css'  // 其实就是需要这个css文件
import pdfPage from "@/components/pdfPage";
import {getProcessor} from "bytemd";
import articleApi from "@/api/article";
import followApi from "@/api/follow";
import commentApi from "@/api/comment";
import collectApi from "@/api/collect";
import likeApi from "@/api/like";
import userApi from "@/api/user";
const plugins = [
  mediumZoom(),
]


export default {
  components: {
    Viewer,
    pdfPage,
    Editor,
    Toolbar
  },
  data() {
    return {
      likeInfo: {
        type: 1,
        targetId: ''
      },
      loadingArticle: false,
      loadingComment: false,
      replyId: '',
      showreplyId: '',
      currentPage: 1,
      currentReplyPage: 1,
      pageSize: 10,
      replyPageSize: 1,
      total: 0,
      pageCount: 9,
      pageReplyCount: 5,
      layout: 'total, sizes, prev, pager, next, jumper',
      commentList: [],
      darkCard: {
        backgroundColor: "rgb(17,17,17)",
      },
      theme: 'light',
      editor: null,
      editor2: null,
      commentText: '',
      commentText2: '',
      toolbarConfig: {
        excludeKeys: [
          'headerSelect',
          'blockquote',
          'group-more-style',
          'color',
          'bgColor',
          'fontSize',
          'lineHeight',
          'todo',
          'group-indent',
          'group-video',
          'group-justify',
          'insertTable',
          'divider',

        ],
      },
      editorConfig: {
        placeholder: '欢迎发表你的想法',
        MENU_CONF: {
          uploadImage: {
            server: 'https://47.120.61.197/oss/uploadOssFile',
            fieldName: "file",
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 5 * 1024 * 1024,
            // 最大上传图片数量
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],
            customInsert(res, insertFn) {                  // JS 语法
              // res 即服务端的返回结果
              const url = res.data.url
              const alt = res.data.url
              const href = url
              // 从 res 中找到 url alt href ，然后插入图片
              insertFn(url, alt, href)
            },
          }
        },

      },
      mode: 'default', // or 'simple'
      sort: 0,
      authorOtherPost: [],
      isFixed: false,
      plugins,
      isShowPdf: false,
      articleInfo: {
        user: {}
      },
      activeName: 'tab0',
      tabPosition: 'right',
      cateList: [], // 目录内容
      offsetTopList: [], //文档流中锚点距离顶部距离集合
      anchor: 0,
      commnetInfo: {},
      articleId: '',
      showReplyCount: 5,
    }
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  created() {
    //从路由中获取id，查找到对应的文章
    this.articleId = this.$route.params.id;
    //查询文章详情
    this.loadingArticle = true;
    articleApi.getArticleById(this.articleId).then(res => {
      if (!res.data.success) {
        //跳转回首页
        this.loadingArticle = false;
        this.$message.warning(res.data.message);
        this.$router.push(`/square/index`);
      } else {
        this.articleInfo = res.data.data.articleVO;
        //获取作者其他文章
        userApi.getUserHotArticle(this.articleInfo.user.id).then(res => {
          this.authorOtherPost = res.data.data.hotArticleList;
        }).catch(() => {
        });
        this.loadingArticle = false;
        const text = this.articleInfo.user.nickname;
        this.$watermark({
          text: text,
          selectors: ".watermarkContent",
          color: "rgba(0, 0, 0, .15)",
        });
        this.getCataLogData();
        this.$nextTick(() => {
          this.transformToId()
          // 获取内容的所有锚点距离顶部的距离
          this.getCalcLateTop()
          // 监听页面滚动获取当前第几个锚点
          window.addEventListener('scroll', this.scrollHandle, true)
          window.onresize = () => {
            this.getCalcLateTop()
          }
        })
        //文章阅读数+1
        articleApi.addViewCount(this.articleId).then(res => {

        }).catch(() => {
        });

        this.getCommentList(this.articleId);
      }
    })



  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    slicedReplies() {
      return (item) => {
        return item.replyComments.slice(0, this.showReplyCount);
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandle)
    window.onresize = null;
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁 editor ，重要！！！
    const editor2 = this.editor2
    if (editor2 == null) return
    editor2.destroy() // 组件销毁时，及时销毁 editor ，重要！！！
  },

  methods: {
    loadMoreReplies() {
      this.showReplyCount += 5;
    },
    toCollect(article) {
      collectApi.collectOrUnCollect(article.id).then(res => {
        article.collectCount = res.data.data.num;
        article.collectStatus = res.data.data.status;
      }).catch(() => {
      });
    },
    toLikeArticle(article) {
      this.likeInfo.targetId = article.id;
      this.likeInfo.type = 1;
      likeApi.likeOrUnLike(this.likeInfo).then(res => {
        article.likeCount = res.data.data.num;
        article.likeStatus = res.data.data.status;
      }).catch(() => {
      });
    },
    likeComment(comment) {
      this.likeInfo.targetId = comment.id;
      this.likeInfo.type = 2;
      likeApi.likeOrUnLike(this.likeInfo).then(res => {
        comment.likeCount = res.data.data.num;
        comment.likeStatus = res.data.data.status;
      }).catch(() => {
      });
    },
    toUserFan(id) {
      if (id == this.userInfo.id)
        this.$router.push(`/self/followed`)
      else
        this.$router.push(`/user/followed`)
    },
    toUserFollow(id) {
      if (id == this.userInfo.id)
        this.$router.push(`/self/follow`)
      else
        this.$router.push(`/user/follow`)
    },
    showReply(item, son) {
      this.showreplyId = item.id;
      this.replyId = son.id;
      console.log(this.replyId)
      this.editorConfig.placeholder = `回复@${item.userVO.nickname}`;
      //定位到评论框
      //由于评论框是动态生成的，所以需要在下一个事件循环中执行
      this.$nextTick(() => {
        const dom = document.getElementById('replyBar');
        console.log(dom);
        if (dom) {
          dom.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      });
    },
    getCommentList(id) {
      //获取评论列表
      this.loadingComment = true;
      this.commentList = [];
      commentApi.getComments(id, this.currentPage, this.pageSize, this.sort).then(res => {
        this.commentList = res.data.data.comments;
        this.total = res.data.data.total;
        this.loadingComment = false;
        //遍历评论列表，获取评论的回复列表
        this.commentList.forEach(item => {
          //反转数组，使最新的回复在下面
          item.replyComments.reverse();
        });
      }).catch(() => {
      });
    },
    addComment() {
      if (this.commentText == '') {
        this.$message.warning('评论内容不能为空');
        return;
      }
      this.commnetInfo.content = this.commentText;
      this.commnetInfo.articleId = this.articleInfo.id;
      commentApi.postComment(this.commnetInfo).then(res => {
        this.$message.success('评论成功');
        this.commentText = '';
        this.getCommentList(this.articleInfo.id);
        this.showreplyId = '';
      }).catch(() => {
      });
    },
    //回复评论
    replyComment() {
      if (this.commentText2 == '') {
        this.$message.warning('评论内容不能为空');
        return;
      }
      this.commnetInfo.content = this.commentText2;
      this.commnetInfo.articleId = this.articleInfo.id;
      this.commnetInfo.commentId = this.replyId;
      commentApi.postComment(this.commnetInfo).then(res => {
        this.$message.success('回复成功');
        this.commentText2 = '';
        this.getCommentList(this.articleInfo.id);
        this.showreplyId = '';
      }).catch(() => {
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCommentList(this.articleId);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getCommentList(this.articleId);
    },
    handleReplySizeChange(val) {
      this.replyPageSize = val;
    },
    handleReplyCurrentChange(val) {
      this.currentReplyPage = val;
    },
    handleResize() {
      if (window.innerWidth < 700) {
        this.pageCount = 5;
        this.layout = 'total,prev, pager, next';
      } else {
        this.pageCount = 9;
        this.layout = 'total, sizes, prev, pager, next, jumper';
      }
    },
    followUser(user) {
      followApi.followOrUnFollow(user.id).then(res => {
        user.followStatus = res.data.data.status;
        user.fansCount = res.data.data.num;
      }).catch(() => {
      });
    },
    toMyHome() {
      this.$router.push(`/user/${this.userInfo.id}/info`)
    },
    showUser(id) {
      //跳转到该用户信息页
      this.$router.push(`/user/${id}/info`)
    },
    toshare() {
      const text = `我在知识问答社区发现了《${this.articleInfo.title}》 快来看看 ${window.location.href}`
      clipboard.copy(text)
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    onChange(editor) {
      const html = editor.getHtml()
      this.commentText = html
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
    },
    onChange2(editor) {
      const html = editor.getHtml()
      this.commentText2 = html
    },
    onCreated2(editor) {
      this.editor2 = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
    },
    toOtherPost(id) {
      //路径变了，但是页面没有刷新，怎么解决？
      this.$router.push(`/square/post/${id}`);
    },
    // 获取内容
    getCataLogData() {
      getProcessor({
        plugins: [
          {
            rehype: p =>
                p.use(() => tree => {
                  if (tree && tree.children.length) {
                    this.createCataLog(tree)
                  }
                }),
          },
        ],
      }).processSync(this.articleInfo.content)
    },
    createCataLog(tree) {
      const items = []
      tree.children
          .filter(v => v.type == 'element')
          .forEach(node => {
            if (node.tagName === 'h2' || node.tagName === 'h1' || node.tagName === 'h3' || node.tagName === 'h4' || node.tagName === 'h5' && node.children.length > 0) {
              items.push({
                tagName: node.tagName,
                text: this.stringifyHeading(node),
              })
            }
          })
      this.cateList = items
    },
    stringifyHeading(node) {
      let result = ''
      node.children.forEach(item => {
        if (item.type == 'text') {
          result += item.value
        }
      })
      return result
    },
    /*
      *@Description: 设置锚点ID
      *@MethodAuthor: peak1024
      *@Date: 2023-10-25 17:03:21
      */
    transformToId() {
      const dom = document.querySelector('.markdown-body');
      let children = Array.from(dom.children);
      let idCount = {};

      if (children.length > 0) {
        for (let i = 0; i < children.length; i += 1) {
          const tagName = children[i].tagName;

          if (tagName === 'H1' || tagName === 'H2' || tagName === 'H3' || tagName === 'H4' || tagName === 'H5') {
            const textContent = children[i].textContent.trim();
            idCount[textContent] = idCount[textContent] ? idCount[textContent] + 1 : 1;

            const matchIndex = idCount[textContent]; // 匹配到第几个相同标题

            // 找到第matchIndex个与当前标题text相等的this.cateList中的项
            let matchFound = 0;
            for (let j = 0; j < this.cateList.length; j++) {
              if (this.cateList[j].text === textContent) {
                matchFound += 1;
                if (matchFound === matchIndex) {
                  children[i].setAttribute('id', `head-${j}`);
                  break;
                }
              }
            }
          }
        }
      }
    },
    scrollToSection(id, index) {
      this.anchor = index
      const dom = document.getElementById(id)
      if (dom) {
        dom.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    },
    getCalcLateTop() {
      const mainEl = document.querySelector('#main')
      this.offsetTopList = this.cateList.map((item, index) => {
        const element = document.querySelector(`#head-${index}`)
        return {
          offsetTop: index === 0 ? mainEl.offsetTop : element.offsetTop,
          anchor: index,
        }
      })
    },
    scrollHandle() {
      if (this.offsetTopList.length === 0) return
      const mainEl = document.querySelector('#main')
      if (!mainEl) return
      const curScrollTop = mainEl.scrollTop
      let flag = true
      const len = this.offsetTopList.length
      const min = this.offsetTopList[0].offsetTop
      // 滚动的距离 小于 第一个锚点距离顶部的距离
      if (curScrollTop < min) {
        this.anchor = 0
        return
      }
      // 滚动的距离 与 全部锚点距离顶部距离的集合 比较 获取最近的锚点标识
      for (let i = len - 1; i >= 0; i--) {
        const curReference = this.offsetTopList[i].offsetTop // 当前参考值
        if (flag && curScrollTop >= curReference) {
          flag = false
          this.anchor = this.offsetTopList[i].anchor
        }
      }

      if (curScrollTop > 300) {
        this.isFixed = true
      } else {
        this.isFixed = false
      }


    }
  }

}

</script>
<style lang="scss">
.el-popover {
  text-align: start;
}

.popperClass.el-popover {
  background-color: rgb(17, 17, 17) !important;
  color: #ffffff !important;

  .popper__arrow::after {
    border-bottom-color: rgb(17, 17, 17) !important;
  }
}

.darkCard {
  .w-e-bar {
    background-color: rgb(17, 17, 17) !important;
  }

  .w-e-text-container {
    background-color: rgb(17, 17, 17) !important;
    color: #73685a;
  }

  .w-e-drop-panel {
    background-color: rgb(17, 17, 17) !important;
    border: 1px solid rgb(62, 62, 62);
  }

  .w-e-modal {
    background-color: rgb(17, 17, 17) !important;
    border: 1px solid rgb(62, 62, 62);
    color: white;
  }

  .w-e-select-list {
    background-color: rgb(17, 17, 17) !important;
    color: antiquewhite;

    ul .selected {
      background-color: #1589fa;
    }

    ul li:hover {
      background-color: #1589fa;
    }
  }

}
</style>
<style scoped lang="scss">
.hoverSpan:hover {
  color: #1589fa;
}

.darkButton {
  color: white !important;
}

.articlAuthorName {
  cursor: pointer;

}

.articlAuthorName:hover {
  color: #1589fa;

}

.darkFooter {
  color: white !important;
}

.copyright-cmp[theme='light'] {
  text-align: center;
  color: rgba(0, 0, 0, .45);
  font-size: 14px;
}

.copyright-cmp[theme='dark'] {
  text-align: center;
  color: rgba(255, 255, 255, .45);
  font-size: 14px;
}

.darkCard .markdown-body {
  color: white !important;
}

.postdes {
  font-size: 14px;
  color: rgba(0, 0, 0, .45);
  margin: 0
}

.darkpostdes {
  font-size: 14px;
  color: rgba(255, 255, 255, .45);
  margin: 0
}


.darkCard {
  border: rgb(62, 62, 62) solid 1px !important;
  color: #ffffff;
  background-color: rgb(17, 17, 17);
}

.darkCard > > > .el-card__header {
  border-bottom: rgb(62, 62, 62) solid 1px !important;
}


.fixed {
  position: fixed;
  top: 80px;
  z-index: 1000;
  width: 31%;
}

.w-e-full-screen-container {
  z-index: 1501;
}

.marker-card {
  width: 100%;

  .marker-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    display: block;

    &:hover {
      color: rebeccapurple;
    }

    &.h2-class {
      padding-left: 15px;
    }

    &.h3-class {
      padding-left: 30px;
    }

    &.h4-class {
      padding-left: 45px;
    }

    &.h5-class {
      padding-left: 60px;
    }

    &.active {
      color: red !important;
    }
  }
}

.navCard > > > .el-tabs--right .el-tabs__header.is-right {
  float: none !important;
  margin-left: 0;
}


.v-note-wrapper {
  border: none !important;
}

.index-left {
  margin-right: 20px;
}

.author-message {
  display: flex;
}

.author-message-right {
  line-height: 25px;
}

.author-Title {
  margin-left: 8px;
}

@media screen and (max-width: 768px) {
  .index-left {
    margin-right: 0px;
  }

  .index-right {
    display: none;
  }
}
</style>
