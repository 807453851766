import axios from 'axios'
import cookie from "js-cookie";
import router from "@/router";
import store from "@/store";
import {Message} from 'element-ui'
// 创建axios实例
const service = axios.create({
    baseURL: 'https://47.120.61.197',
    // baseURL: 'http://localhost:8888',
    // api的base_url  http://localhost:8888 https://47.120.61.197
    timeout: 20000 // 请求超时时间
})


service.interceptors.request.use(
    config => {
        // 在发送请求之前可以做些什么
        // 可以检测网络连接状态
        // if (!navigator.onLine) {
        //     // 网络断开,显示提示
        //     Message.error('网络连接失败，请检查网络连接，稍后再试！')
        //     // 取消本次请求
        //     return Promise.reject(new Error('Network Error'))
        // }

        //debugger
        if (store.state.user.token) {
            //第三步把获取的cookie值放到header
            config.headers['token'] = cookie.get('qa_token');
        }
        return config
    },
    err => {
        return Promise.reject(err);
    }
)




// http response 拦截器
service.interceptors.response.use(
    response => {
        //500
        if (response.data.code == 500){
            router.replace({
                name:'error_500',
            })
        }

        //debugger
        if (response.data.code == 401) {
            console.log("response.data.resultCode是401")
            // 返回 错误代码401 清除token信息并跳转到登录页面
            //debugger
            store.dispatch("user/LOGOUT");
            router.replace({
                path: '/login',
                query: {redirect: router.currentRoute.fullPath}
                //携带路由从什么地方失效的
            })
            return
        } else {
            return response
        }
    },
    error => {
        console.log('err' + error) // for debug
        // 网络错误处理
        if (error.message.includes('Network Error')) {
            console.log('err' + error) // for debug
            Message.error('网络连接已断开,请检查网络设置')
        } else {
            // 其他错误处理
            Message.error(`请求错误${error.message}`)
        }
        // 返回错误
        return Promise.reject(error.response.data)
    });
export default service
