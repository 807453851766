import request from '@/utils/request'

// 查询文章标签管理列表
export function listTag(query) {
  return request({
    url: `/system/tag/list/${query.pageNum}/${query.pageSize}`,
    method: 'post',
    data: {
      tagName: query.tagName,
      isDeleted: query.isDeleted,
    }
  })
}

// 查询文章标签管理详细
export function getTag(id) {
  return request({
    url: '/system/tag/' + id,
    method: 'get'
  })
}

// 新增文章标签管理
export function addTag(data) {
  return request({
    url: '/system/tag',
    method: 'post',
    data: data
  })
}

// 修改文章标签管理
export function updateTag(data) {
  return request({
    url: '/system/tag',
    method: 'put',
    data: data
  })
}

// 删除文章标签管理
export function delTag(id) {
  return request({
    url: '/system/tag/' + id,
    method: 'delete'
  })
}
