<template>
  <div class="err-page">
    <el-result>
      <template slot="icon">
        <el-image style="width: 300px;" :src="img">
        </el-image>
      </template>
      <template slot="title">
        500
      </template>
      <template slot="subTitle">
        <div class="bubbling">抱歉，服务器出错了，访问其他页面试试吧</div>
      </template>
      <template slot="extra">
        <el-button type="primary" size="medium" @click="$router.go(-1)">返回</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
import img from '@/assets/img/500.png'
export default {
  name: 'NotFound',
  data() {
    return {
      img: img
    }
  },
  methods: {
    bubble() {
      const requestInterval = (fn, delay) => {
        let start = new Date().getTime();
        let handle = { value: 0 };
        let loop = () => {
          handle.value = requestAnimationFrame(loop);
          let current = new Date().getTime();
          let delta = current - start;
          if (delta >= delay) {
            fn();
            start = new Date().getTime();
          }
        };
        handle.value = requestAnimationFrame(loop);
        return handle;
      }
      const sample = (arr) => arr[Math.floor(Math.random() * arr.length)]

      let bubbleSizes = [5, 10, 15, 20]
      let bubbleText = document.querySelector(".bubbling")
      let bubblePositions = Array.from(
          Array(bubbleText.offsetWidth).keys()
      )
      requestInterval(() => {
        let bubbleSize = sample(bubbleSizes)
        let bubblePosition = sample(bubblePositions)
        let bubble = document.createElement("div")
        bubble.className = "bubble"
        bubble.style.width = `${bubbleSize}px`
        bubble.style.height = `${bubbleSize}px`
        bubble.style.left = `${bubblePosition}px`
        bubbleText.append(bubble)
        let bubbleAnimation = bubble.animate(
            [{ bottom: "10px", opacity: 1 }, { bottom: "180%", opacity: 0.6 }],
            {
              fill: "forwards",
              duration: 5000
            }
        )
        bubbleAnimation.onfinish = () => bubble.remove()
      }, 300)
    }
  },
  mounted() {
    this.bubble()
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Bubblegum+Sans&display=swap');
.err-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .bubbling {
    position: relative;
    z-index: 2;
    display: inline-block;
    font-size: 16px;
    font-family: 'Bubblegum Sans', cursive;
    font-weight: bold;
    text-transform: uppercase;
  }
  .bubble {
    position: absolute;
    background: #409EFF;
    border-radius: 100%;
  }
}
</style>


