import request from '@/utils/request'
export default {
    //查询当前用户的待办事项
    getTodoList() {
        return request({
            url: '/todo/list',
            method: 'get'
        })
    },

    //分页查询当前用户的待办事项
    getTodoListPage(page, size) {
        return request({
            url: `/todo/list/${page}/${size}`,
            method: 'get',
        })
    },

    //查询当前用户今天的待办事项
    getTodayTodoList() {
        return request({
            url: '/todo/today',
            method: 'get'
        })
    },

    //分页查询当前用户今天的待办事项
    getTodoListTodayPage(page, size) {
        return request({
            url: `/todo/today/${page}/${size}`,
            method: 'get',
        })
    },


    //查询当前用户最近七天的待办事项
    getRecentTodoList() {
        return request({
            url: '/todo/recent',
            method: 'get'
        })
    },

    //分页查询当前用户最近七天的待办事项
    getTodoListRecentPage(page, size) {
        return request({
            url: `/todo/recent/${page}/${size}`,
            method: 'get',
        })
    },



    //修改待办事项状态
    updateTodoStatus(id,status) {
        return request({
            url: '/todo/updateStatus',
            method: 'put',
            params: {
                id,
                status
            }
        })
    },

    //全选/全不选
    updateTodoStatusAll(status) {
        return request({
            url: '/todo/updateAllStatus',
            method: 'put',
            params: {
                status
            }
        })
    },


    //删除待办事项
    deleteTodoById(id) {
        return request({
            url: '/todo/delete/' + id,
            method: 'delete',
        })
    },

    //删除所有已完成的待办事项
    deleteAllCompleted() {
        return request({
            url: '/todo/deleteAllCompleted',
            method: 'delete',
        })
    },

    //修改待办事项优先级
    updateTodoPriority(id,priority) {
        return request({
            url: '/todo/updatePriority',
            method: 'put',
            params: {
                id,
                priority
            }
        })
    },


    //修改待办事项截止时间
    updateTodoDeadline(id,deadline) {
        return request({
            url: '/todo/updateDeadline',
            method: 'put',
            params: {
                id,
                deadline
            }
        })
    },

    //修改待办事项内容
    updateTodoContent(id,content) {
        return request({
            url: '/todo/updateContent',
            method: 'put',
            params: {
                id,
                content
            }
        })
    },

    //新增待办事项
    addTodoItem(todo) {
        return request({
            url: '/todo/add',
            method: 'post',
            data: todo
        })
    },
}
