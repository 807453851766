import request from '@/utils/request'

export default {
    //获取会话列表
    getSessions() {
        return request({
            url: '/session/list',
            method: 'get'
        })
    },

    //创建会话
    createSession(acceptId) {
        return request({
            url: '/session/create',
            method: 'post',
            params: {
                acceptId
            }
        })
    },

    //隐藏会话
    hideSession(acceptId) {
        return request({
            url: '/session/hide',
            method: 'put',
            params: {
                acceptId
            }
        })
    },

    //显示会话
    showSession(acceptId) {
        return request({
            url: '/session/show',
            method: 'put',
            params: {
                acceptId
            }
        })
    },

        //删除会话
    deleteSession(acceptId) {
        return request({
            url: '/session/delete',
            method: 'delete',
            params: {
                acceptId
            }
        })
    },

    //置顶会话
    topSession(acceptId) {
        return request({
            url: '/session/top',
            method: 'put',
            params: {
                acceptId
            }
        })
    },

    //取消置顶会话
    untopSession(acceptId) {
        return request({
            url: '/session/untop',
            method: 'put',
            params: {
                acceptId
            }
        })
    },

    //修改会话最后一条消息
    updateLastMessage(acceptId, lastMess) {
        return request({
            url: '/session/updateLastMess',
            method: 'put',
            params: {
                acceptId,
                lastMess
            }
        })
    },


}
