// // 该文件用于创建Vuex中最为核心的store
// import { createStore } from 'vuex';
//
// // 准备actions---用于响应组件中的动作
// const actions = {}
//
// // 准备mutations---用于操作数据（state）
// const mutations = {}
//
// // 准备state---用于存储数据
// const state = {}
//
// // 创建store
// const store = createStore({
//     actions,
//     mutations,
//     state,
// });
//
// // 暴露store
// export default store;
import Vue from 'vue'
import VueX from 'vuex'
import user from '@/store/modules/user'
Vue.use(VueX)

export default new VueX.Store({
    modules: {
        user,
    }
})
