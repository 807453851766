<template>
  <div class="self-follow" :theme="theme">
    <div :class="{darkFollow:theme=='dark'}">
      <el-card shadow="never" style="margin-bottom: 10px" v-for="(follow,index) in followList" :key="index"
               :body-style="theme=='dark'?darkCard:''"
               :class="{darkCard:theme=='dark'}">
        <div style="display: flex;align-items: center;justify-content: space-between">
          <div style="display: flex;align-items: center;">
            <el-avatar :size="60" :src="follow.avatar" v-if="follow.avatar"></el-avatar>
            <div style="margin-left: 20px;">
              <div style=""  :theme="theme" class="hoverSpan" @click="showUser(follow.id)">{{ follow.nickname }}
              </div>
            </div>
            <div>
              <el-tag class="author-Title" type="info" size="mini" v-if="follow.userLabel=='Lv1 关关雎鸠'"
              >{{ follow.userLabel }}
              </el-tag>

              <el-tag class="author-Title" type="danger" size="mini" v-else-if="follow.userLabel=='Lv7 无人能敌'"
              >{{ follow.userLabel }}
              </el-tag>

              <el-tag class="author-Title" type="success" size="mini" v-else
              >{{ follow.userLabel }}
              </el-tag>

              <el-tag class="author-Title" type="danger" size="mini" v-if="follow.account=='admin'">
                站长
              </el-tag>
            </div>
          </div>

          <div>
            <!--私信按钮-->
            <el-button plain size="mini" style="float: right;" @click="toChat(follow.id)">
              <i class="el-icon-chat-dot-square"></i>私信
            </el-button>
          </div>
        </div>

      </el-card>
      <el-card shadow="never" v-if="isLoading" :body-style="theme=='dark'?darkCard:''"
               :class="{darkCard:theme=='dark'}">
        <el-skeleton :rows="4" animated/>
      </el-card>
      <el-card shadow="never" v-if="articleListIsEmpty" :body-style="theme=='dark'?darkCard:''"
               :class="{darkCard:theme=='dark'}">
        <el-empty description="暂无数据"></el-empty>
      </el-card>
      <el-pagination
          :pager-count="pageCount"
          :small="true"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          :layout="layout"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import followApi from '@/api/follow'
import sessionApi from "@/api/session";

export default {
  data() {
    return {
      darkCard: {
        backgroundColor: "rgb(17,17,17)",
      },
      theme: 'light',
      articleListIsEmpty: false,
      isLoading: false,
      followList: [],
      pageCount: 9,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      currentPage: 1,
      pageSize: 10,
    }
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  created() {
    //获取关注列表
    this.getFollowList()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 700) {
        this.pageCount = 5;
        this.layout = 'total,prev, pager, next';
      }else {
        this.pageCount = 9;
        this.layout = 'total, sizes, prev, pager, next, jumper';
      }
    },
    toChat(id){
      //保存对话id到vuex
      //创建会话
      sessionApi.createSession(id).then(res=>{
        if(res.data.success){
          this.$store.state.user.acceptId = id;
          this.$router.push({
            path: `/mess/index`,
          });
        }else{
          this.$message.warning('创建会话失败');
        }
      })
    },
    showUser(id){
      //跳转到该用户信息页
      this.$router.push(`/user/${id}/info`)
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getFollowList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getFollowList();
    },
    //获取关注列表
    getFollowList() {
      this.isLoading = true;
      followApi.getFollowList(this.currentPage, this.pageSize).then(res => {

        this.followList = res.data.data.list;
        this.total = res.data.data.total
        if (this.followList.length == 0) {
          this.articleListIsEmpty = true;
        } else {
          this.articleListIsEmpty = false;
        }
        this.isLoading = false;
      })
    }
  }
}
</script>
<style lang="scss">
.darkFollow {
  .el-pagination__jump {
    color: white;
  }

  .el-pagination__total {
    color: white;
  }
}
</style>
<style scoped>
.author-Title {
  margin-left: 8px;
}



.hoverSpan[theme='dark'] {
  font-size: 16px;
  color: #fff;
  cursor: pointer
}

.hoverSpan[theme='light'] {
  font-size: 16px;
  color: #303133;
  cursor: pointer
}

.hoverSpan:hover {
  color: #1589fa;
}

.darkCard >>> .el-card__header {
  border-bottom: rgb(62, 62, 62) solid 1px !important;
}

.self-follow[theme='light'] {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
}

.self-follow[theme='dark'] {
  width: 100%;
  height: 100%;
  background-color: rgb(17, 17, 17);
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
}


</style>
