import request from '@/utils/request'
export default {
    //获取所有文章
    getArticleList(pageNo,pagesize) {
        return request({
            url: `/article/getAllArticle`,
            method: 'get',
            params: {
                pageNo: pageNo,
                pageSize: pagesize
            }
        })
    },

    listMysqlArticle(queryInfo,pageNo,pagesize) {
        return request({
            url: `/article/listMysqlArticle`,
            method: 'post',
            params: {
                pageNo: pageNo,
                pageSize: pagesize
            },
            data: queryInfo
        })
    },

    //查询文章（id）
    getArticleById(id) {
        return request({
            url: `/article/getArticleById/`,
            method: 'get',
            params: {
                id: id
            }
        })
    },

    //发布帖子
    addArticle(article) {
        return request({
            url: `/article/createArticle`,
            method: 'post',
            data: article
        })
    },

    //删除帖子
    deleteArticle(id) {
        return request({
            url: `/article/deleteArticle/` + id,
            method: 'delete'
        })
    },

    //修改帖子
    updateArticle(article) {
        return request({
            url: `/article/updateArticle`,
            method: 'put',
            data: article
        })
    },


    //获取全部标签
    getAllTag() {
        return request({
            url: `/tag/list`,
            method: 'get',
        })
    },

    //获取不同分类的文章
    getArticleByCategory(categoryId,pageNo,pagesize) {
        return request({
            url: `/article/getArticleByCategory/`,
            method: 'get',
            params: {
                categoryId: categoryId,
                pageNo: pageNo,
                pageSize: pagesize
            }
        })
    },


    //关键词搜索
    searchArticle(queryInfo,pageNo,pagesize) {
        return request({
            url: `/article/listArticle/`,
            method: 'post',
            params: {
                pageNo: pageNo,
                pageSize: pagesize
            },
            data: queryInfo
        })
    },
    addViewCount(id) {
        return request({
            url: `/article/addArticleViewCount/`,
            method: 'put',
            params: {
                id: id
            }
        })
    },

}
