import request from '@/utils/request'

// 查询文章标签关联管理列表
export function listArticletag(query) {
  return request({
    url: `/system/articletag/list/${query.pageNum}/${query.pageSize}`,
    method: 'post',
    data:{
      articleId: query.articleId,
      tagId: query.tagId,
      isDeleted: query.isDeleted,
    },
  })
}

// 查询文章标签关联管理详细
export function getArticletag(id) {
  return request({
    url: '/system/articletag/' + id,
    method: 'get'
  })
}

// 新增文章标签关联管理
export function addArticletag(data) {
  return request({
    url: '/system/articletag',
    method: 'post',
    data: data
  })
}

// 修改文章标签关联管理
export function updateArticletag(data) {
  return request({
    url: '/system/articletag',
    method: 'put',
    data: data
  })
}

// 删除文章标签关联管理
export function delArticletag(id) {
  return request({
    url: '/system/articletag/' + id,
    method: 'delete'
  })
}
