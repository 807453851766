import request from "@/utils/request";


export default {
    likeOrUnLike(likeInfo) {
        return request({
            url: `/like`,
            method: 'post',
            data: likeInfo
        })

    }
}
