<template>
  <div class="publish-page" style="overflow-y: scroll;height:calc(100vh - 100px)">
    <div>
      <el-card class="box-card" :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
        <el-form :label-position="labelPosition" label-width="80px" :model="articleInfo" ref="articleInfoForm">
          <el-form-item label="分类:"
                        prop="categoryId"
                        :rules="[{ required: true, message: '请选择分类', trigger: 'blur' }]"
          >
            <el-radio-group v-model="articleInfo.categoryId" size="medium" :class="{darkRadio:theme=='dark'}">
              <el-radio-button :label="1">文章</el-radio-button>
              <el-radio-button :label="2">资源</el-radio-button>
              <el-radio-button :label="3">简历</el-radio-button>
              <el-radio-button :label="4">面经</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标题:"
                        prop="title"
                        :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]"
          >
            <el-input v-model="articleInfo.title" placeholder="请输入帖子标题，最多60字"
                      :class="{darkInput:theme=='dark'}"></el-input>
          </el-form-item>
          <el-form-item label="描述:"
                        prop="description"
                        :rules="[{ required: true, message: '请输入描述', trigger: 'blur' }]"
          >
            <el-input maxlength="200" v-model="articleInfo.description"
                      placeholder="请简单介绍帖子的内容，最多200字" :class="{darkInput:theme=='dark'}"></el-input>
          </el-form-item>
          <el-form-item label="内容:"
                        prop="content"
                        :rules="[{ required: true, message: '请输入内容', trigger: 'blur' }]"
          >
            <!--显示富文本编辑器-->
            <div class="myeditor">
              <!--              <mavon-editor-->
              <!--                  :class="{'full-screen':fullScreenStyle}"-->
              <!--                  ref="md"-->
              <!--                  style="width: 100%; height: 100%;min-height: 400px"-->
              <!--                  placeholder="点击右上角全屏，体验更佳"-->
              <!--                  v-model="articleInfo.content"-->
              <!--                  :toolbars="toolbars"-->
              <!--                  @change="change"-->
              <!--                  @imgAdd="imgAdd"-->
              <!--                  language="zh"-->
              <!--                  navigation-->
              <!--                  ishljs-->
              <!--              />-->

              <!--              <v-md-editor :disabled-menus="[]"-->
              <!--                           @upload-image="handleUploadImage" placeholder="点击右上角全屏，体验更佳" :toc-nav-position-right="true" v-model="articleInfo.content" height="400px"></v-md-editor>-->

              <Editor
                  class="editos"
                  :class="{darkEditor:theme=='dark'}"
                  :plugins="plugins"
                  :locale="zhHans"
                  @change="handleChange"
                  :uploadImages="uploadImage"
                  :value="articleInfo.content"
              />
            </div>
          </el-form-item>
          <el-form-item label="标签:"
                        prop="tagIds"
                        :rules="[{ required: true, message: '请选择标签', trigger: 'blur' }]"
          >
            <!--选择标签-->
            <el-select clearable v-model="articleInfo.tagIds" multiple placeholder="请选择" :class="{darkInput:theme=='dark'}"
                       :popper-append-to-body="false"  :transfer="true">
              <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.tagName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="封面:">
            <!--图片上传-->
            <el-upload
                class="avatar-uploader"
                action="https://47.120.61.197/oss/uploadOssFile"
                :show-file-list="false"
                :headers="{token: token}"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <el-image fit="contain" v-if="articleInfo.cover" :src="articleInfo.cover" class="avatar"></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="简历:" v-if="articleInfo.categoryId==3">
            <!--简历上传-->
            <el-upload
                class="upload-demo"
                action="https://47.120.61.197/oss/uploadOssFile"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :limit="1"
                :on-success="handlePdfSuccess"
                :before-upload="beforePdfUpload"
                :on-exceed="handleExceed"
                :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传pdf文件</div>
            </el-upload>

          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="publishArticle">提交</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <div class="copyright-cmp" :theme="theme">
      <p class="copyright-item">Copyright©2024  | 粤ICP备号xxxxxx</p>
      <p class="copyright-item">作者：xiaolin</p>
      <p class="copyright-item">邮箱：xiaolin_zii@163.com</p>
    </div>
  </div>
</template>
<script>
// 这里就是引入所有的扩展的插件
import 'bytemd/dist/index.css'  // 导入编辑器样式
import zhHans from 'bytemd/locales/zh_Hans.json'
import {Editor} from '@bytemd/vue'
import gfm from '@bytemd/plugin-gfm'
import highlightssr from '@bytemd/plugin-highlight-ssr'
import highlight from '@bytemd/plugin-highlight'
import breaks from '@bytemd/plugin-breaks'
import footnotes from '@bytemd/plugin-footnotes'
import frontmatter from '@bytemd/plugin-frontmatter'
import gemoji from '@bytemd/plugin-gemoji'
import math from '@bytemd/plugin-math'
import mermaid from '@bytemd/plugin-mermaid'
import gfm_zhHans from '@bytemd/plugin-gfm/locales/zh_Hans.json'
import math_zhHans from '@bytemd/plugin-math/locales/zh_Hans.json'
import mermaid_zhHans from '@bytemd/plugin-mermaid/locales/zh_Hans.json'
// import mediumZoom from '@bytemd/plugin-medium-zoom' //图片放大预览


import 'highlight.js/styles/vs.css'
import 'juejin-markdown-themes/dist/juejin.min.css'  // 其实就是需要这个css文件

const gfmlocale = {
  locale: gfm_zhHans,
}

const mathlocale = {
  locale: math_zhHans,
}

const mermaidlocale = {
  locale: mermaid_zhHans,
}

import ossApi from "@/api/oss";
import articleApi from "@/api/article";

const plugins = [
  // 将所有的扩展功能放入插件数组中，然后就可以生效了
  gfm(gfmlocale),
  highlight(),
  highlightssr(),
  breaks(),
  frontmatter(),
  footnotes(),
  gemoji(),
  math(mathlocale),
  mermaid(mermaidlocale),
  // mediumZoom()
]

export default {
  name: 'publishPage',
  components: {Editor}, // 组件注册
  data() {
    return {
      darkCard: {
        backgroundColor: "rgb(17,17,17)",
      },
      theme: 'light',
      token: this.$store.state.user.token,
      fileList: [],
      plugins,// 插件
      zhHans, // 简体中文
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: false, // 上一步
        redo: false, // 下一步
        trash: false, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: false, // 左对齐
        aligncenter: false, // 居中
        alignright: false, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true, // 预览
      },
      options: [],
      fullScreenStyle: false,
      labelPosition: 'left',
      articleInfo: {
        id: '',
        title: '',
        content: '',
        description: '',
        categoryId: 1,
        tagIds: [],
        cover: '',
        attachmentAddress: '',
      }
    }
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  created() {
    //判断路由是否有id，有则是编辑文章
    console.log(this.$route.query.articleId)
    if (this.$route.query.articleId) {
      this.articleInfo.id = this.$route.query.articleId;
      articleApi.getArticleById(this.articleInfo.id).then(res => {
        this.articleInfo = res.data.data.articleVO;
        this.fileList.push({name:  this.articleInfo.attachmentAddress, url:  this.articleInfo.attachmentAddress});
        console.log(this.articleInfo.categoryId)
      })
    }
    this.getTags();
  },
  methods: {
    getTags() {
      articleApi.getAllTag().then(res => {
        this.options = res.data.data.list;
      })
    },
    handlePreview(file) {
      console.log(file);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },

    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 获取书写文档内容
    handleChange(v) {
      console.warn(v)
      this.articleInfo.content = v
    },

    publishArticle() {
      this.$refs["articleInfoForm"].validate((valid) => {
        if (valid) {
          //判断是更新还是新增
          if (this.articleInfo.id) {
            articleApi.updateArticle(this.articleInfo).then(res => {
              //发布成功后跳转到首页
              this.$message({
                showClose: true,
                message: '更新成功!',
                type: 'success'
              });
              this.$router.push({path: '/self/post'});
            })
          } else {
            articleApi.addArticle(this.articleInfo).then(res => {
              //发布成功后跳转到首页
              this.$message({
                showClose: true,
                message: '发布成功!',
                type: 'success'
              });
              this.$router.push({path: '/square/index'});
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })

    },
    // 上传图片 点击触发上传图片事件，大家获取文件把图片上传服务器然后返回url既可
    async uploadImage(files) {

      if (files.length > 3) {
        this.$message.error('一次最多只能上传3张图片');
        //取消上传
        return [];
      }

      this.$message({
        showClose: true,
        message: '正在上传中...',
        type: 'info'
      });

      console.log('files', files)
      let result = []
      for (let i = 0; i < files.length; i++) {
        let formData = new FormData()
        let fileName = new Date().getTime() + '.webp';
        formData.append('file', files[i], fileName)
        let res = await ossApi.uploadFileToOss(formData);
        result.push({
          title: files[i].name,
          url: res.data.data.url,
        })
      }
      this.$message({
        showClose: true,
        message: '上传成功!',
        type: 'success'
      });
      return result
    },
    fullScreen() {
      console.log('全屏');
      this.fullScreenStyle = true;
    },
    handleUploadImage(event, insertImage, files) {
      event.preventDefault();
      // 拿到 files 之后上传到文件服务器，然后向编辑框中插入对应的内容
      // console.log(files);
      var formData = new FormData()
      let fileName = new Date().getTime() + '.webp';
      formData.append('file', files[0], fileName)
      ossApi.uploadFileToOss(formData).then(res => {
        insertImage({
          url: res.data.data.url,
          desc: fileName,
          // width: 'auto',
          // height: 'auto',
        });
      })

    },
    // 添加上传图片事件
    imgAdd(pos, file) {
      console.log(pos, file)
      // 第一步.将图片上传到服务器.
      var formData = new FormData()
      let fileName = new Date().getTime() + '.webp';
      formData.append('file', file, fileName)
      ossApi.uploadFileToOss(formData).then(res => {
        // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
        /**
         * $vm 指为mavonEditor实例，可以通过如下两种方式获取
         * 1. 通过引入对象获取: `import {mavonEditor} from ...` 等方式引入后，`$vm`为`mavonEditor`
         * 2. 通过$refs获取: html声明ref : `<mavon-editor ref=md ></mavon-editor>，`$vm`为 `this.$refs.md`
         */
        this.$refs.md.$img2Url(pos, res.data.data.url);
        console.log(res.data.data.url)
      })


    },
    // 所有操作都会被解析重新渲染
    change(value, render) {
      // render 为 markdown 解析后的结果[html]
      this.articleInfo.htmlContent = render;
      // console.log(this.articleInfo.htmlContent)
      // console.log(this.articleInfo.content);
    }
    ,
    handleResize() {
      //监听浏览器窗口大小，小于768px时，labelPosition改为top
      if (window.innerWidth < 768) {
        this.labelPosition = 'top';
      } else {
        this.labelPosition = 'left';
      }
    }
    ,
    handleAvatarSuccess(res, file) {
      this.articleInfo.cover = res.data.url;
      console.log(file)
    },
    handlePdfSuccess(res, file) {
      this.fileList.push({name: file.name, url: res.data.url});
      this.articleInfo.attachmentAddress = res.data.url;
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt5M = file.size / 1024 / 1024 < 5;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      // return isJPG && isLt2M;
      return isLt5M;
    },
    beforePdfUpload(file) {
      const isPdf = file.type === 'application/pdf';
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isPdf) {
        this.$message.error('上传简历只能是 PDF 格式!');
      }
      if (!isLt10M) {
        this.$message.error('上传简历大小不能超过 10MB!');
      }
      return isPdf && isLt10M;
    },
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  computed: {},
}
</script>

<style lang="scss">
.editos {
  .bytemd {
    // 改变编辑器默认高度，不需要的可以不配置
  }

  .bytemd-fullscreen {
    z-index: 1502 !important;
  }
}

.darkEditor {
  .bytemd {
    background-color: rgb(17, 17, 17);
    color: white;
    border: rgb(62, 62, 62) solid 1px !important;

    .markdown-body {
      color: white;
    }
  }
  .CodeMirror{
    background-color: rgb(17, 17, 17);
    color: white;
    .CodeMirror-lines{
      caret-color: white;
    }
  }
  .cm-link{
    color: #1589fa;
  }
  .bytemd-toolbar {
    background-color: rgb(17, 17, 17);
    color: white;
    border-bottom: rgb(62, 62, 62) solid 1px !important;
  }
  .bytemd-preview{
    border-left: rgb(62, 62, 62) solid 1px !important;
  }
  .bytemd-status {
    border-top: rgb(62, 62, 62) solid 1px !important;
  }
}

.el-form-item__content {
  line-height: normal !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF !important;
}


.darkCard{
  .el-select-dropdown{
    background-color: rgb(17, 17, 17);
    color: white;
    border: rgb(62, 62, 62) solid 1px !important;
    .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
      background-color: #1589fa !important;
    }
    .el-select-dropdown__item{
      color: white;
    }
    .popper__arrow{
      border-bottom-color: rgb(62, 62, 62);
    }
    .popper__arrow::after {
      border-bottom-color:rgb(17,17,17) !important;
      border-top-color: rgb(17,17,17) !important;
    }
  }
}
</style>
<style scoped>
.copyright-cmp[theme='light']{
  text-align: center;
  color: rgba(0, 0, 0, .45);
  font-size: 14px;
}
.copyright-cmp[theme='dark']{
  text-align: center;
  color: rgba(255,255,255,.45);
  font-size: 14px;
}
.el-card{
  overflow: visible !important;
}
.darkRadio >>> .el-radio-button__inner {
  background-color: black;
  border: rgb(62, 62, 62) solid 1px !important;
  color: white;
}

.darkRadio >>> .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #409EFF;
  border-color: #409EFF;
  box-shadow: -1px 0 0 0 #409EFF;
}

.darkInput /deep/ .el-input__inner {
  background-color: black;
  border: rgb(62, 62, 62) solid 1px !important;
}

.darkCard {
  border: rgb(62, 62, 62) solid 1px !important;
  color: #ffffff;
  background-color: rgb(17, 17, 17);
}

.darkCard >>> .el-form-item__label {
  color: white;
}

.darkCard >>> .el-card__header {
  border-bottom: rgb(62, 62, 62) solid 1px !important;
}

.publish-page {
}


.bytemd-toolbar-icon.bytemd-tippy.bytemd-tippy-right:last-child {
  display: none !important;
}


.full-screen {
  z-index: 99999 !important;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}


.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
</style>
