<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
import {closeWebSocket, createWebSocket} from '@/utils/websocket.js'
export default {
  name: 'App',
  data() {
    return {

    }
  },
  created() {
    // 检查当前路由是否是登录页
    if (this.$route.path !== '/login') {
      createWebSocket()
    }
  },
  mounted() {

  },
  beforeDestroy() {
    closeWebSocket()
  },
  watch: {

  }
}
</script>

<style>
p{
  margin: 0;
}
html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
}

[v-cloak] {
  display: none;
}

</style>
