import request from '@/utils/request'

// 查询用户评论管理列表
export function listComment(query) {
    return request({
        url: `/system/comment/list/${query.pageNum}/${query.pageSize}`,
        method: 'post',
        data: {
            articleId: query.articleId,
            parentId: query.parentId,
            userId: query.userId,
            content: query.content,
            isDeleted: query.isDeleted,
        }
    })
}

// 查询用户评论管理详细
export function getComment(id) {
    return request({
        url: '/system/comment/' + id,
        method: 'get'
    })
}

// 新增用户评论管理
export function addComment(data) {
    return request({
        url: '/system/comment',
        method: 'post',
        data: data
    })
}

// 修改用户评论管理
export function updateComment(data) {
    return request({
        url: '/system/comment',
        method: 'put',
        data: data
    })
}

// 删除用户评论管理
export function delComment(id) {
    return request({
        url: '/system/comment/' + id,
        method: 'delete'
    })
}
