import request from '@/utils/request'
export default {
    //收藏/取消收藏文章
    collectOrUnCollect(articleId) {
        return request({
            url: `/favorites/${articleId}`,
            method: 'post'
        })
    },

    //获取收藏文章列表
    getCollectList(queryInfo, pageNo, pageSize) {
        return request({
            url: `/favorites/list/${pageNo}/${pageSize}`,
            method: 'post',
            data: queryInfo
        })
    }
}
