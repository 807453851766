import request from '@/utils/request'
export default {
    //gpt
    chatToGpt(params){
        return request({
            url: `/chat/gpt/stream`,
            method: 'post',
            data: params,
            responseType: 'stream'
        })
    },

    //获取全部ai
    getAllAi(){
        return request({
            url: `/ai/list`,
            method: 'get',
        })
    },
}
