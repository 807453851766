<template>
  <div class="self-comment" :theme="theme">
    <div :class="{darkPost:theme=='dark'}">
      <template v-if="commentList.length">
        <el-card shadow="never" style="margin-bottom: 10px" v-for="(comment,index) in commentList" :key="index"
                 class="watermarkContent"  :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
          <!--文章标题-->
          <el-row>
            <el-col :span="24">
              <el-link :underline="false" :href="'/square/post/'+comment.articleVO.id" target="_blank">
                <span style="font-weight: bold;font-size: 16px;">{{comment.articleVO.title}}</span>
              </el-link>
            </el-col>
          </el-row>
          <!--评论内容-->
          <el-row>
            <el-col :span="24">
              <p v-html="comment.content" style="color: #6a737d;font-size: 14px"></p>
            </el-col>
          </el-row>
          <!--评论时间-->
          <el-row>
            <el-col :span="24">
              <span style="font-size: 12px;color: #999;">{{comment.createTime}}</span>
            </el-col>
          </el-row>


        </el-card>
      </template>
      <el-card shadow="never" v-if="isLoading" :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
        <el-skeleton :rows="4" animated/>
      </el-card>
      <el-card shadow="never" v-if="commentListIsEmpty" :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
        <el-empty description="暂无数据"></el-empty>
      </el-card>
      <el-pagination
          v-if="commentList.length"
          :pager-count="pageCount"
          :small="true"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          :layout="layout"
          :total="total">
      </el-pagination>
    </div>
  </div>

</template>
<script>
import userApi from '@/api/user'
export default {
  data() {
    return {
      pageCount: 9,
      layout:'total, sizes, prev, pager, next, jumper',
      darkCard:{
        backgroundColor:"rgb(17,17,17)",
      },
      theme:'light',
      isLoading: false,
      commentList: [],
      commentListIsEmpty: false,
      currentPage: 1,
      pageSize: 10,
      total: 0
    }
  },
  created() {
    this.getUserCommentList()
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 700) {
        this.pageCount = 5;
        this.layout = 'total,prev, pager, next';
      }else {
        this.pageCount = 9;
        this.layout = 'total, sizes, prev, pager, next, jumper';
      }
    },
    handleCurrentChange(val){
      this.currentPage = val;
      this.getUserCommentList();
    },
    handleSizeChange(val){
      this.pageSize = val;
      this.getUserCommentList();
    },
    getUserCommentList() {
      this.isLoading = true;
      userApi.getUserCommentList(this.$route.params.id,this.currentPage,this.pageSize).then(res => {
        this.commentList = res.data.data.commentList;
        this.total = res.data.data.total;
        this.isLoading = false;
        this.commentListIsEmpty = this.commentList.length === 0;
      })
    }
  },
}
</script>
<style scoped>



.self-comment[theme='light'] {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.self-comment[theme='dark'] {
  width: 100%;
  background-color: rgb(17,17,17);
  padding: 20px;
  box-sizing: border-box;
}
</style>
