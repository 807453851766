<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="用户账号" prop="account">
        <el-input
          v-model="queryParams.account"
          placeholder="请输入用户账号"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="昵称" prop="nickname">
        <el-input
          v-model="queryParams.nickname"
          placeholder="请输入昵称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select v-model="queryParams.sex" placeholder="请选择性别" clearable>
          <el-option
            v-for="dict in sys_user_sex"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="角色" prop="role">
        <el-select v-model="queryParams.role" placeholder="请选择角色" clearable>
          <el-option
              v-for="dict in sys_user_role"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="手机" prop="phone">
        <el-input
          v-model="queryParams.phone"
          placeholder="请输入手机"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="学校" prop="school">
        <el-input
          v-model="queryParams.school"
          placeholder="请输入学校"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="专业" prop="major">
        <el-input
          v-model="queryParams.major"
          placeholder="请输入专业"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="就业状态" prop="jobStatus">
        <el-select v-model="queryParams.jobStatus" placeholder="请选择就业状态" clearable>
          <el-option
            v-for="dict in sys_job_status"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="在线状态" prop="onlineStatus">
        <el-select v-model="queryParams.onlineStatus" placeholder="请选择在线状态" clearable>
          <el-option
              v-for="dict in sys_online_status"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="逻辑删除" prop="isDeleted">
        <el-select v-model="queryParams.isDeleted" placeholder="请选择逻辑删除" clearable>
          <el-option
            v-for="dict in sys_logic_deleted"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </el-col>

    </el-row>

    <el-table v-loading="loading" :data="frontuserList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键id" align="center" prop="id" />
      <el-table-column label="用户账号" align="center" prop="account" />
      <el-table-column label="角色" align="center" prop="role">
        <template slot-scope="scope">
          <el-tag>{{scope.row.role=='admin'?'管理员':scope.row.role=='common'?'普通用户':'未知'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="密码" align="center" prop="password" />
      <el-table-column label="昵称" align="center" prop="nickname" />
      <el-table-column label="头像url" align="center" prop="avatar" width="100">
        <template slot-scope="scope">
          <el-image :src="scope.row.avatar" :preview-src-list="[scope.row.avatar]" :width="50" :height="50"/>
        </template>
      </el-table-column>
      <el-table-column label="邮箱" align="center" prop="email" />
      <el-table-column label="个性签名" align="center" prop="signature" />
      <el-table-column label="性别" align="center" prop="sex">
        <template slot-scope="scope">
          <el-tag>{{scope.row.sex==0?'女':scope.row.sex==1?'男':'未知'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="手机" align="center" prop="phone" />
      <el-table-column label="地区" align="center" prop="place" />
      <el-table-column label="生日" align="center" prop="birthday" />
      <el-table-column label="学校" align="center" prop="school" />
      <el-table-column label="专业" align="center" prop="major" />
      <el-table-column label="就业状态" align="center" prop="jobStatus">
        <template slot-scope="scope">
          <el-tag>{{scope.row.jobStatus==0?'在校':scope.row.jobStatus==1?'求职中':scope.row.jobStatus==2?'实习中':'已工作'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="积分" align="center" prop="score" />
      <el-table-column label="学习方向" align="center" prop="direction" />
      <el-table-column label="是否禁用" align="center" prop="isDisabled" />
      <el-table-column label="逻辑删除" align="center" prop="isDeleted">
        <template slot-scope="scope">
          <el-tag>
            {{scope.row.isDeleted==0?'正常':'删除'}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建人" align="center" prop="createUser" />
      <el-table-column label="修改人" align="center" prop="updateUser" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        v-if="frontuserList.length"
        :small="true"
        :pager-count="pageCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParams.pageSize"
        :layout="layout"
        :total="total">
    </el-pagination>

    <!-- 添加或修改前台用户管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="用户账号" prop="account">
          <el-input v-model="form.account" placeholder="请输入用户账号" />
        </el-form-item>
        <el-form-item label="角色" prop="role">
          <el-select v-model="form.role" placeholder="请选择角色" clearable>
            <el-option
                v-for="dict in sys_user_role"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" placeholder="请输入密码" />
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入昵称" />
        </el-form-item>
        <el-form-item label="头像url" prop="avatar">
          <el-upload
              class="avatar-uploader"
              action="https://47.120.61.197/oss/uploadOssFile"
              :show-file-list="false"
              :headers="{token: token}"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <el-image fit="contain" v-if="form.avatar" :src="form.avatar" class="avatar"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form.email" placeholder="请输入邮箱" />
        </el-form-item>
        <el-form-item label="个性签名" prop="signature">
          <el-input v-model="form.signature" placeholder="请输入个性签名" />
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="form.sex" placeholder="请选择性别">
            <el-option
              v-for="dict in sys_user_sex"
              :key="dict.value"
              :label="dict.label"
              :value="parseInt(dict.value)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机" />
        </el-form-item>
        <el-form-item label="地区" prop="place">
          <el-input v-model="form.place" placeholder="请输入地区" />
        </el-form-item>
        <el-form-item label="生日" prop="birthday">
          <el-input v-model="form.birthday" placeholder="请输入生日" />
        </el-form-item>
        <el-form-item label="学校" prop="school">
          <el-input v-model="form.school" placeholder="请输入学校" />
        </el-form-item>
        <el-form-item label="专业" prop="major">
          <el-input v-model="form.major" placeholder="请输入专业" />
        </el-form-item>
        <el-form-item label="就业状态" prop="jobStatus">
          <el-select v-model="form.jobStatus" placeholder="请选择就业状态">
            <el-option
              v-for="dict in sys_job_status"
              :key="dict.value"
              :label="dict.label"
              :value="parseInt(dict.value)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="积分" prop="score">
          <el-input v-model="form.score" placeholder="请输入积分" />
        </el-form-item>
        <el-form-item label="学习方向" prop="direction">
          <el-input v-model="form.direction" placeholder="请输入学习方向" />
        </el-form-item>
        <el-form-item label="是否禁用" prop="isDisabled">
          <el-input v-model="form.isDisabled" placeholder="请输入是否禁用" />
        </el-form-item>
        <el-form-item label="逻辑删除" prop="isDeleted">
          <el-select v-model="form.isDeleted" placeholder="请选择逻辑删除">
            <el-option
              v-for="dict in sys_logic_deleted"
              :key="dict.value"
              :label="dict.label"
              :value="parseInt(dict.value)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建人" prop="createUser">
          <el-input v-model="form.createUser" placeholder="请输入创建人" />
        </el-form-item>
        <el-form-item label="修改人" prop="updateUser">
          <el-input v-model="form.updateUser" placeholder="请输入修改人" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listFrontuser, getFrontuser, delFrontuser, addFrontuser, updateFrontuser } from "@/api/admin/frontuser";

export default {
  data() {
    return {
      sys_user_role:[
        { label: "普通用户", value: 'common' },
        { label: "管理员", value: 'admin' },
      ],
      pageCount:9,
      token: this.$store.state.user.token,
      layout:'total, sizes, prev, pager, next, jumper',
      sys_logic_deleted:[
        { label: "正常", value: 0 },
        { label: "删除", value: 1 }
      ],
      sys_job_status:[
        { label: "在校", value: 0 },
        { label: "求职中", value: 1 },
        { label: "实习中", value: 2 },
        { label: "已工作", value: 3 }
      ],
      sys_online_status:[
        { label: "离线", value: 0 },
        { label: "在线", value: 1 },
      ],
      sys_user_sex:[
        { label: "男", value: 1 },
        { label: "女", value: 0 },
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 前台用户管理表格数据
      frontuserList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        account: null,
        nickname: null,
        sex: null,
        phone: null,
        school: null,
        major: null,
        jobStatus: null,
        onlineStatus:null,
        isDeleted: null,
        role:null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        account: [
          { required: true, message: "用户账号不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" }
        ],
      }
    };
  },
  created() {
    this.getList();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.form.avatar = res.data.url;
      console.log(file)
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt5M = file.size / 1024 / 1024 < 5;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      // return isJPG && isLt2M;
      return isLt5M;
    },
    handleResize() {
      if (window.innerWidth < 700) {
        this.pageCount = 5;
        this.layout = 'total,prev, pager, next';
      }else {
        this.pageCount = 9;
        this.layout = 'total, sizes, prev, pager, next, jumper';
      }
    },
    handleCurrentChange(val){
      this.queryParams.pageNum = val;
      this.getList();
    },
    handleSizeChange(val){
      this.queryParams.pageSize = val;
      this.getList();
    },
    /** 查询前台用户管理列表 */
    getList() {
      this.loading = true;
      listFrontuser(this.queryParams).then(response => {
        this.frontuserList = response.data.data.list;
        this.total = response.data.data.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        account: null,
        password: null,
        nickname: null,
        avatar: null,
        email: null,
        signature: null,
        sex: null,
        phone: null,
        place: null,
        birthday: null,
        school: null,
        major: null,
        jobStatus: null,
        score: null,
        role:null,
        direction: null,
        createTime: null,
        updateTime: null,
        isDisabled: null,
        isDeleted: null,
        createUser: null,
        updateUser: null
      };
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加前台用户管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getFrontuser(id).then(response => {
        this.form = response.data.data.user;
        this.open = true;
        this.title = "修改前台用户管理";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateFrontuser(this.form).then(response => {
              this.$message.success("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addFrontuser(this.form).then(response => {
              this.$message.success("添加成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除前台用户管理编号为"' + ids + '"的数据项？').then(function() {
        return delFrontuser(ids);
      }).then(() => {
        this.getList();
        this.$message.success("删除成功");
      }).catch(() => {});
    },

  }
};
</script>
