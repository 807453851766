import request from '@/utils/request'

// 查询点赞管理列表
export function listLike(query) {
  return request({
    url: `/system/like/list/${query.pageNum}/${query.pageSize}`,
    method: 'post',
    data: {
      targetId: query.targetId,
      type: query.type,
      userId: query.userId,
      isDeleted: query.isDeleted,
    }
  })
}

// 查询点赞管理详细
export function getLike(id) {
  return request({
    url: '/system/like/' + id,
    method: 'get'
  })
}

// 新增点赞管理
export function addLike(data) {
  return request({
    url: '/system/like',
    method: 'post',
    data: data
  })
}

// 修改点赞管理
export function updateLike(data) {
  return request({
    url: '/system/like',
    method: 'put',
    data: data
  })
}

// 删除点赞管理
export function delLike(id) {
  return request({
    url: '/system/like/' + id,
    method: 'delete'
  })
}
