<template>
  <div class="copyright-cmp">
    <p class="copyright-item">Copyright©2024  | 粤ICP备号xxxxxx</p>
    <p class="copyright-item">作者：xiaolin</p>
    <p class="copyright-item">邮箱：xiaolin_zii@163.com</p>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
  }
</script>

<style lang="scss" scoped>
  .copyright-cmp {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    .copyright-item {
      margin: 0 0 5px;
      color: #ffffff;
      font-size: 14px;
    }
  }
</style>
