<template>
  <div class="self-post" :theme="theme">
    <div style="padding: 12px 0 0 12px;border: 1px solid #EBEEF5;" :class="{darkBorder:theme=='dark'}">
      <el-form inline :model="queryInfo" :class="{darkForm:theme=='dark'}">
        <el-form-item label="标签:">
          <el-select v-model="queryInfo.tagIds" multiple placeholder="请选择" clearable style="width: 100%"
                     :class="{darkInput:theme=='dark'}"
                     :popper-append-to-body="false"
                     @change="queryByTag">
            <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.tagName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="queryArticleList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div :class="{darkPost:theme=='dark'}">
      <template v-if="articleList.length">
        <el-card shadow="never" style="margin-bottom: 10px" v-for="(article,index) in articleList" :key="index"
                 class="watermarkContent"  :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
          <div class="article-list-item">
            <!--个人信息-->
            <div class="article-list-item-title">
              <div class="author-message">
                <div>
                  <el-image
                      style="width: 50px; height: 50px;border-radius: 50%"
                      :src="article.user.avatar"
                      :preview-src-list="[article.user.avatar]"
                  >
                  </el-image>
                </div>

                <el-popover
                    :popper-class="theme=='dark'?'popperClass':''"
                    placement="bottom"
                    width="350"
                    trigger="hover"
                >
                  <div>
                    <div class="article-list-item-title">
                      <div class="author-message">
                        <el-image
                            style="width: 50px; height: 50px;border-radius: 50%"
                            :src="article.user.avatar"
                            :preview-src-list="[article.user.avatar]"
                        >
                        </el-image>
                        <div  class="author-message-right">
                          <span style="margin-left: 10px" class="articlAuthorName" @click="showUser(article.user.id)">{{ article.user.nickname }}</span>


                          <el-tag class="author-Title" type="info" size="mini" v-if="article.user.userLabel=='Lv1 关关雎鸠'"
                          >{{ article.user.userLabel }}
                          </el-tag>

                          <el-tag class="author-Title" type="danger" size="mini" v-else-if="article.user.userLabel=='Lv7 无人能敌'"
                          >{{ article.user.userLabel }}
                          </el-tag>

                          <el-tag class="author-Title" type="success" size="mini" v-else
                          >{{ article.user.userLabel }}
                          </el-tag>

                          <el-tag class="author-Title" type="danger" size="mini" v-if="article.user.account=='admin'">
                            站长
                          </el-tag>
                          <!--关注按钮-->
                          <el-button type="primary" size="mini" style="margin-left: 10px" v-if="article.user.id!=otherUserInfo.id&&article.user.followStatus==0" @click="followUser(article.user)">关注</el-button>
                          <el-button type="primary" size="mini" style="margin-left: 10px" v-if="article.user.id==otherUserInfo.id" @click="toMyHome">主页</el-button>
                          <el-button type="primary" plain size="mini" style="margin-left: 10px" v-if="article.user.id!=otherUserInfo.id&&article.user.followStatus==1" @click="followUser(article.user)">已关注</el-button>
                        </div>
                      </div>
                      <div style="margin-top: 10px">
                        <!--简介-->
                        <div>
                          简介：
                          <span>{{article.user.signature}}</span>
                        </div>
                        <el-divider></el-divider>
                        <!--粉丝关注积分-->
                        <div style="display: flex;width: 100%">
                          <span style="width: 33%;cursor: pointer;" class="hoverSpan" @click="toUserFan(article.user.id)">粉丝:<span style="margin-left: 5px" >{{ article.user.fansCount }}</span></span>
                          <span style="width: 33%;cursor: pointer"  class="hoverSpan"  @click="toUserFollow(article.user.id)">关注:<span style="margin-left: 5px">{{ article.user.followCount }}</span></span>
                          <span style="width: 33%;">积分:<span style="margin-left: 5px" >{{ article.user.score }}</span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div slot="reference" class="author-message-right">
                    <span style="margin-left: 10px" class="articlAuthorName" @click="showUser(article.user.id)">{{ article.user.nickname }}</span>


                    <el-tag class="author-Title" type="info" size="mini" v-if="article.user.userLabel=='Lv1 关关雎鸠'"
                    >{{ article.user.userLabel }}
                    </el-tag>

                    <el-tag class="author-Title" type="danger" size="mini" v-else-if="article.user.userLabel=='Lv7 无人能敌'"
                    >{{ article.user.userLabel }}
                    </el-tag>

                    <el-tag class="author-Title" type="success" size="mini" v-else
                    >{{ article.user.userLabel }}
                    </el-tag>

                    <el-tag class="author-Title" type="danger" size="mini" v-if="article.user.account=='admin'">
                      站长
                    </el-tag>
                  </div>
                </el-popover>


              </div>
            </div>
            <!--文章内容-->
            <div class="article-list-item-content-container" :theme="theme">
              <!--文章文字-->
              <div class="article-list-item-content" style="width: 100%">
                <a class="article-list-item-content-title"  @click="toArticleDetail(article.id)">
                  <h4 v-html="article.title"></h4>
                </a>
                <a class="article-list-item-content-text" @click="toArticleDetail(article.id)" :theme="theme">
                  <div v-html="article.description" style="width: 100%; word-wrap: break-word;white-space: normal;"></div>
                </a>
                <div style="margin-top: 20px;">
                  <el-tag type="info" size="mini" v-for="(tag,index) in article.tagNames" :key="index" style="margin-left: 8px;">{{tag}}</el-tag>
                </div>
                <div style="margin-top: 20px;">
                  <!--时间-->
                  <span class="articleTime" :theme="theme">{{ article.updateTime }}</span>
                  <span class="suxian" :theme="theme">|</span>
                  <!--点赞、收藏、评论-->
                  <!--点赞、收藏、评论-->
                  <button v-if="article.likeStatus==1" style="background: none;border: none" @click="toLikeArticle(article)"><i class="iconfont icon-dianzan_kuai tubiao"
                                                                                                                                :theme="theme"></i><span
                      style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{ article.likeCount }}</span>
                  </button>

                  <button v-else style="background: none;border: none" @click="toLikeArticle(article)"><i class="iconfont icon-dianzan2 tubiao"
                                                                                                          :theme="theme"></i><span
                      style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{ article.likeCount }}</span>
                  </button>

                  <span class="suxian" :theme="theme">|</span>
                  <button v-if="article.collectStatus==1" style="background: none;border: none" @click="toCollect(article)"><i class="iconfont icon-shoucang3 tubiao"
                                                                                                                               :theme="theme" ></i><span
                      style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{
                      article.collectCount
                    }}</span></button>

                  <button v-else style="background: none;border: none" @click="toCollect(article)"><i class="iconfont icon-shoucang8 tubiao"
                                                                                                      :theme="theme" ></i><span
                      style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{
                      article.collectCount
                    }}</span></button>
                  <span class="suxian" :theme="theme">|</span>
                  <button style="background: none;border: none"><i class="iconfont icon-31pinglun tubiao"
                                                                   :theme="theme"></i><span
                      style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{ article.commentCount }}</span></button>
                </div>
              </div>
              <!--文章图片-->
              <div class="article-list-item-img" v-if="article.cover">
                <el-image

                    style="width: 100px;"
                    :src="article.cover"
                    :preview-src-list="[article.cover]"
                >
                </el-image>
              </div>
            </div>
          </div>
        </el-card>
      </template>
      <el-card shadow="never" v-if="isLoading" :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
        <el-skeleton :rows="4" animated/>
      </el-card>
      <el-card shadow="never" v-if="articleListIsEmpty" :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
        <el-empty description="暂无数据"></el-empty>
      </el-card>
      <el-pagination
          v-if="articleList.length"
          :small="true"
          :pager-count="pageCount"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          :layout="layout"
          :total="total">
      </el-pagination>
    </div>
  </div>

</template>
<script>
import articleApi from "@/api/article";
import followApi from "@/api/follow";
import collectApi from "@/api/collect";
import likeApi from "@/api/like";
export default {
  data() {
    return {
      likeInfo:{
        type:1,
        targetId: ''
      },
      pageCount:9,
      layout:'total, sizes, prev, pager, next, jumper',
      darkCard:{
        backgroundColor:"rgb(17,17,17)",
      },
      theme:'light',
      queryInfo: {
        tagIds: [],
        userId: this.$route.params.id,
      },
      options: [],
      articleList: [],
      articleListIsEmpty: false,
      isLoading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  created() {
    this.getAllTags();
    this.initArticleList();
  },
  computed: {
    otherUserInfo() {
      console.log(this.$store.state.user.otherUserInfo)
      return this.$store.state.user.otherUserInfo;
    },
  },
  methods: {
    toLikeArticle(article) {
      this.likeInfo.targetId=article.id;
      likeApi.likeOrUnLike(this.likeInfo).then(res => {
        article.likeCount = res.data.data.num;
        article.likeStatus = res.data.data.status;
      }).catch(() => {
      });
    },
    toUserFan(id) {
      if (id == this.userInfo.id)
        this.$router.push(`/self/followed`)
      else
        this.$router.push(`/user/followed`)
    },
    toUserFollow(id) {
      if (id == this.userInfo.id)
        this.$router.push(`/self/follow`)
      else
        this.$router.push(`/user/follow`)
    },
    toCollect(article) {
      collectApi.collectOrUnCollect(article.id).then(res => {
        article.collectCount = res.data.data.num;
        article.collectStatus = res.data.data.status;
        console.log(article.collectStatus);
      }).catch(() => {
      });
    },
    followUser(user) {
      followApi.followOrUnFollow(user.id).then(res => {
        user.followStatus = res.data.data.status;
        user.fansCount = res.data.data.num;
      }).catch(() => {
      });
    },
    toMyHome() {
      this.$router.push(`/user/${this.userInfo.id}/info`)
    },
    showUser(id){
      //跳转到该用户信息页
      this.$router.push(`/user/${id}/info`)
    },
    handleResize() {
      if (window.innerWidth < 700) {
        this.pageCount = 5;
        this.layout = 'total,prev, pager, next';
      }else {
        this.pageCount = 9;
        this.layout = 'total, sizes, prev, pager, next, jumper';
      }
    },
    handleCurrentChange(val){
      this.currentPage = val;
      this.initArticleList();
    },
    handleSizeChange(val){
      this.pageSize = val;
      this.initArticleList();
    },
    updateMyArticle(id) {
      //跳转到发布文章页面，携带文章id
      this.$router.push('/square/publish?articleId='+id)
    },
    deleteMyArticle(id) {
      //删除文章
      //提示
      this.$confirm('此操作将永久删除该文章, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        articleApi.deleteArticle(id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          //数据有延迟，所以延迟1s刷新
          setTimeout(() => {
            this.initArticleList();
          }, 1000)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    initArticleList() {
      this.isLoading = true;
      articleApi.searchArticle(this.queryInfo, this.currentPage, this.pageSize).then(res => {
        this.articleList = res.data.data.articleVOList;
        this.total = res.data.data.total;
        if (this.articleList.length === 0) {
          this.articleListIsEmpty = true;
        } else {
          this.articleListIsEmpty = false;
        }
        this.isLoading = false;
      })
    },
    queryArticleList() {
      this.initArticleList();
    },
    handleClick(tab, event) {
      (tab, event);
    },
    toArticleDetail(id) {
      this.$router.push(`/square/post/${id}`);
    },
    getAllTags() {
      articleApi.getAllTag().then(response => {
        this.options = response.data.data.list;
      })
    },
    queryByTag(val) {
      //val是一个数组
      this.queryInfo.tagIds = val
    },
  },
}
</script>
<style lang="scss">
.el-popover{
  text-align: start;
}
.popperClass.el-popover{
  background-color: rgb(17, 17, 17) !important;
  color: #ffffff !important;
  .popper__arrow::after{
    border-bottom-color: rgb(17, 17, 17) !important;
  }
}

.darkPost{
  .el-pagination__jump{
    color: white;
  }
  .el-pagination__total{
    color: white;
  }
}
.darkForm{
  .el-select-dropdown{
    background-color: rgb(17, 17, 17);
    color: white;
    border: rgb(62, 62, 62) solid 1px !important;
    .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
      background-color: #1589fa !important;
    }
    .el-select-dropdown__item{
      color: white;
    }
    .popper__arrow{
      border-bottom-color: rgb(62, 62, 62);
    }
    .popper__arrow::after {
      border-bottom-color:rgb(17,17,17) !important;
      border-top-color: rgb(17,17,17) !important;
    }
  }
}
</style>
<style scoped>
.hoverSpan:hover{
  color: #1589fa;
}
.articlAuthorName{
  cursor: pointer;

}
.articlAuthorName:hover{
  color: #1589fa;

}
.tubiao[theme='light'] {
  color: rgba(3, 3, 3);
  font-size: 14px;
  cursor: pointer;
}

.tubiao[theme='dark'] {
  color: rgba(222, 222, 222);
  font-size: 14px;
  cursor: pointer;
}
.darkCount{
  color: #ffffff;
}
.suxian[theme='light']{
  margin: 0 6px;
  color: rgba(0,0,0,.1);

}
.suxian[theme='dark']{
  margin: 0 6px;
  color: rgba(255,255,255,.1);

}
.articleTime[theme='light']{
  color: rgba(0,0,0,.45);font-size: 14px
}
.articleTime[theme='dark']{
  color: rgba(255,255,255,.45);font-size: 14px
}

.darkCard{
  border: rgb(62, 62, 62) solid 1px !important;
  color: #ffffff;
  background-color: rgb(17,17,17);
}

.darkCard >>> .el-card__header {
  border-bottom: rgb(62, 62, 62) solid 1px !important;
}
.darkBorder{
  border: 1px solid rgb(62,62,62) !important;
}
.darkInput /deep/ .el-input__inner {
  background-color: black;
  border: rgb(62, 62, 62) solid 1px !important;
}
.darkForm{
  background-color:rgb(17,17,17);
  border-radius: 5px;
}
.self-post[theme='light'] {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.self-post[theme='dark'] {
  width: 100%;
  background-color: rgb(17,17,17);
  padding: 20px;
  box-sizing: border-box;
}


.article-list-item-content-title, .article-list-item-content-text {
  cursor: pointer;
}

.article-list-item-content-title:hover {
  color: #3a8ee6;
}

.author-message {
  display: flex;
}

.author-message-right {
  line-height: 35px;
}

.author-Title {
  margin-left: 8px;
}

.article-list-item-content-text[theme='light'] {
  color: rgba(0, 0, 0, .45);
  font-size: 15px;
  line-height: 1.75;
}

.article-list-item-content-text[theme='dark'] {
  color: #ffffff;
  font-size: 15px;
  line-height: 1.75;
}

.article-list-item-content-container[theme='light']{
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, .85);
  align-items: normal;
}

.article-list-item-content-container[theme='dark']{
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  color: #ffffff;
  align-items: normal;
}

.article-list-item-content {

}

.article-list-item-img {
  margin-left: 40px;
  max-width: 300px;
}

.article-tags {
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .index-left {
    margin-right: 0px;
  }

  .article-list-item-content-container{
    /*display: block;*/
    flex-direction: column;
  }
  .article-list-item-img{
    margin: 10px auto;
    float: top;
  }
  .index-right {
    display: none;
  }
}
</style>
