// websocket.js

import userApi from "@/api/user";

let socket = null
import store from '@/store'
import {Notification} from 'element-ui'
var lockReconnect = false;//避免重复连接
var tt;
const listeners = []
const wsUrl = 'wss://47.120.61.197/ws' // wss://47.120.61.197/ws ws://localhost:8888/ws
// const wsUrl = 'ws://localhost:8888/ws'
const createWebSocket = () => {
    try {
        const token = store.state.user.token
        socket = new WebSocket(wsUrl, [token])
        console.log('WebSocket 连接成功')

        socket.onopen = () => {
            heartCheck.start()
            addInitialListener()
            console.log('WebSocket 打开连接')
            //更新在线状态
            userApi.updateOnlineStatus(1).then(res=>{
                if (!res.data.success){
                    console.log(res);
                }
            })
        }

        socket.onmessage = (event) => {
            heartCheck.start()

            if(event.data=='ok'){//心跳消息不做处理
                return
            }

            // 触发事件监听器
            listeners.forEach(listener => listener(event.data))
        }

        socket.onerror = (error) => {
            console.error('WebSocket 连接发生错误:', error)
        }

        socket.onclose = () => {
            console.log('WebSocket 连接关闭')
            //更新在线状态
            userApi.updateOnlineStatus(0).then(res=>{
                if (!res.data.success){
                    console.log(res);
                }
            })
            reconnect();
        }
    } catch (error) {
        console.error('WebSocket连接发生错误:', error)
    }
}

function reconnect() {
    if(lockReconnect) {
        return;
    }
    lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    tt && clearTimeout(tt);
    tt = setTimeout(function () {
        console.log("执行断线重连...")
        createWebSocket();
        lockReconnect = false;
    }, 4000);
}


//根据消息标识做不同的处理
function messageHandle(message) {
    let msg = JSON.parse(message)

    if (msg.sendId==0) {
        //接收到系统消息
        Notification({
            title: '系统消息',
            dangerouslyUseHTMLString: true,
            message: `
<div style="display: flex; align-items: center; justify-content: center;">
    <img src="${msg.sendAvatar}" style="width: 35px;height: 35px;margin-right: 5px;">
    <div>
        <p style="margin: 0;">${msg.sendNickname}</p>
        <p style="margin: 0;font-size: 14px;color: #8c939d;overflow:hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;">${msg.content}</p>
    </div>
</div>
`,
        });
        return
    }else if(msg.sendId!==store.state.user.userInfo.id){
        //接收到别人发送的消息
        Notification({
            title: '新消息',
            dangerouslyUseHTMLString: true,
            //用户头像+昵称：消息内容
            message: `
<div style="display: flex; align-items: center; justify-content: center;">
    <img src="${msg.sendAvatar}" style="width: 35px;height: 35px;margin-right: 5px;">
    <div>
        <p style="margin: 0;">${msg.sendNickname}</p>
        <p style="margin: 0;font-size: 14px;color: #8c939d;overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;">${msg.content}</p>
    </div>
</div>
`,
        });
        store.state.user.hasUnreadMessage = 'true';
        window.localStorage.setItem('hasUnreadMessage','true');
    }else{
        //接收到自己发送的消息

    }
}



//心跳检测
var heartCheck = {
    timeout: 60000,//60秒
    timeoutObj: null,
    serverTimeoutObj: null,
    start: function(){
        console.log('开始心跳检测');
        var self = this;
        this.timeoutObj && clearTimeout(this.timeoutObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        this.timeoutObj = setTimeout(function(){
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            const mess = {
                sendId: "-1",
                acceptId: store.state.user.userInfo.id,
                content: "HeartBeat:"+ new Date().getTime() ,
                contentType: 'HeartBeat',
                sendNickname: "心跳检测",
                sendAvatar: null,
                sendTime: new Date().getTime(),
            }
            let messageStr = JSON.stringify(mess);
            socket.send(messageStr);
            self.serverTimeoutObj = setTimeout(function() {
                if(socket.readyState!=1){
                    socket.close();
                }
                // createWebSocket();
            }, self.timeout);

        }, this.timeout)
    }
}


const sendMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(message)
    } else {
        console.error('WebSocket 未连接')
    }
}

const addListener = (listener) => {
    //判断是否已经存在该监听器
    if (listeners.indexOf(listener) === -1) {
        //不存在则添加
        listeners.push(listener)
    }
}

const removeMyListener = (listener) => {
    const index = listeners.indexOf(listener)
    if (index !== -1) {
        listeners.splice(index, 1)
    }
}

const removeInitialListener = () => {
    const index = listeners.indexOf(messageHandle)
    if (index !== -1) {
        listeners.splice(index, 1)
    }
}

const addInitialListener = () => {
    addListener(messageHandle)
}

const closeWebSocket = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
        socket.close()
        //更新在线状态
        userApi.updateOnlineStatus(0).then(res=>{
            if (!res.data.success){
                console.log(res);
            }
        })
    }
}


export {createWebSocket, sendMessage, closeWebSocket,addInitialListener, removeInitialListener,removeMyListener, addListener}
