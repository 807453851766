<template>
  <div class="self-followed">
    <div :class="{darkFans:theme=='dark'}">
      <el-card shadow="never" style="margin-bottom: 10px" v-for="(fan,index) in fansList" :key="index"
               :body-style="theme=='dark'?darkCard:''"
               :class="{darkCard:theme=='dark'}">
        <div style="display: flex;align-items: center;justify-content: space-between">
          <div style="display: flex;align-items: center;">
            <div>
              <el-avatar :size="60" :src="fan.avatar" v-if="fan.avatar"></el-avatar>
            </div>
            <div style="margin-left: 20px;">
              <div style="" class="hoverSpan" :theme="theme" @click="showUser(fan.id)">{{fan.nickname}}</div>
            </div>
            <div>
              <el-tag class="author-Title" type="info" size="mini" v-if="fan.userLabel=='Lv1 关关雎鸠'"
              >{{ fan.userLabel }}
              </el-tag>

              <el-tag class="author-Title" type="danger" size="mini" v-else-if="fan.userLabel=='Lv7 无人能敌'"
              >{{ fan.userLabel }}
              </el-tag>

              <el-tag class="author-Title" type="success" size="mini" v-else
              >{{ fan.userLabel }}
              </el-tag>

              <el-tag class="author-Title" type="danger" size="mini" v-if="fan.account=='admin'">
                站长
              </el-tag>
            </div>
          </div>

          <div  style="float: right">
            <!--关注按钮-->
            <el-button type="primary" size="mini" v-if="fan.followStatus==0&&currentUser.id!=fan.id" @click="followUser(fan)">关注</el-button>
            <el-button type="primary" plain size="mini" v-if="fan.followStatus==1&&currentUser.id!=fan.id" @click="followUser(fan)">互关</el-button>
            <el-button type="primary" size="mini" style="margin-left: 30px" v-if="fan.id==currentUser.id" @click="toMyHome">主页</el-button>
            <el-button plain size="mini"  v-if="currentUser.id!=fan.id" @click="toChat(fan.id)">
              <i class="el-icon-chat-dot-square"></i>私信
            </el-button>
          </div>
        </div>

      </el-card>
      <el-card shadow="never" v-if="isLoading" :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
        <el-skeleton :rows="4" animated/>
      </el-card>
      <el-card shadow="never" v-if="articleListIsEmpty" :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
        <el-empty description="暂无数据"></el-empty>
      </el-card>
      <el-pagination
          :pager-count="pageCount"
          :small="true"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          :layout="layout"
          :total="total">
      </el-pagination>
    </div>
  </div>

</template>
<script>
import followApi from "@/api/follow";
import sessionApi from "@/api/session";

export default {
  data() {
    return {
      theme:'light',
      articleListIsEmpty: false,
      isLoading: false,
      darkCard: {
        backgroundColor: "rgb(17,17,17)",
      },
      fansList:[],
      pageCount: 9,
      layout:'total, sizes, prev, pager, next, jumper',
      total:0,
      currentPage: 1,
      pageSize: 10,
      userId: this.$route.params.id
    }
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  computed: {
    currentUser(){
      return this.$store.state.user.userInfo;
    }
  },
  created() {
    //获取关注列表
    this.getFansList()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 700) {
        this.pageCount = 5;
        this.layout = 'total,prev, pager, next';
      }else {
        this.pageCount = 9;
        this.layout = 'total, sizes, prev, pager, next, jumper';
      }
    },
    toChat(id){
      //保存对话id到vuex
      //创建会话
      sessionApi.createSession(id).then(res=>{
        if(res.data.success){
          this.$store.state.user.acceptId = id;
          this.$router.push({
            path: `/mess/index`,
          });
        }else{
          this.$message.warning('创建会话失败');
        }
      })
    },
    showUser(id){
      //跳转到该用户信息页
      this.$router.push(`/user/${id}/info`)
    },
    followUser(user) {
      followApi.followOrUnFollow(user.id).then(res => {
        user.followStatus = res.data.data.status;
        user.fansCount = res.data.data.num;
      }).catch(() => {
      });
    },
    toMyHome() {
      this.$router.push(`/user/${this.currentUser.id}/info`)
    },
    handleCurrentChange(val){
      this.currentPage = val;
      this.getFansList();
    },
    handleSizeChange(val){
      this.pageSize = val;
      this.getFansList();
    },
    //获取关注列表
    getFansList(){
      this.isLoading = true;
      followApi.getFansListById(this.userId,this.currentPage, this.pageSize).then(res=>{
        this.fansList = res.data.data.list;
        this.total = res.data.data.total
        if (this.fansList.length === 0) {
          this.articleListIsEmpty = true;
        } else {
          this.articleListIsEmpty = false;
        }
        this.isLoading = false;
      })
    }
  }
}
</script>
<style lang="scss">
.darkFans {
  .el-pagination__jump {
    color: white;
  }

  .el-pagination__total {
    color: white;
  }
}
</style>
<style scoped>
.author-Title {
  margin-left: 8px;
}
.hoverSpan[theme='dark'] {
  font-size: 16px;
  color: #fff;
  cursor: pointer
}

.hoverSpan[theme='light'] {
  font-size: 16px;
  color: #303133;
  cursor: pointer
}
.hoverSpan:hover{
  color: #1589fa;
}
.darkNickName {
  color: #fff !important;
}
.darkCard >>> .el-card__header {
  border-bottom: rgb(62, 62, 62) solid 1px !important;
}
.self-followed[theme='light']{
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
}
.self-followed[theme='dark']{
  width: 100%;
  height: 100%;
  background-color: rgb(17,17,17);
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
}


</style>
