<template>
  <div style="overflow-y: scroll;height:calc(100vh - 100px)" @scroll="handleScroll">
    <div>
      <!--导航栏-->
      <el-row type="flex" justify="center">
        <el-col :xs="22" :span="14" class="index-left">
          <div>
            <el-row class="index-tag-list">
              <el-col :span="24">
                <div class="article-tags" :class="{darkTab:theme=='dark'}">
                  <el-tabs :v-model="articletags.length" type="card" :stretch="true" @tab-click="handleClick">
                    <el-tab-pane name="hot" style="background-color: #fff !important;" key="hot" label="热门">
                    </el-tab-pane>
                    <el-tab-pane name="new" style="background-color: #fff !important;" key="new" label="最新">
                    </el-tab-pane>
                    <el-tab-pane
                        :name="tag.id"
                        style="background-color: #fff !important;"
                        v-for="tag in articletags"
                        :key="tag.id"
                        :label="tag.tagName"
                    >
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </el-col>
            </el-row>
            <div>
              <el-col :span="24">
                <template v-if="articleList.length">
                  <el-card shadow="never" style="margin-bottom: 10px" v-for="(article,index) in articleList"
                           :key="index"
                           class="watermarkContent" :body-style="theme=='dark'?darkCard:''"
                           :class="{darkCard:theme=='dark'}">
                    <div class="article-list-item">
                      <!--个人信息-->
                      <div class="article-list-item-title">
                        <div class="author-message">
                          <div>
                            <el-image
                                style="width: 50px; height: 50px;border-radius: 50%"
                                :src="article.user.avatar"
                                :preview-src-list="[article.user.avatar]"
                            >
                            </el-image>
                          </div>

                          <el-popover
                              :popper-class="theme=='dark'?'popperClass':''"
                              placement="bottom"
                              width="350"
                              trigger="hover"
                          >
                            <div>
                              <div class="article-list-item-title">
                                <div class="author-message">
                                  <el-image
                                      style="width: 50px; height: 50px;border-radius: 50%"
                                      :src="article.user.avatar"
                                      :preview-src-list="[article.user.avatar]"
                                  >
                                  </el-image>
                                  <div class="author-message-right">
                                    <span style="margin-left: 10px" class="articlAuthorName"
                                          @click="showUser(article.user.id)">{{ article.user.nickname }}</span>

                                    <el-tag class="author-Title" type="info" size="mini"
                                            v-if="article.user.userLabel=='Lv1 关关雎鸠'"
                                    >{{ article.user.userLabel }}
                                    </el-tag>

                                    <el-tag class="author-Title" type="danger" size="mini"
                                            v-else-if="article.user.userLabel=='Lv7 无人能敌'"
                                    >{{ article.user.userLabel }}
                                    </el-tag>

                                    <el-tag class="author-Title" type="success" size="mini" v-else
                                    >{{ article.user.userLabel }}
                                    </el-tag>

                                    <el-tag class="author-Title" type="danger" size="mini"
                                            v-if="article.user.account=='admin'">
                                      站长
                                    </el-tag>
                                    <!--关注按钮-->
                                    <el-button type="primary" size="mini" style="margin-left: 10px"
                                               v-if="article.user.id!=userInfo.id&&article.user.followStatus==0"
                                               @click="followUser(article.user)">关注
                                    </el-button>
                                    <el-button type="primary" size="mini" style="margin-left: 10px"
                                               v-if="article.user.id==userInfo.id" @click="toMyHome">主页
                                    </el-button>
                                    <el-button type="primary" plain size="mini" style="margin-left: 10px"
                                               v-if="article.user.id!=userInfo.id&&article.user.followStatus==1"
                                               @click="followUser(article.user)">已关注
                                    </el-button>
                                  </div>
                                </div>
                                <div style="margin-top: 10px">
                                  <!--简介-->
                                  <div>
                                    简介：
                                    <span>{{ article.user.signature }}</span>
                                  </div>
                                  <el-divider></el-divider>
                                  <!--粉丝关注积分-->
                                  <div style="display: flex;width: 100%">
                                    <span style="width: 33%;cursor: pointer;" class="hoverSpan"
                                          @click="toUserFan(article.user.id)">粉丝:<span
                                        style="margin-left: 5px">{{ article.user.fansCount }}</span></span>
                                    <span style="width: 33%;cursor: pointer" class="hoverSpan"
                                          @click="toUserFollow(article.user.id)">关注:<span
                                        style="margin-left: 5px">{{ article.user.followCount }}</span></span>
                                    <span style="width: 33%;">积分:<span style="margin-left: 5px">{{
                                        article.user.score
                                      }}</span></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div slot="reference" class="author-message-right">
                              <span style="margin-left: 10px" class="articlAuthorName"
                                    @click="showUser(article.user.id)">{{ article.user.nickname }}</span>


                              <el-tag class="author-Title" type="info" size="mini"
                                      v-if="article.user.userLabel=='Lv1 关关雎鸠'"
                              >{{ article.user.userLabel }}
                              </el-tag>

                              <el-tag class="author-Title" type="danger" size="mini"
                                      v-else-if="article.user.userLabel=='Lv7 无人能敌'"
                              >{{ article.user.userLabel }}
                              </el-tag>

                              <el-tag class="author-Title" type="success" size="mini" v-else
                              >{{ article.user.userLabel }}
                              </el-tag>

                              <el-tag class="author-Title" type="danger" size="mini"
                                      v-if="article.user.account=='admin'">
                                站长
                              </el-tag>
                            </div>
                          </el-popover>


                        </div>
                      </div>
                      <!--文章内容-->
                      <div class="article-list-item-content-container" :theme="theme">
                        <!--文章文字-->
                        <div class="article-list-item-content" style="width: 100%">
                          <a class="article-list-item-content-title" @click="toArticleDetail(article.id)">
                            <h4>{{ article.title }}</h4>
                          </a>
                          <a class="article-list-item-content-text" @click="toArticleDetail(article.id)" :theme="theme">
                            <div v-html="article.description"
                                 style="width: 100%; word-wrap: break-word;white-space: normal;"></div>
                          </a>
                          <div style="margin-top: 20px;">
                            <el-tag type="info" size="mini" v-for="(tag,index) in article.tagNames" :key="index"
                                    style="margin-left: 8px;">{{ tag }}
                            </el-tag>
                          </div>
                          <div style="margin-top: 20px;">
                            <!--时间-->
                            <span class="articleTime" :theme="theme">{{ article.updateTime }}</span>

                            <span class="suxian" :theme="theme">|</span>

                            <!--点赞、收藏、评论-->
                            <button v-if="article.likeStatus==1" style="background: none;border: none"
                                    @click="toLikeArticle(article)"><i class="iconfont icon-dianzan_kuai tubiao"
                                                                       :theme="theme"></i><span
                                style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{
                                article.likeCount
                              }}</span>
                            </button>

                            <button v-else style="background: none;border: none" @click="toLikeArticle(article)"><i
                                class="iconfont icon-dianzan2 tubiao"
                                :theme="theme"></i><span
                                style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{
                                article.likeCount
                              }}</span>
                            </button>

                            <span class="suxian" :theme="theme">|</span>


                            <button v-if="article.collectStatus==1" style="background: none;border: none"
                                    @click="toCollect(article)"><i class="iconfont icon-shoucang3 tubiao"
                                                                   :theme="theme"></i><span
                                style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{
                                article.collectCount
                              }}</span></button>

                            <button v-else style="background: none;border: none" @click="toCollect(article)"><i
                                class="iconfont icon-shoucang8 tubiao"
                                :theme="theme"></i><span
                                style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{
                                article.collectCount
                              }}</span></button>


                            <span class="suxian" :theme="theme">|</span>

                            <button style="background: none;border: none"><i class="iconfont icon-31pinglun tubiao"
                                                                             :theme="theme"></i><span
                                style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{
                                article.commentCount
                              }}</span></button>
                          </div>
                        </div>
                        <!--文章图片-->
                        <div class="article-list-item-img" v-if="article.cover">
                          <el-image

                              style="width: 100px;"
                              :src="article.cover"
                              :preview-src-list="[article.cover]"
                          >
                          </el-image>
                        </div>
                      </div>

                    </div>
                  </el-card>
                </template>
                <el-card shadow="never" v-if="isLoading" :body-style="theme=='dark'?darkCard:''"
                         :class="{darkCard:theme=='dark'}">
                  <el-skeleton :rows="4" animated/>
                </el-card>
                <el-card shadow="never" v-if="articleListIsEmpty" :body-style="theme=='dark'?darkCard:''"
                         :class="{darkCard:theme=='dark'}">
                  <el-empty description="暂无数据"></el-empty>
                </el-card>
              </el-col>
            </div>
          </div>
        </el-col>

        <el-col :span="8" class="index-right">
          <div class="grid-content bg-purple-light">
<!--            <el-row style="margin-bottom: 20px">-->
<!--              <el-col :span="24">-->
<!--                <div class="grid-content bg-purple-dark">-->
<!--                  <el-card shadow="never" :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">-->
<!--                    <div slot="header" class="clearfix">-->
<!--                      <span>公告</span>-->
<!--                      <el-button style="float: right; padding: 3px 0" type="text">更多</el-button>-->
<!--                    </div>-->
<!--                    WebSocket聊天室上线啦，快来这里交朋友吧!-->
<!--                    <el-link style="float: right; padding: 3px 0" type="primary" :underline="false"-->
<!--                             href="https://chat.tobeyou.cn" target="_blank">详情-->
<!--                    </el-link>-->
<!--                  </el-card>-->
<!--                </div>-->
<!--              </el-col>-->
<!--            </el-row>-->
            <el-row style="margin-bottom: 20px">
              <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                  <el-card shadow="never" :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
                    <div slot="header" class="clearfix">
                      <span>推荐好友</span>
                      <el-button style="float: right; padding: 3px 0" type="text" @click="$router.push('/search/user')">
                        更多
                      </el-button>
                    </div>
                    <div style="">
                      <el-empty description="暂无数据" v-if="isEmptyUserList"></el-empty>
                      <div class="user-card" v-for="(user) in userList" :key="user.id">
                        <el-col>
                          <el-card shadow="never" :body-style="theme=='dark'?darkCard:''"
                                   :class="{darkCard:theme=='dark'}">
                            <!--个人信息-->
                            <div style="display: flex">
                              <!--头像-->
                              <div>
                                <el-image
                                    style="width: 50px; height: 50px;border-radius: 50%"
                                    :src="user.avatar"
                                    :preview-src-list="[user.avatar]"
                                >
                                </el-image>
                              </div>

                              <div>
                                <span style="margin-left: 10px;cursor: pointer" class="hoverSpan"
                                      @click="$router.push(`/user/${user.id}/info`)">{{ user.nickname }}</span>

                                <el-tag class="author-Title" type="info" size="mini"
                                        v-if="user.userLabel=='Lv1 关关雎鸠'"
                                >{{ user.userLabel }}
                                </el-tag>

                                <el-tag class="author-Title" type="danger" size="mini"
                                        v-else-if="user.userLabel=='Lv7 无人能敌'"
                                >{{ user.userLabel }}
                                </el-tag>

                                <el-tag class="author-Title" type="success" size="mini" v-else
                                >{{ user.userLabel }}
                                </el-tag>

                                <el-tag class="author-Title" type="danger" size="mini" v-if="user.account=='admin'">
                                  站长
                                </el-tag>
                                <div style="margin: 10px 0 0 10px;font-size: 12px;color: #8c939d">该用户很高冷，暂无个人简介......
                                </div>
                              </div>
                            </div>
                            <!--关注按钮-->
                            <!--                  <el-button type="primary" size="mini" style="margin-left: auto;margin-right: 10px;">关注</el-button>-->

                            <!--关注按钮-->
                            <el-button type="primary" size="mini"
                                       style="margin-left: auto;margin-right: 10px;margin-top: 10px"
                                       v-if="user.id!=userInfo.id&&user.followStatus==0" @click="followUser(user)">关注
                            </el-button>
                            <el-button type="primary" size="mini"
                                       style="margin-left: auto;margin-right: 10px;margin-top: 10px"
                                       v-if="user.id==userInfo.id" @click="toMyHome">主页
                            </el-button>
                            <el-button type="primary" plain size="mini"
                                       style="margin-left: auto;margin-right: 10px;margin-top: 10px"
                                       v-if="user.id!=userInfo.id&&user.followStatus==1" @click="followUser(user)">已关注
                            </el-button>
                          </el-card>
                        </el-col>
                      </div>
                      <el-skeleton :rows="4" v-if="isLoadingUserList" animated/>
                    </div>

                  </el-card>
                </div>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 20px">
              <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                  <el-card shadow="never" :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
                    <div slot="header" class="clearfix">
                      <span>积分排行(前10）</span>
<!--                      <el-button style="float: right; padding: 3px 0" type="text">更多</el-button>-->
                    </div>
                    <div>
                      <el-empty description="暂无数据" v-if="isEmptyScoreList"></el-empty>
                      <el-carousel trigger="click" height="150px" indicator-position="outside">
                        <el-carousel-item v-for="(user,index) in scoreList" :key="user.id">
                          <el-col>
                            <el-card shadow="never" :body-style="theme=='dark'?darkCard:''"
                                     :class="{darkCard:theme=='dark'}">
                              <!--个人信息-->
                              <div style="display: flex">
                                <!--头像-->
                                <div><el-image
                                    style="width: 50px; height: 50px;border-radius: 50%"
                                    :src="user.avatar"
                                    :preview-src-list="[user.avatar]"
                                >
                                </el-image></div>

                                <div>
                                  <span style="margin-left: 10px;cursor: pointer" class="hoverSpan"
                                        @click="$router.push(`/user/${user.id}/info`)">{{ user.nickname }}</span>

                                  <el-tag class="author-Title" type="info" size="mini"
                                          v-if="user.userLabel=='Lv1 关关雎鸠'"
                                  >{{ user.userLabel }}
                                  </el-tag>

                                  <el-tag class="author-Title" type="danger" size="mini"
                                          v-else-if="user.userLabel=='Lv7 无人能敌'"
                                  >{{ user.userLabel }}
                                  </el-tag>

                                  <el-tag class="author-Title" type="success" size="mini" v-else
                                  >{{ user.userLabel }}
                                  </el-tag>

                                  <el-tag class="author-Title" type="danger" size="mini" v-if="user.role=='admin'">
                                    管理员
                                  </el-tag>
                                  <span style="margin-left: 10px;color: #ff4400">Top{{ index + 1 }}({{
                                      user.score
                                    }})</span>
                                  <div style="margin: 10px 0 0 10px;font-size: 12px;color: #8c939d">该用户很高冷，暂无个人简介......
                                  </div>
                                </div>
                              </div>
                              <!--关注按钮-->
                              <!--                  <el-button type="primary" size="mini" style="margin-left: auto;margin-right: 10px;">关注</el-button>-->
                              <!--关注按钮-->
                              <el-button type="primary" size="mini"
                                         style="margin-left: auto;margin-right: 10px;margin-top: 10px"
                                         v-if="user.id!=userInfo.id&&user.followStatus==0" @click="followUser(user)">关注
                              </el-button>
                              <el-button type="primary" size="mini"
                                         style="margin-left: auto;margin-right: 10px;margin-top: 10px"
                                         v-if="user.id==userInfo.id" @click="toMyHome">主页
                              </el-button>
                              <el-button type="primary" plain size="mini"
                                         style="margin-left: auto;margin-right: 10px;margin-top: 10px"
                                         v-if="user.id!=userInfo.id&&user.followStatus==1" @click="followUser(user)">已关注
                              </el-button>
                            </el-card>
                          </el-col>
                        </el-carousel-item>
                      </el-carousel>
                      <el-skeleton :rows="4" v-if="isLoadingScoreList" animated/>
                    </div>
                  </el-card>
                </div>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 20px;">
              <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                  <div>
                    <div style="color: rgb(0, 11, 54);font-size: 14px" :class="{darkFooter:theme=='dark'}">
                      <p>
                        <el-link href="https://tobeyou.cn" target="_blank" :underline="false"
                                 :class="{darkFooter:theme=='dark'}"><i
                            class="iconfont icon-31wode"></i>站长：xiaolinzi
                        </el-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<el-link href="https://chat.tobeyou.cn"
                                                                               :class="{darkFooter:theme=='dark'}"
                                                                               target="_blank" :underline="false"><i
                          class="iconfont icon-duihua1"></i>WebSocket聊天室
                      </el-link>
                      </p>
                      <p>
                        <el-link href="/square/post/1771426722064982017" target="_blank" :underline="false"
                                 :class="{darkFooter:theme=='dark'}"><i
                            class="iconfont icon-beiwanglu"></i>建议反馈
                        </el-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;站长邮箱：xiaolin_zii@163.com
                      </p>
                      <p>Copyright©2024 | 粤ICP备号xxxxxx</p>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// import watermark from '@/assets/js/watermark1.js'
import likeApi from "@/api/like";
import articleApi from "@/api/article";
import followApi from "@/api/follow";
import collectApi from "@/api/collect";
import userApi from "@/api/user";

export default {

  data() {
    return {
      darkCard: {
        backgroundColor: "rgb(17,17,17)",
      },
      likeInfo: {
        type: 1,
        targetId: ''
      },
      articleListIsEmpty: false,
      isLoading: false,
      articletags: [],
      articleList: [],
      userList: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      theme: 'light',
      queryInfo: {
        sort: 0,
        tagIds: [],
      },
      isLoadingUserList: false,
      isEmptyUserList: false,
      isEmptyScoreList: false,
      scoreList: [],
      isLoadingScoreList: false,
    }
  },

  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  mounted() {
    const text = this.$store.state.user.userInfo.account;
    this.$watermark({
      text: text,
      selectors: ".watermarkContent",
      color: "rgba(0, 0, 0, .2)",
      size: 25
    });
    // watermark.set(text, '编程社区')
  },
  destroyed() {
    // watermark.close()
  },
  created() {
    this.getAllTags();
    this.initArticleList();
    this.getRecommendUser();
    this.getScoreRank();
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  methods: {
    getScoreRank() {
      this.isLoadingScoreList = true;
      userApi.scoreRank().then(res => {
        this.scoreList = res.data.data.userList;
        this.isLoadingScoreList = false;
        if (this.scoreList.length === 0) {
          this.isEmptyScoreList = true;
        } else {
          this.isEmptyScoreList = false;
        }
      })
    },
    getRecommendUser() {
      this.isLoadingUserList = true;
      userApi.randomUser().then(res => {
        this.userList = res.data.data.userList;
        this.isLoadingUserList = false;
        if (this.userList.length === 0) {
          this.isEmptyUserList = true;
        } else {
          this.isEmptyUserList = false;
        }
      })
    },
    toUserFan(id) {
      if (id == this.userInfo.id)
        this.$router.push(`/self/followed`)
      else
        this.$router.push(`/user/${id}/followed`)
    },
    toUserFollow(id) {
      if (id == this.userInfo.id)
        this.$router.push(`/self/follow`)
      else
        this.$router.push(`/user/${id}/follow`)
    },
    toCollect(article) {
      collectApi.collectOrUnCollect(article.id).then(res => {
        article.collectCount = res.data.data.num;
        article.collectStatus = res.data.data.status;
      }).catch(() => {
      });
    },
    toLikeArticle(article) {
      this.likeInfo.targetId = article.id;
      likeApi.likeOrUnLike(this.likeInfo).then(res => {
        article.likeCount = res.data.data.num;
        article.likeStatus = res.data.data.status;
      }).catch(() => {
      });
    },
    followUser(user) {
      followApi.followOrUnFollow(user.id).then(res => {
        user.followStatus = res.data.data.status;
        user.fansCount = res.data.data.num;
      }).catch(() => {
      });
    },
    toMyHome() {
      this.$router.push(`/user/${this.userInfo.id}/info`)
    },
    showUser(id) {
      //跳转到该用户信息页
      this.$router.push(`/user/${id}/info`)
    },
    getAllTags() {
      articleApi.getAllTag().then(response => {
        this.articletags = response.data.data.list;
      })
    },
    initArticleList() {
      this.isLoading = true;
      this.articleListIsEmpty = false;
      articleApi.getArticleList(this.currentPage, this.pageSize).then(res => {
        this.articleList = res.data.data.articleVOList;
        this.total = res.data.data.total;
        if (this.articleList.length === 0) {
          this.articleListIsEmpty = true;
        } else {
          this.articleListIsEmpty = false;
        }
        this.isLoading = false;
      });
    },
    handleClick(tab) {
      if (tab.name == "hot") {
        this.articleList = [];
        this.queryInfo.sort = 2;
        this.isLoading = true;
        this.articleListIsEmpty = false;
        articleApi.searchArticle(this.queryInfo, this.currentPage, this.pageSize).then(res => {
          this.articleList = res.data.data.articleVOList;
          this.total = res.data.data.total;
          if (this.articleList.length === 0) {
            this.articleListIsEmpty = true;
          } else {
            this.articleListIsEmpty = false;
          }
          this.isLoading = false;
          this.queryInfo.sort = 0;
        });
      } else if (tab.name == "new") {
        this.articleList = [];
        this.articleListIsEmpty = false;
        this.queryInfo.sort = 1;
        this.isLoading = true;
        articleApi.searchArticle(this.queryInfo, this.currentPage, this.pageSize).then(res => {
          this.articleList = res.data.data.articleVOList;
          this.total = res.data.data.total;
          if (this.articleList.length === 0) {
            this.articleListIsEmpty = true;
          } else {
            this.articleListIsEmpty = false;
          }
          this.isLoading = false;
          this.queryInfo.sort = 0;
        })
      } else {
        this.articleList = [];
        this.articleListIsEmpty = false;
        this.queryInfo.tagIds = [tab.name]
        this.isLoading = true;
        articleApi.searchArticle(this.queryInfo, this.currentPage, this.pageSize).then(res => {
          this.articleList = res.data.data.articleVOList;
          this.total = res.data.data.total;
          if (this.articleList.length === 0) {
            this.articleListIsEmpty = true;
          } else {
            this.articleListIsEmpty = false;
          }
          this.isLoading = false;
          this.queryInfo.tagIds = [];
        })
      }
    },
    handleScroll(e) {
      const clientHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      const scrollTop = e.target.scrollTop;
      if (clientHeight + scrollTop >= scrollHeight - 50) {
        if (this.articleList.length == this.total) {
          return;
        }
        //加载更多
        this.isLoading = true;

        this.currentPage++;
        articleApi.getArticleList(this.currentPage, this.pageSize).then(res => {
          this.articleList = this.articleList.concat(res.data.data.articleVOList);
          this.isLoading = false;
          console.log(this.articleList.length);
        });

      }
    },
    toArticleDetail(id) {
      this.$router.push(`/square/post/${id}`);
    }
  }
}

</script>

<style lang="scss">
.el-popover {
  text-align: start;
}

.popperClass.el-popover {
  background-color: rgb(17, 17, 17) !important;
  color: #ffffff !important;

  .popper__arrow::after {
    border-bottom-color: rgb(17, 17, 17) !important;
  }
}
</style>
<style scoped>
.hoverSpan:hover {
  color: #1589fa;
}

.darkFooter {
  color: white !important;
}

.articlAuthorName {
  cursor: pointer;

}

.articlAuthorName:hover {
  color: #1589fa;

}

.darkCard >>> .el-card__header {
  border-bottom: rgb(62, 62, 62) solid 1px !important;
}

.darkCount {
  color: #ffffff;
}

.tubiao[theme='light'] {
  color: rgba(3, 3, 3);
  font-size: 14px;
  cursor: pointer;
}

.tubiao[theme='dark'] {
  color: rgba(222, 222, 222);
  font-size: 14px;
  cursor: pointer;
}

.suxian[theme='light'] {
  margin: 0 6px;
  color: rgba(0, 0, 0, .1);

}

.suxian[theme='dark'] {
  margin: 0 6px;
  color: rgba(255, 255, 255, .1);

}

.articleTime[theme='light'] {
  color: rgba(0, 0, 0, .45);
  font-size: 14px
}

.articleTime[theme='dark'] {
  color: rgba(255, 255, 255, .45);
  font-size: 14px
}

.darkCard {
  border: rgb(62, 62, 62) solid 1px !important;
  color: #ffffff;
  background-color: rgb(17, 17, 17);
}

.article-tags >>> .el-tabs--card > .el-tabs__header {
  background-color: #fff !important;
  border-bottom: none !important;
}

.darkTab >>> .el-tabs--card > .el-tabs__header {
  background-color: rgb(17, 17, 17) !important;
  border-bottom: none !important;
}


.darkTab >>> .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: rgb(62, 62, 62) solid 1px !important;
}

.darkTab >>> .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: rgb(62, 62, 62) solid 1px !important;
  color: #ffffff;
}


.index-left {
  margin-right: 20px;
}


.index-right {

}

.article-list-item {
}

.article-list-item-title {

  /*margin-bottom: 10px;*/
}

.article-list-item-content-title, .article-list-item-content-text {
  cursor: pointer;
}

.article-list-item-content-title:hover {
  color: #3a8ee6;
}

.author-message {
  display: flex;

}

.author-message-right {
  line-height: 35px;
}

.author-Title {
  margin-left: 8px;
}

.article-list-item-content-text[theme='light'] {
  color: rgba(0, 0, 0, .45);
  font-size: 15px;
  line-height: 1.75;
}

.article-list-item-content-text[theme='dark'] {
  color: #ffffff;
  font-size: 15px;
  line-height: 1.75;
}

.article-list-item-content-container[theme='light'] {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, .85);
  align-items: normal;
}

.article-list-item-content-container[theme='dark'] {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  color: #ffffff;
  align-items: normal;
}

.article-list-item-content {

}

.article-list-item-img {
  margin-left: 40px;
  max-width: 300px;
}

.article-tags {
  margin: 0 auto;
}


@media screen and (max-width: 768px) {
  .index-left {
    margin-right: 0px;
  }

  .article-list-item-content-container {
    /*display: block;*/
    flex-direction: column;
  }

  .article-list-item-img {
    margin: 10px auto;
    float: top;
  }

  .index-right {
    display: none;
  }
}
</style>
