import request from '@/utils/request'

// 查询AI管理列表
export function listAi(query) {
  return request({
    url: `/system/ai/list/${query.pageNum}/${query.pageSize}`,
    method: 'post',
    data: {
      name: query.name,
      text: query.text,
      model: query.model,
      isDeleted: query.isDeleted,
    }
  })
}

// 查询AI管理详细
export function getAi(id) {
  return request({
    url: '/system/ai/' + id,
    method: 'get'
  })
}

// 新增AI管理
export function addAi(data) {
  return request({
    url: '/system/ai',
    method: 'post',
    data: data
  })
}

// 修改AI管理
export function updateAi(data) {
  return request({
    url: '/system/ai',
    method: 'put',
    data: data
  })
}

// 删除AI管理
export function delAi(id) {
  return request({
    url: '/system/ai/' + id,
    method: 'delete'
  })
}
