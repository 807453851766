<template>
  <div>
    <div class="tools">
      <button :title="'基础按钮'" @click="prePage" class="mr10">上一页</button>
      <button :title="'基础按钮'" @click="nextPage" class="mr10">下一页</button>
      <div class="page">
        <input v-model.number="currentPage"
               type="number"
               class="inputNumber"
               @input="inputEvent"> / {{ pageCount }}
      </div>
      <button :title="'基础按钮'" @click="rotateClockwise" class="mr10">顺时针</button>
      <button :title="'基础按钮'" @click="rotateCounterClockwise" class="mr10">逆时针</button>
    </div>
    <div class="pdfArea" ref="pdfViewer"></div>
  </div>
</template>

<script>
import { getDocument } from 'pdfjs-dist/build/pdf';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.mjs'
export default {
  props: {
    pdfUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      pageCount: 0,
      pdfInstance: null,
      canvas: null,
      ctx: null,
      rotate: 0
    };
  },
  watch: {
    currentPage: 'renderPage'
  },
  mounted() {
    this.initPdf();
  },
  methods: {
    async initPdf() {
      const loadingTask = getDocument(this.pdfUrl);
      try {
        this.pdfInstance = await loadingTask.promise;
        this.pageCount = this.pdfInstance.numPages;
        this.renderPage();
      } catch (error) {
        console.error('PDF loading error: ', error);
      }
    },
    async renderPage() {
      const page = await this.pdfInstance.getPage(this.currentPage);
      const viewport = page.getViewport({ scale: 1.5,rotation: this.rotate  });
      this.canvas = this.canvas || this.$refs.pdfViewer.appendChild(document.createElement('canvas'));
      this.ctx = this.canvas.getContext('2d');
      this.canvas.height = viewport.height;
      this.canvas.width = viewport.width;

      const renderContext = {
        canvasContext: this.ctx,
        viewport: viewport
      };
      await page.render(renderContext).promise;
    },
    prePage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
    rotateClockwise() {
      this.rotate += 90;
      if (this.rotate >= 360) {
        this.rotate = 0;
      }
      this.renderPage();
    },
    rotateCounterClockwise() {
      this.rotate -= 90;
      if (this.rotate >= 360) {
        this.rotate = 0;
      }
      this.renderPage();
    },

    inputEvent() {
      if (this.currentPage > this.pageCount) {
        this.currentPage = this.pageCount;
      } else if (this.currentPage < 1) {
        this.currentPage = 1;
      }
      this.renderPage();
    }
  }
}
</script>

<style scoped>
.pdf-preview canvas {
  width: 100%; /* Adjust canvas size */
}

.tools {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.mr10 {
  margin-right: 10px;
  background-color: white;
  border: 1px solid #999999;
  border-radius: 5px;
  cursor: pointer;
}

.page {
  margin: 0 10px;
}

.inputNumber {
  border-radius: 8px;
  border: 1px solid #999999;
  height: 20px;
  font-size: 18px;
  width: 45px;
  text-align: center;
}

.inputNumber:focus {
  border: 1px solid #00aeff;
  background-color: rgba(18, 163, 230, 0.096);
  outline: none;
  transition: 0.2s;
}

.pdfArea {
  width: 100%;
  overflow: auto;
}
</style>
