<template>
  <div class="passagePage" style="overflow-y: scroll;height:calc(100vh - 100px)">
    <div :class="{darkResource:theme=='dark'}">
      <!--导航栏-->
      <el-row type="flex" justify="center">
        <el-col :xs="22" :span="22" >
          <div >
            <el-row>
              <el-col :span="24">
                <div :class="{darkForm:theme=='dark'}" class="lightForm">
                  <el-form :inline="isPC"  :model="queryInfo" >
                    <el-form-item label="内容:">
                      <el-input size="small"  maxlength="50" v-model="queryInfo.keyword" clearable placeholder="输入关键字进行搜索" :class="{darkInput:theme=='dark'}"></el-input>
                    </el-form-item>
                    <el-form-item label="标签:">
                      <el-select v-model="queryInfo.tagIds" multiple placeholder="请选择" clearable  style="width: 100%"
                                 :class="{darkInput:theme=='dark'}"
                                 :popper-append-to-body="false"
                                 @change="queryByTag">
                        <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.tagName"
                            :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-button size="small" type="primary" @click="queryArticleList">查询</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
            </el-row>

            <el-row style="margin-top: 20px;">
              <el-col :span="24">
                <div class="passageOrder" :class="{darkTab:theme=='dark'}">
                  <el-tabs v-model="activeName" @tab-click="handleClick" >
                    <el-tab-pane label="推荐" name="recommend">

                    </el-tab-pane>
                    <el-tab-pane label="最新" name="new">

                    </el-tab-pane>
                    <el-tab-pane label="最热" name="hot">

                    </el-tab-pane>
                  </el-tabs>
                </div>
              </el-col>
            </el-row>

            <el-row type="flex" justify="center">
              <el-col :span="24">
                <el-row>
                  <el-col :span="24">
                    <template v-if="articleList.length">
                      <el-card shadow="never" style="margin-bottom: 10px" v-for="(article,index) in articleList" :key="index" class="watermarkContent"
                               :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
                        <div class="article-list-item"  >
                          <!--个人信息-->
                          <div class="article-list-item-title">
                            <div class="author-message">
                              <div>
                                <el-image
                                    style="width: 50px; height: 50px;border-radius: 50%"
                                    :src="article.user.avatar"
                                    :preview-src-list="[article.user.avatar]"
                                >
                                </el-image>
                              </div>

                              <el-popover
                                  :popper-class="theme=='dark'?'popperClass':''"
                                  placement="bottom"
                                  width="350"
                                  trigger="hover"
                              >
                                <div>
                                  <div class="article-list-item-title">
                                    <div class="author-message">
                                      <el-image
                                          style="width: 50px; height: 50px;border-radius: 50%"
                                          :src="article.user.avatar"
                                          :preview-src-list="[article.user.avatar]"
                                      >
                                      </el-image>
                                      <div  class="author-message-right">
                                        <span style="margin-left: 10px" class="articlAuthorName" @click="showUser(article.user.id)">{{ article.user.nickname }}</span>


                                        <el-tag class="author-Title" type="info" size="mini" v-if="article.user.userLabel=='Lv1 关关雎鸠'"
                                        >{{ article.user.userLabel }}
                                        </el-tag>

                                        <el-tag class="author-Title" type="danger" size="mini" v-else-if="article.user.userLabel=='Lv7 无人能敌'"
                                        >{{ article.user.userLabel }}
                                        </el-tag>

                                        <el-tag class="author-Title" type="success" size="mini" v-else
                                        >{{ article.user.userLabel }}
                                        </el-tag>

                                        <el-tag class="author-Title" type="danger" size="mini" v-if="article.user.account=='admin'">
                                          站长
                                        </el-tag>
                                        <!--关注按钮-->
                                        <el-button type="primary" size="mini" style="margin-left: 10px" v-if="article.user.id!=userInfo.id&&article.user.followStatus==0" @click="followUser(article.user)">关注</el-button>
                                        <el-button type="primary" size="mini" style="margin-left: 10px" v-if="article.user.id==userInfo.id" @click="toMyHome">主页</el-button>
                                        <el-button type="primary" plain size="mini" style="margin-left: 10px" v-if="article.user.id!=userInfo.id&&article.user.followStatus==1" @click="followUser(article.user)">已关注</el-button>
                                      </div>
                                    </div>
                                    <div style="margin-top: 10px">
                                      <!--简介-->
                                      <div>
                                        简介：
                                        <span>{{article.user.signature}}</span>
                                      </div>
                                      <el-divider></el-divider>
                                      <!--粉丝关注积分-->
                                      <div style="display: flex;width: 100%">
                                        <span style="width: 33%;cursor: pointer;" class="hoverSpan" @click="toUserFan(article.user.id)">粉丝:<span style="margin-left: 5px" >{{ article.user.fansCount }}</span></span>
                                        <span style="width: 33%;cursor: pointer"  class="hoverSpan"  @click="toUserFollow(article.user.id)">关注:<span style="margin-left: 5px">{{ article.user.followCount }}</span></span>
                                        <span style="width: 33%;">积分:<span style="margin-left: 5px" >{{ article.user.score }}</span></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div slot="reference" class="author-message-right">
                                  <span style="margin-left: 10px" class="articlAuthorName" @click="showUser(article.user.id)">{{ article.user.nickname }}</span>


                                  <el-tag class="author-Title" type="info" size="mini" v-if="article.user.userLabel=='Lv1 关关雎鸠'"
                                  >{{ article.user.userLabel }}
                                  </el-tag>

                                  <el-tag class="author-Title" type="danger" size="mini" v-else-if="article.user.userLabel=='Lv7 无人能敌'"
                                  >{{ article.user.userLabel }}
                                  </el-tag>

                                  <el-tag class="author-Title" type="success" size="mini" v-else
                                  >{{ article.user.userLabel }}
                                  </el-tag>

                                  <el-tag class="author-Title" type="danger" size="mini" v-if="article.user.account=='admin'">
                                    站长
                                  </el-tag>
                                </div>
                              </el-popover>


                            </div>
                          </div>
                          <!--文章内容-->
                          <div class="article-list-item-content-container" :theme="theme">
                            <!--文章文字-->
                            <div class="article-list-item-content" style="width: 100%">
                              <a class="article-list-item-content-title"  @click="toArticleDetail(article.id)">
                                <h4 v-html="article.title"></h4>
                              </a>
                              <a class="article-list-item-content-text" @click="toArticleDetail(article.id)" :theme="theme">
                                <div v-html="article.description" style="width: 100%; word-wrap: break-word;white-space: normal;"></div>
                              </a>
                              <div style="margin-top: 20px;">
                                <el-tag type="info" size="mini" v-for="(tag,index) in article.tagNames" :key="index" style="margin-left: 8px;">{{tag}}</el-tag>
                              </div>
                              <div style="margin-top: 20px;">
                                <!--时间-->
                                <span class="articleTime" :theme="theme">{{ article.updateTime }}</span>
                                <span class="suxian" :theme="theme">|</span>
                                <!--点赞、收藏、评论-->
                                <!--点赞、收藏、评论-->
                                <button v-if="article.likeStatus==1" style="background: none;border: none" @click="toLikeArticle(article)"><i class="iconfont icon-dianzan_kuai tubiao"
                                                                                                                                              :theme="theme"></i><span
                                    style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{ article.likeCount }}</span>
                                </button>

                                <button v-else style="background: none;border: none" @click="toLikeArticle(article)"><i class="iconfont icon-dianzan2 tubiao"
                                                                                                                        :theme="theme"></i><span
                                    style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{ article.likeCount }}</span>
                                </button>

                                <span class="suxian" :theme="theme">|</span>
                                <button v-if="article.collectStatus==1" style="background: none;border: none" @click="toCollect(article)"><i class="iconfont icon-shoucang3 tubiao"
                                                                                                                                             :theme="theme" ></i><span
                                    style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{
                                    article.collectCount
                                  }}</span></button>

                                <button v-else style="background: none;border: none" @click="toCollect(article)"><i class="iconfont icon-shoucang8 tubiao"
                                                                                                                    :theme="theme" ></i><span
                                    style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{
                                    article.collectCount
                                  }}</span></button>
                                <span class="suxian" :theme="theme">|</span>
                                <button style="background: none;border: none"><i class="iconfont icon-31pinglun tubiao"
                                                                                 :theme="theme"></i><span
                                    style="margin-left: 4px" :class="{darkCount:theme=='dark'}">{{ article.commentCount }}</span></button>
                              </div>
                            </div>
                            <!--文章图片-->
                            <div class="article-list-item-img" v-if="article.cover">
                              <el-image

                                  style="width: 100px;"
                                  :src="article.cover"
                                  :preview-src-list="[article.cover]"
                              >
                              </el-image>
                            </div>
                          </div>

                        </div>
                      </el-card>
                    </template>
                    <el-card shadow="never" v-if="isLoading" :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
                      <el-skeleton :rows="4" animated/>
                    </el-card>
                    <el-card shadow="never" v-if="articleListIsEmpty" :body-style="theme=='dark'?darkCard:''" :class="{darkCard:theme=='dark'}">
                      <el-empty description="暂无数据"></el-empty>
                    </el-card>
                  </el-col>
                </el-row>
              </el-col>

            </el-row>
            <el-row style="margin-top: 10px;" type="flex" justify="center" v-if="articleList.length">
              <el-col :span="24">
                <el-pagination
                    :small="true"
                    :pager-count="pageCount"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageSize"
                    :layout="layout"
                    :total="total">
                </el-pagination>
              </el-col>
            </el-row>
          </div>
        </el-col>

      </el-row>
    </div>
    <div class="copyright-cmp" :theme="theme">
      <p class="copyright-item">Copyright©2024  | 粤ICP备号xxxxxx</p>
      <p class="copyright-item">作者：xiaolin</p>
      <p class="copyright-item">邮箱：xiaolin_zii@163.com</p>
    </div>

  </div>
</template>

<script>
// import watermark from '@/assets/js/watermark1.js'
import articleApi from "@/api/article";
import followApi from "@/api/follow";
import collectApi from "@/api/collect";
import likeApi from "@/api/like";
export default {

  data() {
    return{
      likeInfo:{
        type:1,
        targetId: ''
      },
      pageCount:9,
      layout:'total, sizes, prev, pager, next, jumper',
      darkCard:{
        backgroundColor:"rgb(17,17,17)",
      },
      theme: 'light', // 'light' | 'dark
      articleListIsEmpty: false,
      activeName: 'recommend',
      isPC: true,
      isLoading: false,
      currentPage: 1,
      pageSize: 10,
      total:0,
      queryInfo: {
        tagIds:[],
        keyword: '',
        categoryId: 2,
      },
      articleList: [],

      options: [],
    }
  },
  created() {
    this.getAllTags();
    this.initArticleList();
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    }
  },
  mounted() {
    const text = this.$store.state.user.userInfo.account;
    this.$watermark({
      //换行，显示多行水印
      text: text,
      selectors: ".watermarkContent",
      color: "rgba(0, 0, 0, .2)",
      size:30,
    });
    // watermark.set(text, '编程社区')
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    // watermark.close();
  },
  methods: {
    toLikeArticle(article) {
      this.likeInfo.targetId=article.id;
      likeApi.likeOrUnLike(this.likeInfo).then(res => {
        article.likeCount = res.data.data.num;
        article.likeStatus = res.data.data.status;
      }).catch(() => {
      });
    },
    toUserFan(id) {
      if (id == this.userInfo.id)
        this.$router.push(`/self/followed`)
      else
        this.$router.push(`/user/${id}/followed`)
    },
    toUserFollow(id) {
      if (id == this.userInfo.id)
        this.$router.push(`/self/follow`)
      else
        this.$router.push(`/user/${id}/follow`)
    },
    toCollect(article) {
      collectApi.collectOrUnCollect(article.id).then(res => {
        article.collectCount = res.data.data.num;
        article.collectStatus = res.data.data.status;
        console.log(article.collectStatus);
      }).catch(() => {
      });
    },
    followUser(user) {
      followApi.followOrUnFollow(user.id).then(res => {
        user.followStatus = res.data.data.status;
        user.fansCount = res.data.data.num;
      }).catch(() => {
      });
    },
    toMyHome() {
      this.$router.push(`/user/${this.userInfo.id}/info`)
    },
    handleResize() {
      if (window.innerWidth < 700) {
        this.pageCount = 5;
        this.layout = 'total,prev, pager, next';
      }else {
        this.pageCount = 9;
        this.layout = 'total, sizes, prev, pager, next, jumper';
      }
    },
    showUser(id){
      //跳转到该用户信息页
      this.$router.push(`/user/${id}/info`)
    },
    queryArticleList(){
      this.initArticleList();
    },
    getAllTags() {
      articleApi.getAllTag().then(response => {
        this.options = response.data.data.list;
      })
    },
    queryByTag(val){
      //val是一个数组
      this.queryInfo.tagIds = val
    },
    handleCurrentChange(val){
      this.currentPage = val;
      this.initArticleList();
    },
    handleSizeChange(val){
      this.pageSize = val;
      this.initArticleList();
    },
    initArticleList(){
      this.isLoading=true;
      this.articleList = [];
      articleApi.searchArticle(this.queryInfo,this.currentPage,this.pageSize).then(res=>{
        this.articleList = res.data.data.articleVOList;
        this.total = res.data.data.total;
        if(this.articleList.length === 0){
          this.articleListIsEmpty = true;
        }else{
          this.articleListIsEmpty = false;
        }
        this.isLoading = false;
      })
    },
    handleClick(tab, event) {
      console.log(tab.name);
      this.queryInfo.sort = tab.name === 'recommend' ? 0 : tab.name === 'new' ? 1 : 2;
      this.initArticleList();
    },
    toArticleDetail(id){
      console.log(id)
      this.$router.push(`/square/post/${id}`);
    }
  },
}
</script>
<style lang="scss">
.el-popover{
  text-align: start;
}
.popperClass.el-popover{
  background-color: rgb(17, 17, 17) !important;
  color: #ffffff !important;
  .popper__arrow::after{
    border-bottom-color: rgb(17, 17, 17) !important;
  }
}
.darkResource{
  .el-pagination__jump{
    color: white;
  }
  .el-pagination__total{
    color: white;
  }
}
.darkForm{
  .el-select-dropdown{
    background-color: rgb(17, 17, 17);
    color: white;
    border: rgb(62, 62, 62) solid 1px !important;
    .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
      background-color: #1589fa !important;
    }
    .el-select-dropdown__item{
      color: white;
    }
    .popper__arrow{
      border-bottom-color: rgb(62, 62, 62);
    }
    .popper__arrow::after {
      border-bottom-color:rgb(17,17,17) !important;
      border-top-color: rgb(17,17,17) !important;
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hoverSpan:hover{
  color: #1589fa;
}
.articlAuthorName{
  cursor: pointer;

}
.articlAuthorName:hover{
  color: #1589fa;

}
.copyright-cmp[theme='light']{
  text-align: center;
  color: rgba(0, 0, 0, .45);
  font-size: 14px;
}
.copyright-cmp[theme='dark']{
  text-align: center;
  color: rgba(255,255,255,.45);
  font-size: 14px;
}
.darkCard{
  border: rgb(62, 62, 62) solid 1px !important;
  color: #ffffff;
  background-color: rgb(17,17,17);
}

.darkCard >>> .el-card__header {
  border-bottom: rgb(62, 62, 62) solid 1px !important;
}

.darkInput /deep/ .el-input__inner {
  background-color: black;
  border: rgb(62, 62, 62) solid 1px !important;
  color: white;
}
.suxian[theme='light']{
  margin: 0 6px;
  color: rgba(0,0,0,.1);

}
.suxian[theme='dark']{
  margin: 0 6px;
  color: rgba(255,255,255,.1);

}
.tubiao[theme='light'] {
  color: rgba(3, 3, 3);
  font-size: 14px;
  cursor: pointer;
}

.tubiao[theme='dark'] {
  color: rgba(222, 222, 222);
  font-size: 14px;
  cursor: pointer;
}
.darkCount{
  color: #ffffff;
}

.darkForm>>> .el-form-item__label{
  color: #fff;
}

.articleTime[theme='light']{
  color: rgba(0,0,0,.45);font-size: 14px
}
.articleTime[theme='dark']{
  color: rgba(255,255,255,.45);font-size: 14px
}

.lightForm{
  background-color: #fff;
  padding: 12px 0 0 12px;
  border-radius: 5px;
  border: 1px solid #EBEEF5;
}

.darkForm{
  background-color:rgb(17,17,17);
  padding: 12px 0 0 12px;
  border-radius: 5px;
  border: 1px solid rgb(62, 62, 62);
}

.passageOrder>>>.el-tabs {
  background-color: #fff !important;
  border-bottom: 0px !important;
  padding: 0 0 0 20px;
}
.passageOrder>>>.el-tabs .el-tabs__header{
  margin-bottom: -1px;
}
.darkTab>>>.el-tabs {
  background-color: rgb(17,17,17) !important;
  border-bottom: 0px !important;
  padding: 0 0 0 20px;
  border: 1px solid rgb(62, 62, 62);
}

.darkTab>>> .el-tabs__header .el-tabs__item {
  color: #ffffff;
}

.article-list-item-content-text[theme='light'] {
  color: rgba(0, 0, 0, .45);
  font-size: 15px;
  line-height: 1.75;
}

.article-list-item-content-text[theme='dark'] {
  color: #ffffff;
  font-size: 15px;
  line-height: 1.75;
}

.article-list-item-content-title,.article-list-item-content-text{
  cursor: pointer;
}
.article-list-item-content-title:hover{
  color: #3a8ee6;
}
.author-message {
  display: flex;
}

.author-message-right {
  line-height: 35px;
}

.author-Title {
  margin-left: 8px;
}

.article-list-item-content-text {
  color: rgba(0, 0, 0, .45);
  font-size: 15px;
  line-height: 1.75;
}

.article-list-item-content-container[theme='light']{
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, .85);
  align-items: normal;
}

.article-list-item-content-container[theme='dark']{
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  color: #ffffff;
  align-items: normal;
}
.article-list-item-content{

}
.article-list-item-img{
  margin-left: 40px;
  max-width: 300px;
}

.article-tags{
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .index-left {
    margin-right: 0px;
  }

  .article-list-item-content-container{
    /*display: block;*/
    flex-direction: column;
  }
  .article-list-item-img{
    margin: 10px auto;
    float: top;
  }
  .index-right {
    display: none;
  }
}
</style>
