import request from '@/utils/request'

// 查询用户得分管理列表
export function listScore(query) {
  return request({
    url: `/system/score/list/${query.pageNum}/${query.pageSize}`,
    method: 'post',
    data: {
      type: query.type,
      userId: query.userId,
      isDeleted: query.isDeleted,
    },

  })
}

// 查询用户得分管理详细
export function getScore(id) {
  return request({
    url: '/system/score/' + id,
    method: 'get'
  })
}

// 新增用户得分管理
export function addScore(data) {
  return request({
    url: '/system/score',
    method: 'post',
    data: data
  })
}

// 修改用户得分管理
export function updateScore(data) {
  return request({
    url: '/system/score',
    method: 'put',
    data: data
  })
}

// 删除用户得分管理
export function delScore(id) {
  return request({
    url: '/system/score/' + id,
    method: 'delete'
  })
}
