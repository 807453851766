import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en'
import router from "@/router";
import store from "@/store"; // lang i18n


// 引入水印文件地址
import {createWatemark} from '@/assets/js/watermark.js'
Vue.prototype.$watermark = createWatemark

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
// highlightjs
import hljs from 'highlight.js';
VMdEditor.use(githubTheme, {
    Hljs: hljs,
});
Vue.use(VMdEditor);



Vue.use(ElementUI)


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
},).$mount('#app')
