<template>
  <div class="slef-score">
    <div>
      <el-card shadow="never" :body-style="theme=='dark'?darkCard:''"
               :class="{darkCard:theme=='dark'}">

        <el-card shadow="never" v-for="item in list" :key="item.id" style="margin-bottom: 10px" :body-style="theme=='dark'?darkCard:''"
                 :class="{darkCard:theme=='dark'}">
          <div style="display: flex;justify-content: space-between">
            <!--得分来源，分数，时间-->
            <el-tag size="mini">{{ item.type == 1 ? '打卡' : '发布文章' }}</el-tag>
            <div  style="font-size: 14px;color: red"><span v-if="item.score>0">+</span>
              <span>{{ item.score }}</span></div>
            <span style="font-size: 14px" :class="{darkSpan:theme=='dark'}">{{ item.createTime }}</span>
          </div>
        </el-card>
        <el-pagination
            :hide-on-single-page="true"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total">
        </el-pagination>
      </el-card>
      <el-card shadow="never" v-if="isLoading" :body-style="theme=='dark'?darkCard:''"
               :class="{darkCard:theme=='dark'}">
        <el-skeleton :rows="4" animated/>
      </el-card>
      <el-card shadow="never" v-if="ListIsEmpty" :body-style="theme=='dark'?darkCard:''"
               :class="{darkCard:theme=='dark'}">
        <el-empty description="暂无数据"></el-empty>
      </el-card>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'

export default {
  data() {
    return {
      ListIsEmpty: false,
      isLoading: false,
      darkCard: {
        backgroundColor: "rgb(17,17,17)",
      },
      theme: 'light',
      list: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    }
  },
  created() {
    this.getScoreList();
  },
  mounted() {

  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getScoreList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getScoreList();
    },
    getScoreList() {
      this.isLoading = true;
      userApi.getUserScoreList(this.currentPage, this.pageSize).then(res => {
        this.list = res.data.data.list;
        this.ListIsEmpty = this.list.length === 0;
        this.total = res.data.data.total;
        this.isLoading = false;
      })
    }
  },
  beforeDestroy() {

  },
}
</script>
<style scoped>
.darkCard >>> .el-card__header {
  border-bottom: rgb(62, 62, 62) solid 1px !important;
}
.darkSpan{
  color: white !important;
}
</style>


