<template>
  <div class="self-info" :theme="theme">
    <div>
      <el-descriptions :column="1" size="small" :colon="false" :class="{darkDes:theme=='dark'}">
        <template slot="title">
          <span style="font-size: 14px">基本信息</span>
        </template>
        <el-descriptions-item labelStyle="width:100px;margin-bottom:5px" label="性别">
          {{ userInfo.sex == 1 ? '男' : '女' }}
        </el-descriptions-item>
        <el-descriptions-item labelStyle="width:100px;margin-bottom:5px" label="简介">{{
            userInfo.signature
          }}
        </el-descriptions-item>
        <el-descriptions-item labelStyle="width:100px;margin-bottom:5px" label="地区">{{
            userInfo.place
          }}
        </el-descriptions-item>
        <el-descriptions-item labelStyle="width:100px;margin-bottom:5px" label="生日">{{
            userInfo.birthday
          }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <el-divider></el-divider>
    <div>
      <el-descriptions :column="1" size="small" :colon="false" :class="{darkDes:theme=='dark'}">
        <template slot="title">
          <span style="font-size: 14px">学习信息</span>
        </template>
        <el-descriptions-item labelStyle="width:100px;margin-bottom:5px" label="学校">{{
            userInfo.school
          }}
        </el-descriptions-item>
        <el-descriptions-item labelStyle="width:100px;margin-bottom:5px" label="专业">{{
            userInfo.major
          }}
        </el-descriptions-item>
        <el-descriptions-item labelStyle="width:100px;margin-bottom:5px" label="方向">{{
            userInfo.direction
          }}
        </el-descriptions-item>
        <el-descriptions-item labelStyle="width:100px;margin-bottom:5px" label="状态">{{
            jobStatusText
          }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <el-divider></el-divider>
    <div>
      <el-descriptions :column="1" size="small" :colon="false" :class="{darkDes:theme=='dark'}">
        <template slot="title">
          <span style="font-size: 14px">其他信息</span>
        </template>

        <el-descriptions-item labelStyle="width:100px;margin-bottom:5px" label="用户id">{{
            userInfo.id
          }}
        </el-descriptions-item>
        <el-descriptions-item labelStyle="width:100px;margin-bottom:5px" label="注册时间">{{
            userInfo.createTime
          }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <el-divider></el-divider>
    <!--绑定邮箱弹出框-->
    <el-dialog title="绑定邮箱" :visible.sync="emailDialogVisible" width="350px" :custom-class="theme=='dark'?'darkDialog':'nodark'">
      <div class="emailInput">
        <el-input v-model="bindEmail" placeholder="输入邮箱地址">
          <el-button slot="append" style="color: #1589fa;font-weight:bold;border: none" @click="getEmailCode()">{{ getCodeText }}</el-button>
        </el-input>
      </div>
      <div style="margin-top: 10px" class="emailInput">
        <el-input v-model="code" placeholder="验证码"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="emailDialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="toBindEmail()" size="mini">确 定</el-button>
      </span>
    </el-dialog>

    <!--绑定手机弹出框-->
    <el-dialog title="绑定手机" :visible.sync="phoneDialogVisible" width="350px" :custom-class="theme=='dark'?'darkDialog':'nodark'">
      <div class="emailInput">
        <el-input v-model="bindPhone" placeholder="输入手机号码">
          <el-button slot="append" style="color: #1589fa;font-weight:bold;border: none" @click="getPhoneCode()">{{ getCodeText }}</el-button>
        </el-input>
      </div>
      <div style="margin-top: 10px" class="emailInput">
        <el-input v-model="bindPhoneCode" placeholder="验证码"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="phoneDialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="toBindPhone()" size="mini">确 定</el-button>
      </span>
    </el-dialog>
    <!--修改密码弹出框-->
    <el-dialog title="修改密码" :visible.sync="changPassWordDialog" width="350px" :custom-class="theme=='dark'?'darkDialog':'nodark'">
      <el-form label-width="80px">
        <el-form-item label="原密码">
          <el-input type="password" v-model="oldPassword"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input type="password" v-model="newPassword"></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input type="password" v-model="confirmPassword"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changPassWordDialog = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="toChangePassword()" size="mini">确 定</el-button>
      </span>
    </el-dialog>


  </div>

</template>
<script>
import userApi from '@/api/user'
import CryptoJS from 'crypto-js';
const secretKey = 'QAPlatform-2024';
export default {
  data() {
    return {
      theme:'light',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      changPassWordDialog:false,
      phoneDialogVisible: false,
      bindPhone: '',
      bindPhoneCode: '',
      timer: null,
      countDownSecond: 60,
      getCodeText: '获取验证码',
      bindEmail: '',
      code: '',
      emailDialogVisible: false,
      jobStatusMap: {
        0: '在校',
        1: '求职中',
        2: '实习中',
        3: '已工作'
      },

    }
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.otherUserInfo;
    },
    jobStatusText() {
      return this.jobStatusMap[this.userInfo.jobStatus];
    }
  },
  created() {

  },
  mounted() {

  },

  methods: {
    toChangePassword(){
      if (!this.oldPassword) {
        this.$message.error('请输入原密码')
        return
      }
      if (!this.newPassword) {
        this.$message.error('请输入新密码')
        return
      }
      if (!this.confirmPassword) {
        this.$message.error('请输入确认密码')
        return
      }
      if (this.newPassword != this.confirmPassword) {
        this.$message.error('两次密码不一致')
        return
      }
      userApi.updatePassword(this.oldPassword, this.newPassword).then(res => {
        if (res.data.success) {
          // this.$message.success(res.data.message)
          this.changPassWordDialog = false34
          //退出登录
          this.$router.replace("/login");
          this.$store.dispatch("user/LOGOUT");
          this.$message.info('账号信息已过期，请重新登录')
        }else {
          this.$message.error(res.data.message)
        }
      })
    },
    toBindPhone(){
      if (!this.bindPhone) {
        this.$message.error('请输入手机号码')
        return
      }
      //手机号格式校验
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.bindPhone)) {
        this.$message.error('手机号格式不正确')
        return
      }
      if (!this.bindPhoneCode) {
        this.$message.error('请输入验证码')
        return
      }
      userApi.bindPhone(this.bindPhone, this.bindPhoneCode).then(res => {
        if (res.data.success) {
          this.$message.success(res.data.message)
          //绑定成功后，更新用户信息
          const encryptedUserInfo = window.localStorage.getItem('userInfo');
          if (encryptedUserInfo) {
            const bytes = CryptoJS.AES.decrypt(encryptedUserInfo, secretKey);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8) || '{}' );
            decryptedData.phone = this.bindPhone;
            const userInfoString = JSON.stringify(decryptedData)
            const encryptedData = CryptoJS.AES.encrypt(userInfoString, secretKey).toString();
            window.localStorage.setItem('userInfo', encryptedData)
          }
          this.$store.state.user.userInfo.phone = this.bindPhone
          this.phoneDialogVisible = false
        }else {
          this.$message.error(res.data.message)
        }
      })

    },
    toBindEmail() {
      if (!this.bindEmail) {
        this.$message.error('请输入邮箱地址')
        return
      }
      //邮箱格式校验
      const  reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;

      if (!reg.test(this.bindEmail)) {
        this.$message.error('邮箱格式不正确')
        return
      }
      if (!this.code) {
        this.$message.error('请输入验证码')
        return
      }
      userApi.bindEmail(this.bindEmail, this.code).then(res => {
        if (res.data.success) {
          this.$message.success(res.data.message)
          //绑定成功后，更新用户信息
          const encryptedUserInfo = window.localStorage.getItem('userInfo');
          if (encryptedUserInfo) {
            const bytes = CryptoJS.AES.decrypt(encryptedUserInfo, secretKey);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8) || '{}' );
            decryptedData.email = this.bindEmail;
            const userInfoString = JSON.stringify(decryptedData)
            const encryptedData = CryptoJS.AES.encrypt(userInfoString, secretKey).toString();
            window.localStorage.setItem('userInfo', encryptedData)
          }
          this.$store.state.user.userInfo.email = this.bindEmail
          this.emailDialogVisible = false
        }else {
          this.$message.error(res.data.message)
        }
      })
    },
    startCountDown(){
      this.countDownSecond = 60;
      this.timer = setInterval(() => {
        this.countDownSecond--;
        this.getCodeText = `重新发送(${this.countDownSecond})`
        if (this.countDownSecond == 0) {
          clearInterval(this.timer);
          this.getCodeText = '获取验证码'
        }
      }, 1000);
    },
    getPhoneCode() {
      if (this.bindPhone == this.userInfo.phone) {
        this.$message.error('您已绑定该手机号')
        return
      }
      //校验手机号
      if (!this.bindPhone) {
        this.$message.error('请输入手机号码')
        return
      }
      //手机号格式校验
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.bindPhone)) {
        this.$message.error('手机号格式不正确')
        return
      }
      this.startCountDown();
      userApi.sendPhoneCode(this.bindPhone).then(res => {
        if (res.data.success) {
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getEmailCode() {
      if (this.bindEmail == this.userInfo.email) {
        this.$message.error('您已绑定该邮箱')
        return
      }
      //校验邮箱
      if (!this.bindEmail) {
        this.$message.error('请输入邮箱地址')
        return
      }
      //邮箱格式校验
     const  reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!reg.test(this.bindEmail)) {
        this.$message.error('邮箱格式不正确')
        return
      }
      this.startCountDown();

      userApi.sendCode(this.bindEmail).then(res => {
        if (res.data.success) {
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    toUpdate() {
      this.$router.push('/self/edit')
    }
  }
}
</script>
<style scoped>

.darkDes>>>.el-descriptions__title{
  color: white;
}

.darkDes>>>.el-descriptions__body{
  background-color: rgb(17,17,17);
  color: white;
}

.emailInput>>> .el-input .el-input__inner {
  border: 0;
  background-color: #F5F7FA;
}

.emailInput>>>.el-input-group__append {
  border: 0;
}

.self-info[theme='light'] {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.self-info[theme='dark'] {
  width: 100%;
  background-color: rgb(17,17,17);
  padding: 20px;
  box-sizing: border-box;
}

.dialog-with-radius {
  border-radius: 10px;
}


</style>
