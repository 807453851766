<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="帖子id" prop="articleId">
        <el-input
          v-model="queryParams.articleId"
          placeholder="请输入帖子id"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="用户id" prop="userId">
        <el-input
          v-model="queryParams.userId"
          placeholder="请输入用户id"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="逻辑删除" prop="isDeleted">
        <el-select v-model="queryParams.isDeleted" placeholder="请选择逻辑删除" clearable>
          <el-option
            v-for="dict in sys_logic_deleted"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="父级id" prop="parentId">
        <el-input
          v-model="queryParams.parentId"
          placeholder="请输入父级id"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </el-col>

    </el-row>

    <el-table v-loading="loading" :data="commentList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键id" align="center" prop="id" />
      <el-table-column label="帖子id" align="center" prop="articleId" />
      <el-table-column label="用户id" align="center" prop="userId" />
<!--      <el-table-column label="评论内容" align="center" prop="content" >-->
<!--        <template slot-scope="scope">-->
<!--          <p v-html="scope.row.content"></p>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="逻辑删除" align="center" prop="isDeleted">
        <template slot-scope="scope">
          <el-tag>
            {{scope.row.isDeleted==0?'正常':'删除'}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="点赞数" align="center" prop="likeCount" />
      <el-table-column label="回复数" align="center" prop="replyCount" />
      <el-table-column label="父级id" align="center" prop="parentId" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        v-if="commentList.length"
        :small="true"
        :pager-count="pageCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParams.pageSize"
        :layout="layout"
        :total="total">
    </el-pagination>

    <!-- 添加或修改用户评论管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="帖子id" prop="articleId">
          <el-input v-model="form.articleId" placeholder="请输入帖子id" />
        </el-form-item>
        <el-form-item label="用户id" prop="userId">
          <el-input v-model="form.userId" placeholder="请输入用户id" />
        </el-form-item>
        <el-form-item label="评论内容">
          <div style="border: 1px solid #ccc; width: calc(100% - 50px)">
            <!-- 工具栏 -->
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <!-- 编辑器 -->
            <Editor
                style="height: 300px; overflow-y: hidden;"
                :defaultConfig="editorConfig"
                v-model="form.content"
                @onCreated="onCreated"
                :mode="mode"
                @onChange="onChange"
            />
          </div>
        </el-form-item>
        <el-form-item label="逻辑删除" prop="isDeleted">
          <el-select v-model="form.isDeleted" placeholder="请选择逻辑删除">
            <el-option
              v-for="dict in sys_logic_deleted"
              :key="dict.value"
              :label="dict.label"
              :value="parseInt(dict.value)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="点赞数" prop="likeCount">
          <el-input v-model="form.likeCount" placeholder="请输入点赞数" />
        </el-form-item>
        <el-form-item label="回复数" prop="replyCount">
          <el-input v-model="form.replyCount" placeholder="请输入回复数" />
        </el-form-item>
        <el-form-item label="父级id" prop="parentId">
          <el-input v-model="form.parentId" placeholder="请输入父级id" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listComment, getComment, delComment, addComment, updateComment } from "@/api/admin/comment";
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css'
export default {
  components:{
    Editor,
    Toolbar
  },
  data() {
    return {
      mode: 'default', // or 'simple'
      editor: null,
      toolbarConfig: {
        excludeKeys: [
          'headerSelect',
          'blockquote',
          'group-more-style',
          'color',
          'bgColor',
          'fontSize',
          'lineHeight',
          'todo',
          'group-indent',
          'group-video',
          'group-justify',
          'insertTable',
          'divider',

        ],
      },
      editorConfig: {
        placeholder: '欢迎发表你的想法',
        MENU_CONF: {
          uploadImage: {
            server: 'https://47.120.61.197/oss/uploadOssFile',
            fieldName: "file",
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 5 * 1024 * 1024,
            // 最大上传图片数量
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],
            customInsert(res, insertFn) {                  // JS 语法
              // res 即服务端的返回结果
              const url = res.data.url
              const alt = res.data.url
              const href = url
              // 从 res 中找到 url alt href ，然后插入图片
              insertFn(url, alt, href)
            },
          }
        },
      },
      pageCount:9,
      token: this.$store.state.user.token,
      layout:'total, sizes, prev, pager, next, jumper',
      sys_logic_deleted: [
        { label: "正常", value: 0 },
        { label: "删除", value: 1 }
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 用户评论管理表格数据
      commentList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        articleId: null,
        userId: null,
        content: null,
        isDeleted: null,
        parentId: null
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        articleId: [
          { required: true, message: "帖子id不能为空", trigger: "blur" }
        ],
        userId: [
          { required: true, message: "用户id不能为空", trigger: "blur" }
        ],
        isDeleted: [
          { required: true, message: "逻辑删除 1不能为空", trigger: "change" }
        ],
      }
    };
  },
  created() {
    this.getList();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    onChange(editor) {
      const html = editor.getHtml()
      this.form.content = html
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
    },
    handleResize() {
      if (window.innerWidth < 700) {
        this.pageCount = 5;
        this.layout = 'total,prev, pager, next';
      }else {
        this.pageCount = 9;
        this.layout = 'total, sizes, prev, pager, next, jumper';
      }
    },
    handleCurrentChange(val){
      this.queryParams.pageNum = val;
      this.getList();
    },
    handleSizeChange(val){
      this.queryParams.pageSize = val;
      this.getList();
    },
    /** 查询用户评论管理列表 */
    getList() {
      this.loading = true;
      listComment(this.queryParams).then(response => {
        this.commentList = response.data.data.list;
        this.total = response.data.data.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        articleId: null,
        userId: null,
        content: null,
        createTime: null,
        updateTime: null,
        isDeleted: null,
        likeCount: null,
        replyCount: null,
        parentId: null
      };
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加用户评论管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getComment(id).then(response => {
        this.form = response.data.data.comment;
        this.open = true;
        this.title = "修改用户评论管理";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateComment(this.form).then(response => {
              this.$message.success("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addComment(this.form).then(response => {
              this.$message.success("添加成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除用户评论管理编号为"' + ids + '"的数据项？').then(function() {
        return delComment(ids);
      }).then(() => {
        this.getList();
        this.$message.success("删除成功");
      }).catch(() => {});
    },

  }
};
</script>
