import request from '@/utils/request'

// 查询用户待办事项管理列表
export function listTodo(query) {
  return request({
    url: `/system/todo/list/${query.pageNum}/${query.pageSize}`,
    method: 'post',
    data: {
      uid: query.uid,
      deadline: query.deadline,
      priority: query.priority,
      status: query.status,
      content: query.content,
      isDeleted: query.isDeleted,
    },
  })
}

// 查询用户待办事项管理详细
export function getTodo(id) {
  return request({
    url: '/system/todo/' + id,
    method: 'get'
  })
}

// 新增用户待办事项管理
export function addTodo(data) {
  return request({
    url: '/system/todo',
    method: 'post',
    data: data
  })
}

// 修改用户待办事项管理
export function updateTodo(data) {
  return request({
    url: '/system/todo',
    method: 'put',
    data: data
  })
}

// 删除用户待办事项管理
export function delTodo(id) {
  return request({
    url: '/system/todo/' + id,
    method: 'delete'
  })
}
