import request from '@/utils/request'

// 查询用户会话管理列表
export function listSession(query) {
  return request({
    url: `/system/session/list/${query.pageNum}/${query.pageSize}`,
    method: 'post',
    data: {
      acceptId: query.acceptId,
      lastMess: query.lastMess,
      lastTime: query.lastTime,
      isShow: query.isShow,
      userId: query.userId,
      isDeleted: query.isDeleted,
      params: query.params
    },

  })
}

// 查询用户会话管理详细
export function getSession(id) {
  return request({
    url: '/system/session/' + id,
    method: 'get'
  })
}

// 新增用户会话管理
export function addSession(data) {
  return request({
    url: '/system/session',
    method: 'post',
    data: data
  })
}

// 修改用户会话管理
export function updateSession(data) {
  return request({
    url: '/system/session',
    method: 'put',
    data: data
  })
}

// 删除用户会话管理
export function delSession(id) {
  return request({
    url: '/system/session/' + id,
    method: 'delete'
  })
}
