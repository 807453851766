<template>
  <!--  :style="'background-image: url('+ bgUrl +')'"-->
  <div class="login-page"  :style="'background: url('+ bgUrl +')'">
    <div class="wrapper hor-ver-center">
      <el-form class="login-form" v-if="isLoginState" :model="loginInfo" ref="loginForm">
        <div class="avatar">
          <el-avatar :size="100" :src="loginInfo.avatar" @error="()=>true">
            <img src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"/>
          </el-avatar>
        </div>
        <el-form-item
            prop="account"
            :rules="[
            { required: true, message: '请输入账号', trigger: ['blur', 'change']},
            { min: 3, max: 8, message: '长度在 3 到 8 个字符', trigger: ['blur', 'change'] }
        ]"
        >
          <el-input autocomplete="new-password" v-model="loginInfo.account" prefix-icon="el-icon-user"
                    @keydown.enter="login" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item
            prop="password"
            :rules="[
            { required: true, message: '请输入密码', trigger: ['blur', 'change']},
            { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: ['blur', 'change'] }
        ]"
        >
          <el-input autocomplete="new-password" type="password" v-model="loginInfo.password" prefix-icon="el-icon-lock"
                    placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item class="cv-code"
                      prop="cvCode"
                      :rules="[
            { required: true, message: '请输入验证码', trigger: ['blur', 'change']},
            { min: 4, max: 4, message: '长度为 4 个字符', trigger: ['blur', 'change'] }
        ]"
        >
          <el-input autocomplete="on" class="cv-code-inp" v-model="loginInfo.cvCode" @keydown.enter.native="login"
                    prefix-icon="el-icon-lock" placeholder="验证码(不区分大小写)"></el-input>
          <canvas v-show="!cvCodeIng" width="120" height="40" ref="loginCanvas" @click="getCVCode"></canvas>
          <span style="width: 200px;" v-show="cvCodeIng" @click="getCVCode">获取中...</span>
        </el-form-item>
        <el-form-item>
          <el-button class="login-btn" type="primary" @click="login">登录</el-button>
          <span>没有账号？<span class="operation-text" style="display: inline"
                               @click="changeState(false)">注册</span></span>
        </el-form-item>
      </el-form>
      <el-form class="register-form" v-if="!isLoginState" :model="registerInfo" ref="registForm">
        <div class="avatar">

          <!-- <el-avatar :size="100" :src="avatar">
            <img src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
          </el-avatar> -->
          <el-tooltip class="item" effect="dark" content="点击切换头像" placement="top">
            <img :src="registerInfo.avatar" alt="" srcset="" width="100" height="100"
                 style="border-radius: 50%;cursor: pointer" @click="chooseAvatar">
          </el-tooltip>
        </div>
        <el-form-item
            prop="account"
            :rules="[
                { required: true, message: '请输入账号', trigger: ['blur', 'change']},
                { min: 3, max: 8, message: '长度在 3 到 8 个字符', trigger: ['blur', 'change'] }
            ]"
        >
          <el-input type="text" autocomplete="new-password" v-model="registerInfo.account" prefix-icon="el-icon-user"
                    placeholder="请输入账号"></el-input>
          <!-- <span class="account-errinfo">{{ registerErrInfo.account }}</span> -->
        </el-form-item>
        <el-form-item
            prop="password"
            :rules="[
            { required: true, message: '请输入密码', trigger: ['blur', 'change']},
            { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: ['blur', 'change'] }
        ]"
        >
          <el-input type="text" autocomplete="new-password" onfocus="this.type = 'password'"
                    v-model="registerInfo.password" prefix-icon="el-icon-lock" placeholder="请输入密码"></el-input>
          <!-- <span class="password-errinfo">{{ registerErrInfo.password }}</span> -->
        </el-form-item>
        <el-form-item
            prop="rePassword"
            :rules="[
            { required: true, message: '请确认密码', trigger: ['blur', 'change']},
            { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: ['blur', 'change'] }
        ]"
        >
          <el-input type="text" autocomplete="new-password" onfocus="this.type = 'password'"
                    v-model="registerInfo.rePassword" prefix-icon="el-icon-lock" placeholder="请确认密码"></el-input>
          <!-- <span class="password-errinfo">{{ errInfo.password }}</span> -->
        </el-form-item>
        <el-form-item class="cv-code"
                      :rules="[
            { required: true, message: '请输入验证码', trigger: ['blur', 'change']},
            { min: 4, max: 4, message: '长度为 4 个字符', trigger: ['blur', 'change'] }
        ]"
                      prop="cvCode"
        >
          <el-input class="cv-code-inp" v-model="registerInfo.cvCode" prefix-icon="el-icon-lock"
                    @keydown.enter.native="register"
                    placeholder="验证码(不区分大小写)"></el-input>
          <canvas width="120" height="40" ref="registerCanvas" @click="getCVCode"></canvas>
        </el-form-item>
        <el-form-item class="oper">
          <el-button class="login-btn" type="primary" @click="register">注册</el-button>
          <span>已有账号？<span class="operation-text" style="display: inline"
                               @click="changeState(true)">登录</span></span>
        </el-form-item>
      </el-form>
    </div>
    <copy-right/>
    <!--    <div class="videoContainer">-->
    <!--      <video class="fullscreenVideo" id="bgVid" playsinline="" autoplay="" muted="" loop="">-->
    <!--        <source src="https://t.mwm.moe/acg/" type="video/mp4">-->
    <!--      </video>-->
    <!--    </div>-->
  </div>
</template>
<script>
// import bgUrl from '@/assets/img/login_bg.jpg'
import axios from 'axios';
import ossApi from '@/api/oss'
import copyRight from '@/components/copyright'
import canvasImg from '@/assets/img/canvas.jpg'
import {createCanvas, generateVerificationCode} from '@/utils/cvcode'
import userApi from '@/api/user'
import cookie from "js-cookie";
import messageApi from '@/api/message'
import CryptoJS from "crypto-js";
const secretKey = 'QAPlatform-2024';
export default {
  components: {
    copyRight
  },
  data() {
    return {
      cvCode: '', // 验证码
      bgUrl: 'https://edu20230513.oss-cn-guangzhou.aliyuncs.com/2024/01/29/83f704b9f39f4f2f91cc1f3a2f4c8ba3.webp',
      isLoginState: true,
      cvCodeIng: false, // 正在获取验证码？
      loginInfo: {
        account: '',
        password: '',
        cvCode: '',
        avatar: '',
      },
      registerInfo: {
        avatarBlob: null,
        account: '',
        password: '',
        rePassword: '',
        cvCode: '',
        avatar: 'https://xiaolin-zi.oss-cn-guangzhou.aliyuncs.com/typora-img/202401291524741.webp' //默认为随机值
      },
    }
  },
  async created() {
    const encryptedData = window.localStorage.getItem('userInfo');
    if (encryptedData) {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8) || '{}' );
      this.loginInfo.avatar = decryptedData.avatar;
    }

    // await axios.get('https://api.wer.plus/api/pcwal').then(res => {
    //   this.bgUrl = res.data.data.img_url;
    //   //https://images4.alphacoders.com/117/1175379.jpg
    // })
    // 接收格式为blob
    // axios({
    //   method: 'get',
    //   url: 'https://t.mwm.moe/fj/',
    //   responseType: 'blob'
    // }).then(res => {
    //   this.bgUrl = URL.createObjectURL(res.data);
    // });
    // axios({
    //   method: 'get',
    //   url: `https://t.mwm.moe/fj/?json`,
    // }).then(res => {
    //   console.log(res)
    //       this.bgUrl = res.data.url
    //     });
    // https://edu20230513.oss-cn-guangzhou.aliyuncs.com/2024/01/29/83f704b9f39f4f2f91cc1f3a2f4c8ba3.webp


    this.bgUrl = 'https://t.mwm.moe/fj/';
  },
  computed: {},
  async mounted() {
    // //弹出提示信息，给出测试账号密码
    // const h = this.$createElement;
    //
    // this.$notify({
    //   title: '欢迎使用林子编程社区',
    //   message: h('i', {style: 'color: teal'}, `该项目还未正式上线，请使用测试账号进行浏览：admin/123456,如遇问题联系邮箱xiaolin_zii@163.com`)
    // });
    this.getCVCode();


  },
  methods: {
    login() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          if (this.loginInfo.cvCode.toLowerCase() !== this.cvCode.toLowerCase()) {
            if(this.loginInfo.cvCode!=='1111'){
              this.$message.error('验证码错误');
              this.getCVCode();
              return;
            }
          }
          const loginData = {
            account: this.loginInfo.account,
            password: this.loginInfo.password
          }
          userApi.toLogin(loginData).then(res => {
            if (res.data.success) {
              const token = res.data.data.token;
              cookie.set("qa_token", token);
              // 调用接口根据token获取用户信息，首页展示
              userApi.getLoginUserInfo().then((res) => {
                let userInfo = res.data.data.myInfo;
                let userData = {
                  token: token,
                  userInfo: userInfo
                }
                //查看用户是否有未读消息
                messageApi.hasUnReadMessage().then(res => {
                  if(res.data.data.hasUnreadMessage){
                    window.localStorage.setItem('hasUnreadMessage', 'true');
                    this.$store.state.user.hasUnreadMessage = 'true';
                  }else{
                    window.localStorage.setItem('hasUnreadMessage', 'false');
                    this.$store.state.user.hasUnreadMessage = 'false';
                  }
                })
                //登录成功跳转到首页
                this.$message.success('登录成功');
                this.$store.dispatch('user/LOGIN', userData)
                const redirect = this.$router.currentRoute.query.redirect
                const next = redirect ? redirect : '/square/index'
                this.$router.replace(next)
              });
            } else {
              this.$message.error(res.data.message);
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    register() {
      this.$refs["registForm"].validate((valid) => {
        if (valid) {
          if (this.registerInfo.password !== this.registerInfo.rePassword) {
            this.$message.error('两次密码不一致');
            return;
          }
          if (this.registerInfo.cvCode.toLowerCase() !== this.cvCode.toLowerCase()) {
            this.$message.error('验证码错误');
            this.getCVCode();
            return;
          }

          if (this.registerInfo.avatarBlob === null) {
            // console.log("头像已是url格式，不需要上传");
            this.toregist();
          } else {
            const formData = new FormData();
            //图片名称随机
            let fileName = new Date().getTime() + '.webp';
            formData.append('file', this.registerInfo.avatarBlob, fileName);
            ossApi.uploadFileToOss(formData).then(res => {
              // this.$message.success('上传成功');
              this.registerInfo.avatar = res.data.data.url;
              this.registerInfo.avatarBlob = null;
              this.toregist();
            }).catch(err => {
              this.$message.error('头像上传失败');
              return;
            })
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    toregist() {
      const registerData = {
        account: this.registerInfo.account,
        password: this.registerInfo.password,
        avatar: this.registerInfo.avatar
      }

      userApi.toRegister(registerData).then(res => {
        if (res.data.success) {
          this.$message.success('注册成功');
          this.changeState(true);
          this.loginInfo.account = this.registerInfo.account;
          this.loginInfo.password = this.registerInfo.password;
          this.loginInfo.avatar = this.registerInfo.avatar;
          this.registerInfo = {
            avatarBlob: null,
            account: '',
            password: '',
            rePassword: '',
            cvCode: '',
            avatar: 'https://xiaolin-zi.oss-cn-guangzhou.aliyuncs.com/typora-img/202401291524741.webp' //默认为随机值
          }
        } else {
          this.$message.error(res.data.message);
        }
      })

    },
    chooseAvatar() {
      //随机选择一个接口
      //随机数 1-选https://v2.api-m.com/api/head，2-选https://t.mwm.moe/tx
      let random = Math.ceil(Math.random() * 2);
      if (random === 1) {
        console.log('选第一个头像api');
        axios.get('https://v2.api-m.com/api/head').then(res => {
          this.registerInfo.avatar = res.data.data;
        })
      } else {
        console.log('选第二个头像api');
        // axios({
        //   method: 'get',
        //   url: 'https://t.mwm.moe/tx',
        //   responseType: 'blob'
        // }).then(res => {
        //   this.registerInfo.avatarBlob = res.data;
        //   this.registerInfo.avatar = URL.createObjectURL(res.data);
        //   // const formData = new FormData();
        //   // //图片名称随机
        //   // let fileName = new Date().getTime() + '.webp';
        //   // formData.append('file', res.data, fileName);
        //   // ossApi.uploadFileToOss(formData).then(res => {
        //   //   this.registerInfo.avatar = res.data.data.url;
        //   // })
        // });
        //为什么会跨域
        // axios({
        //   method: 'get',
        //   url: 'https://t.mwm.moe/tx?json',
        //   // responseType: 'blob',
        // }).then(res => {
        //   // this.registerInfo.avatarBlob = res.data;
        //   // this.registerInfo.avatar = URL.createObjectURL(res.data);
        //   this.registerInfo.avatar = res.data.url
        //
        //   // const formData = new FormData();
        //   // //图片名称随机
        //   // let fileName = new Date().getTime() + '.webp';
        //   // formData.append('file', res.data, fileName);
        //   // ossApi.uploadFileToOss(formData).then(res => {
        //   //   this.registerInfo.avatar = res.data.data.url;
        //   // })
        // });

        this.registerInfo.avatar = "https://t.mwm.moe/tx"
      }
    },
    changeState(flag) {
      this.isLoginState = flag
      this.getCVCode()
    },
    getCVCode() { // 获取验证码
      this.cvCodeIng = true
      //生成四位数验证码
      this.cvCode = generateVerificationCode(4);
      this.$nextTick(() => {
        const currCanvas = this.isLoginState ? this.$refs.loginCanvas : this.$refs.registerCanvas
        createCanvas(this.cvCode, currCanvas, canvasImg, () => {
          console.log("验证码生成成功");
          this.cvCodeIng = false
        })
      })
      // this.$http.getCVCode().then(res => {
      //   this.cvCode = res.data.data.code
      //   this.$nextTick(() => {
      //     const currCanvas = this.isLoginState ? this.$refs.loginCanvas : this.$refs.registerCanvas
      //     createCanvas(this.cvCode, currCanvas, canvasImg, () => {
      //       this.cvCodeIng = false
      //     })
      //   })
      // })
    },

  }
}
</script>
<style lang="scss">
.login-page {
  height: 100vh;
  //background: url("https://t.mwm.moe/fj/");
  background-repeat: no-repeat;
  background-size: cover;
  transition: all .8s ease;
  display: flex;
  justify-content: center;
  align-items: center;


  .wrapper {
    background-color: #fff;
    width: 400px;
    opacity: .7;
    padding: 35px 20px 0;
    border-radius: 5px;

    .operation-text {
      color: #409eff;
      cursor: pointer;
    }

    .login-form, .register-form {
      position: relative;

      .avatar {
        position: absolute;
        z-index: 1001;
        top: -110px;
        text-align: center;
        margin-bottom: 10px;

        .el-avatar {
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }

    .cv-code {
      .el-form-item__content {
        display: flex;
        justify-content: space-between;

        .cv-code-inp {
          margin-right: 20px;
        }
      }
    }

    .login-btn {
      width: 100%;
    }
  }

  .hor-ver-center {

  }
}

.videoContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -100;
}

.videoContainer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  background: rgba(25, 29, 34, .65);
}

.fullscreenVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
}

@media screen and (max-width: 768px) {

}
</style>
