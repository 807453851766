import request from '@/utils/request'

// 查询用户关注管理列表
export function listFollow(query) {
  return request({
    url: `/system/follow/list/${query.pageNum}/${query.pageSize}`,
    method: 'post',
    data: {
      friendId: query.friendId,
      userId: query.userId,
      isDeleted: query.isDeleted,
    }
  })
}

// 查询用户关注管理详细
export function getFollow(id) {
  return request({
    url: '/system/follow/' + id,
    method: 'get'
  })
}

// 新增用户关注管理
export function addFollow(data) {
  return request({
    url: '/system/follow',
    method: 'post',
    data: data
  })
}

// 修改用户关注管理
export function updateFollow(data) {
  return request({
    url: '/system/follow',
    method: 'put',
    data: data
  })
}

// 删除用户关注管理
export function delFollow(id) {
  return request({
    url: '/system/follow/' + id,
    method: 'delete'
  })
}
