<template>
  <div class="test" style="height:100vh;display: flex;">
    <!--侧边栏-->
    <sidebar class="sidebar" :class="{showSideBar:tohide!=true}"></sidebar>
    <div class="self" style="width: 100%;overflow-y:scroll;" :class="{darkBg:theme=='dark',lightBg:theme=='light'}">
      <!--显示头像、昵称、个性签名、关注、粉丝、积分-->
      <div class="self-top" :theme="theme">
        <div class="self-top-left">
          <!--头像-->
          <div>
            <el-image v-if="userInfo.avatar" class="self-top-left-avatar" style="width: 100px; height: 100px;border-radius: 50%" :src="userInfo.avatar"

                       alt="用户头像" :preview-src-list="[userInfo.avatar]">
            </el-image>
          </div>
          <!--账号-->
          <div class="self-top-left-account" :theme="theme">
            <span>{{ userInfo.nickname }}</span>
          </div>
          <!--个人签名-->
          <div class="self-top-left-signature" :theme="theme">
            <span>{{userInfo.signature}}</span>
          </div>
        </div>
        <div class="self-top-right">
          <!--粉丝、关注、积分-->
          <div class="self-baseinfo" :class="{darkInfo:theme=='dark'}">
            <el-row>
              <el-col :span="8">
                <div >
                  <span class="self-top-right-item" @click="handleSelect(`/user/${id}/followed`)">粉丝</span>
                  <p class="userInfoNum" :class="{darkInfo:theme=='dark'}">{{ userInfo.fansCount }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div >
                  <span class="self-top-right-item" @click="handleSelect(`/user/${id}/follow`)">关注</span>
                  <p class="userInfoNum" :class="{darkInfo:theme=='dark'}">{{ userInfo.followCount }}</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <span class="self-top-right-item">积分</span>
                  <p class="userInfoNum" :class="{darkInfo:theme=='dark'}">{{ userInfo.score }}</p>
                </div>
              </el-col>
            </el-row>
          </div>
          <!--积分-->
          <div class="self-small-baseinfo" :class="{darkInfo:theme=='dark'}">
            <div class="self-small-baseinfo-item" @click="handleSelect(`/user/${id}/followed`)" >粉丝：<span class="userInfoNum" :class="{darkInfo:theme=='dark'}">{{ userInfo.fansCount }}</span></div>
            <div class="self-small-baseinfo-item" @click="handleSelect(`/user/${id}/follow`)">关注：<span class="userInfoNum" :class="{darkInfo:theme=='dark'}">{{ userInfo.followCount }}</span></div>
            <div class="self-small-baseinfo-item">积分：<span class="userInfoNum" :class="{darkInfo:theme=='dark'}">{{ userInfo.score }}</span></div>
          </div>
          <!--关注按钮-->
          <el-button type="primary" size="mini" style="margin-top: 20px" v-if="userInfo.followStatus==0" @click="followUser(userInfo)">关注</el-button>
          <el-button type="primary" plain size="mini" style="margin-top: 20px" v-if="userInfo.followStatus==1" @click="followUser(userInfo)">已关注</el-button>

          <!--私信按钮-->
          <!--私信按钮-->
          <el-button plain size="mini" @click="toChat">
            <i class="el-icon-chat-dot-square"></i>私信
          </el-button>
        </div>
      </div>
      <div class="self-bottom">
        <div class="self-menu">
          <el-menu style="" :default-active="$route.path" class="el-menu-demo"  mode="horizontal" @select="handleSelect" :background-color="menuBackgroundColor"
                   :text-color="menuTextColor"
                   :active-text-color="menuActiveTextColor">
            <el-menu-item  :index="`/user/${id}/info`" class="item">
              <span slot="title">资料</span>
            </el-menu-item>
            <el-menu-item  :index="`/user/${id}/post`" class="item">
              <span slot="title">帖子</span>
            </el-menu-item>
            <el-menu-item  :index="`/user/${id}/comment`" class="item">
              <span slot="title">评论</span>
            </el-menu-item>
            <el-menu-item :index="`/user/${id}/favorites`" class="item">
              <span slot="title">收藏</span>
            </el-menu-item>
            <el-menu-item  :index="`/user/${id}/follow`" class="item">
              <span slot="title">关注</span>
            </el-menu-item>
            <el-menu-item  :index="`/user/${id}/followed`" class="item">
            <span slot="title">粉丝</span>
          </el-menu-item>
          </el-menu>
        </div>
        <div class="self-small-menu" >
          <el-menu  :default-active="$route.path" class="el-menu-demo"  mode="horizontal" @select="handleSelect" :background-color="menuBackgroundColor"
                   :text-color="menuTextColor"
                   :active-text-color="menuActiveTextColor">
            <el-menu-item v-for="item in menuItems.slice(0,itemNum)" :key="item.index" :index="`/user/${id}/${item.index}`" class="item">
              <span slot="title">{{item.name}}</span>
            </el-menu-item>
            <el-menu-item>
              <el-dropdown placement="bottom-start" trigger="click">
                <span class="el-dropdown-link">
                  <i class="icon-caidan1 iconfont" style="font-size: 20px"></i>
                </span>
                <el-dropdown-menu slot="dropdown" :class="{dropdownMenu:theme=='dark'}">
                  <el-dropdown-item v-for="item in menuItems.slice(itemNum)" :key="item.index"  @click.native="handleSelect(`/user/${id}/${item.index}`)">{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>
          </el-menu>

        </div>
      </div>
      <router-view :key="$route.fullPath" ></router-view>
      <div class="copyright-cmp" :theme="theme">
        <p class="copyright-item">Copyright©2024  | 粤ICP备号xxxxxx</p>
        <p class="copyright-item">作者：xiaolin</p>
        <p class="copyright-item">邮箱：xiaolin_zii@163.com</p>
      </div>
    </div>
    <div class="backSideBarRight"  @click="openSideBar()">
      <button style="background: none;border: none;z-index: 999">
        <i class="iconfont icon-jiantou_yemian_xiangyou" style="color: deepskyblue" :class="{hide:tohide!=true}"></i>
      </button>
    </div>
    <div class="backSideBarLeft"  @click="openSideBar()" >
      <button style="background: none;border: none;z-index: 999">
        <i class="iconfont icon-jiantou_yemian_xiangzuo" style="color: deepskyblue" :class="{hide:tohide==true}"></i>
      </button>
    </div>
  </div>
</template>
<script>

import cookie from "js-cookie";
import sidebar from "@/components/sidebar";
import userApi from "@/api/user";
import followApi from "@/api/follow";

import sessionApi from "@/api/session";
export default {
  components: {
    sidebar
  },
  data() {
    return {
      id:"",
      userInfo:{},
      theme:'light',
      tohide: false,
      menuItems: [
        { name: '资料', index: 'info' },
        { name: '帖子', index: 'post' },
        { name: '评论', index: 'comment' },
        { name: '收藏', index: 'favorites' },
        { name: '关注', index: 'follow' },
        { name: '粉丝', index: 'followed' }
      ],
      itemNum: 7,
      model: '夜间',
      isNight: false,
      menuBackgroundColor: '',
      menuTextColor:'',
      menuActiveTextColor: '',
    }
  },
  created() {
    //从路由中获取id，查找到对应的用户
    const id = this.$route.params.id;
    this.id = id;
    if(this.$store.state.user.userInfo.id==id){
      //跳转到自己的信息页
      this.$router.replace(`/self/index`);
    }
    //根据id获取该用户信息
    userApi.getUserInfo(id).then(res=>{
      if(res.data.success){
       this.userInfo = res.data.data.userInfo;
       //存到store
        this.$store.state.user.otherUserInfo = res.data.data.userInfo;
      }else{
        //跳转回首页
        this.$message.warning('查询用户信息失败');
        this.$router.push(`/square/index`);
      }
    })
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
        if (val == 'dark') {
          this.menuTextColor = '#fff'
          this.menuBackgroundColor = 'rgb(17,17,17)'
          this.menuActiveTextColor = '#409EFF'
        } else {
          this.menuTextColor = ''
          this.menuBackgroundColor = ''
          this.menuActiveTextColor = ''
        }
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();//先调用一次，防止刷新页面时，窗口宽度小于650px，但是isBigView为true
  },
  computed: {
  },
  methods: {
    toChat(){
      //保存对话id到vuex
      //创建会话
      sessionApi.createSession(this.id).then(res=>{
        if(res.data.success){
          this.$store.state.user.acceptId = this.id;
          this.$router.push({
            path: `/mess/index`,
          });
        }else{
          this.$message.warning('创建会话失败');
        }
      })
    },
    followUser(user) {
      followApi.followOrUnFollow(user.id).then(res => {
        user.followStatus = res.data.data.status;
        user.fansCount = res.data.data.num;
      }).catch(() => {
      });
    },
    openSideBar(){
      this.tohide = !this.tohide;
    },
    handleResize() {
      if (window.innerWidth < 620 && window.innerWidth >= 590) {
        // 窗口宽度小于620px的处理逻辑
        this.itemNum = 6;
      }else if(window.innerWidth < 590 && window.innerWidth >= 520){
        this.itemNum = 5;
      }else if(window.innerWidth < 520 && window.innerWidth >= 450){
        this.itemNum = 4;
      }else if(window.innerWidth < 450 && window.innerWidth >= 370 ){
        this.itemNum = 3;
      }else if (window.innerWidth < 370){
        this.itemNum = 2;
      } else {
        // 窗口宽度大于或等于620px的处理逻辑
        this.itemNum = 7;
      }
    },

    handleSelect(path) {
      console.log(path)
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
<style lang="scss">
.el-popper.dropdownMenu {
  background-color: rgb(17, 17, 17);
  border: 1px solid #fff;

  .el-dropdown-menu__item {
    color: #fff !important;
  }



  .popper__arrow::after {
    border-bottom-color: rgb(17, 17, 17) !important;
    border-top-color: rgb(17, 17, 17) !important;
  }

  .el-dropdown-menu__item:not(.is-disabled):hover {
    background: #40a8ee !important;
  }
}
</style>
<style scoped>
.copyright-cmp[theme='light']{
  text-align: center;
  color: rgba(0, 0, 0, .45);
  font-size: 14px;
}
.copyright-cmp[theme='dark']{
  text-align: center;
  color: rgba(255,255,255,.45);
  font-size: 14px;
}
.darkInfo{
  color: white !important;
}

.darkBg{
  background-color: rgb(34, 34, 34);
}

.lightBg{
  background-color: #f5f5f5;
}
.item{
  margin-right: 5px;
}
a{
  text-decoration: none;
}
/* 改变elementui 侧边栏移入颜色 */
/*.el-menu-item:hover{*/
/*  background: rgb(142, 166, 236) !important;*/
/*}*/

.hide{
  display: none;
}

.self {
  width: 100%;
  height: 100%;
}

.self-top[theme='light'] {
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  /*background: url("https://images4.alphacoders.com/117/1175379.jpg");*/
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.self-top[theme='dark'] {
  width: 100%;
  height: 200px;
  background-color: rgb(17,17,17);
  /*background: url("https://images4.alphacoders.com/117/1175379.jpg");*/
  border-bottom: 1px solid rgb(62,62,62);
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.self-top-left{
  width: 50%;
  margin-left: 50px;
}
.self-top-left-avatar{


}
.self-top-left-account[theme='light']{
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  overflow: hidden;
}

.self-top-left-account[theme='dark']{
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  overflow: hidden;
}

.self-top-left-signature[theme='light']{
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
  color: #000000;
  overflow: hidden;
}

.self-top-left-signature[theme='dark']{
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
  color: white;
  overflow: hidden;
}

.self-top-right{
  width: 40%;
  margin: 0 auto;
}

.userInfoNum{
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.self-top-right-item{
  width: 100px;
}
.self-top-right-item:hover{
  color: rgb(0, 133, 255);
  cursor: pointer;
}

.self-bottom{
  width: 100%;
  background-color: #ffffff;
  margin-top: 20px;
}

.self-small-menu{
  display: none;
}

.self-small-baseinfo{
  display: none;
}

.self-small-baseinfo-item{
  margin-bottom: 5px;
}

@media screen and (max-width: 620px) {
  .self-menu{
    display: none;
  }
  .self-small-menu{
    display: block;
  }
  .self-top-left{
    margin-left: 10px;
  }
  .self-top-left-avatar{
    width: 60px !important;
    height: 60px !important;
  }
  .self-baseinfo{
    display: none;
  }
  .self-small-baseinfo{
    display: block;
    font-size: 12px;
  }
  .self-small-baseinfo-item:hover{
    color: rgb(0, 133, 255);
    cursor: pointer;
  }
  .userInfoNum{
    font-size: 12px;
  }
  .self-top-left-account{
    font-size: 12px;
  }
  .self-top-left-signature{
    font-size: 10px;
  }
}
.hide{
  display: none;
}
.backSideBarRight{
  position: absolute;
  top:300px;
  left: -10px;
  display: none;
  z-index: 999;
}
.backSideBarLeft{
  position: absolute;
  top:300px;
  left: 70px;

  z-index: 999;
  display: none;
}
.showSideBar{
  display: block !important;
}

.hide{
  display: none;
}
@media screen and (max-width: 768px) {

  .sidebar{
    display: none;
    z-index: 99;

  }
  .backSideBarRight{
    display: block;
  }
  .backSideBarLeft{
    display: block;
  }
}
</style>
