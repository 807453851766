import Vue from 'vue'
import VueRouter from 'vue-router'
import defaultLayout from '@/layout/default'
import indexLayout from "@/layout/indexLayout";
import selfLayout from "@/layout/selfLayout";
import userLayout from "@/layout/userLayout";
import store from "@/store";
import {Message} from 'element-ui'
import publish from "@/views/square/publish";
import login from "@/views/login/login";
import square from "@/views/square";
import passage from "@/views/square/passage";
import resource from "@/views/square/resource";
import resume from "@/views/square/resume";
import search from "@/views/square/search";
import squarePost from "@/views/square/post";
import mess from "@/views/mess/mess";
import selfInfo from "@/views/self/info";
import selfPost from "@/views/self/post";
import selfComment from "@/views/self/comment";
import selfColumn from "@/views/self/column";
import selfFavorites from "@/views/self/favorites";
import selfFollow from "@/views/self/follow";
import selfFollowed from "@/views/self/fans";
import selfScore from "@/views/self/score";
import userInfo from "@/views/user/info";
import userPost from "@/views/user/post";
import userComment from "@/views/user/comment";
import userColumn from "@/views/user/column";
import userFavorites from "@/views/user/favorites";
import userFollow from "@/views/user/follow";
import userFollowed from "@/views/user/fans";
import selfEdit from "@/views/self/edit";
import error_500 from "@/views/500";
import error_404 from "@/views/404";
import error_403 from  "@/views/403"
import test from "@/views/square/test";
import todo from "@/views/todo/index";
import interview from "@/views/square/interview";
import adminLayout from "@/layout/adminLayout";
import adminAi from "@/views/admin/ai/index"
import adminArticle from "@/views/admin/article/index"
import adminArticleTag from "@/views/admin/articletag/index"
import adminComment from "@/views/admin/comment/index"
import adminFavorites from "@/views/admin/favorites/index"
import adminFollow from "@/views/admin/follow/index"
import adminFrontUser from "@/views/admin/frontuser/index"
import adminLike from "@/views/admin/like/index"
import adminRecord from "@/views/admin/record/index"
import adminScore from "@/views/admin/score/index"
import adminSession from "@/views/admin/session/index"
import adminTag from "@/views/admin/tag/index"
import adminTodo from "@/views/admin/todo/index"

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


//创建router实例对象，去管理一组一组的路由规则
const router = new VueRouter({
    // 指定路由工作模式
    mode: 'history',
    // 定义 hash 与组件之间的对应关系
    routes: [
        {
            path: '/',
            redirect: '/login',
        },
        // 登录
        {
            path: '/login',
            name: 'Login',
            component: login,
        },

        // 首页
        {
            path: '/home',
            redirect: '/square/index',
            meta: {
                requiresAuth: true
            }
        },
        // 广场
        {
            path: '/square',
            name: 'IndexLayout',
            component: indexLayout,
            redirect: '/square/index',
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: 'index',
                    name: 'SquareIndex',
                    component: square,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'passage',
                    name: 'passagePage',
                    component: passage,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'resource',
                    name: 'resourcePage',
                    component: resource,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'resume',
                    name: 'resumePage',
                    component: resume,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'interview',
                    name: 'interviewPage',
                    component: interview,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'search',
                    name: 'Search',
                    component: search,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'publish',
                    name: 'publishPage',
                    component: publish,
                    meta: {
                        requiresAuth: true
                    }
                },
                //动态路由到文章详情页
                {
                    path: 'post/:id',
                    name: 'postPage',
                    component: squarePost,
                    meta: {
                        requiresAuth: true
                    },
                }
            ]
        },


        //对话
        {
            path: '/mess',
            name: 'MessDefaultLayout',
            component: defaultLayout,
            redirect: '/mess/index',
            children: [
                {
                    path: 'index',
                    name: 'MessIndex',
                    component: mess,
                    meta: {
                        requiresAuth: true
                    }
                },
            ]
        },
        //待办
        {
            path: '/todo',
            name: 'TodoDefaultLayout',
            component: defaultLayout,
            redirect: '/todo/index',
            children: [
                {
                    path: 'index',
                    name: 'TodoIndex',
                    component: todo,
                    meta: {
                        requiresAuth: true
                    }
                },

            ]
        },
        // 个人中心
        {
            path: '/self',
            name: 'SelfLayout',
            component: selfLayout,
            redirect: '/self/index',
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: 'index',
                    name: 'SelfIndex',
                    redirect: '/self/info',
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'info',
                    name: 'Info',
                    component: selfInfo,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'post',
                    name: 'Post',
                    component: selfPost,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'comment',
                    name: 'Comment',
                    component: selfComment,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'column',
                    name: 'Column',
                    component: selfColumn,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'favorites',
                    name: 'Favorites',
                    component: selfFavorites,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'follow',
                    name: 'Follow',
                    component: selfFollow,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'followed',
                    name: 'Followed',
                    component: selfFollowed,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'score',
                    name: 'Score',
                    component: selfScore,
                    meta: {
                        requiresAuth: true
                    }
                }
            ]
        },

        {
            path: '/self',
            component: defaultLayout,
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: 'edit',
                    name: 'Edit',
                    component: selfEdit,
                    meta: {
                        requiresAuth: true
                    }
                },

            ]
        },

        // {
        //     path: '/user/:id',
        //     component: defaultLayout,
        //     meta: {
        //         requiresAuth: true
        //     },
        //     children: [
        //         {
        //             path: 'score',
        //             name: 'Score',
        //             component: userScore,
        //             meta: {
        //                 requiresAuth: true
        //             }
        //         }
        //     ]
        // },
        //公共用户
        {
            path: '/user/:id',
            component: userLayout,
            meta: {
                requiresAuth: true
            },
            redirect: '/user/:id/info',
            children: [
                {
                    path: 'info',
                    name: 'Info',
                    component: userInfo,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'post',
                    name: 'Post',
                    component: userPost,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'comment',
                    name: 'Comment',
                    component: userComment,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'column',
                    name: 'Column',
                    component: userColumn,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'favorites',
                    name: 'Favorites',
                    component: userFavorites,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'follow',
                    name: 'Follow',
                    component: userFollow,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'followed',
                    name: 'Followed',
                    component: userFollowed,
                    meta: {
                        requiresAuth: true
                    }
                },
            ]
        },

        //搜索
        {
            path: '/search',
            name: 'Search',
            component: indexLayout,
            redirect: '/search/:searchType',
            children: [
                {
                    path: ':searchType',
                    name: 'Search',
                    component: search,
                    meta: {
                        requiresAuth: true
                    }
                }
            ]
        },



        {
            path: '/admin',
            name: 'AdminLayout',
            component: adminLayout,
            redirect: '/admin/user',
            meta: {
                requiresAuth: true,
                admin:true
            },
            children: [
                {
                    path: 'user',
                    name: 'adminFrontUser',
                    component: adminFrontUser,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
                {
                    path: 'article',
                    name: 'adminArticle',
                    component: adminArticle,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
                {
                    path: 'ai',
                    name: 'adminAi',
                    component: adminAi,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
                {
                    path: 'tag',
                    name: 'adminTag',
                    component: adminTag,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
                {
                    path: 'todo',
                    name: 'adminTodo',
                    component: adminTodo,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
                {
                    path: 'articleTag',
                    name: 'adminArticleTag',
                    component: adminArticleTag,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
                {
                    path: 'session',
                    name: 'adminSession',
                    component: adminSession,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
                {
                    path: 'score',
                    name: 'adminScore',
                    component: adminScore,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
                {
                    path: 'follow',
                    name: 'adminFollow',
                    component: adminFollow,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
                {
                    path: 'favorites',
                    name: 'adminFavorites',
                    component: adminFavorites,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
                {
                    path: 'like',
                    name: 'adminLike',
                    component: adminLike,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
                {
                    path: 'record',
                    name: 'adminRecord',
                    component: adminRecord,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
                {
                    path: 'comment',
                    name: 'adminComment',
                    component: adminComment,
                    meta: {
                        requiresAuth: true,
                        admin:true
                    },
                },
            ]
        },

        {
            path: '/test',
            name: 'Test',
            component: test,
        },

        {
          name: 'error_403',
          path: '/403',
          component: error_403
        },

        {
            name: 'error_500',
            path: '/500',
            component: error_500,
        },
        // {
        //     name: 'error_500',
        //     path: '/500',
        //     component: defaultLayout,
        //     redirect: '/500',
        //     children: [
        //         {
        //             path: '',
        //             name: '500',
        //             component: error_500,
        //             meta: {
        //                 requiresAuth: true
        //             }
        //         },
        //
        //     ]
        // },




        // 404
        {
            path: '*',
            name: 'NotFound',
            component: error_404
        },
        //
        // {
        //     path: '*',
        //     name: 'NotFound',
        //     component: defaultLayout,
        //     redirect: '*',
        //     children: [
        //         {
        //             path: '',
        //             name: '404',
        //             component: error_404,
        //             meta: {
        //                 requiresAuth: true
        //             }
        //         },
        //
        //     ]
        // },


    ]
})
router.beforeEach((to, from, next) => {
    /**tips:需要在钩子函数内读取登录状态 */
    const userIsLogin = store.state.user.token;
    const role = store.state.user.userInfo.role;
    if (to.meta.requiresAuth) {
        if (userIsLogin) {
            if(to.meta.admin){
                if(role=='admin'){
                    next();
                }else{
                    next({
                        path: '/403'
                    })
                }
            }
            next()
        } else {
            // alert('请先登录再进行此操作!')
            //身份验证失败，跳转到登录页，并将要去的路由path作为参数传递给登录页
            //提示
            Message({
                message: '未登录或身份已过期，请重新登录!',
                type: 'warning'
            })
            next({
                path: '/login',
                /** 将刚刚要去的路由path（却无权限）作为参数，方便登录成功后直接跳转到该路由 */
                query: {redirect: to.fullPath}
            })
        }
    } else {
        next()
    }
})


// 向外共享路由模块
export default router
