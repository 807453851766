import request from '@/utils/request'

// 查询用户聊天记录管理列表
export function listRecord(query) {
  return request({
    url: `/system/messageRecord/list/${query.pageNum}/${query.pageSize}`,
    method: 'post',
    data: {
      sendId: query.sendId,
      acceptId: query.acceptId,
      sendTime: query.sendTime,
      contentType: query.contentType,
      isRead: query.isRead,
      content: query.content,
      isDeleted: query.isDeleted,
      params: query.params
    }
  })
}

// 查询用户聊天记录管理详细
export function getRecord(id) {
  return request({
    url: '/system/messageRecord/' + id,
    method: 'get'
  })
}

// 新增用户聊天记录管理
export function addRecord(data) {
  return request({
    url: '/system/messageRecord',
    method: 'post',
    data: data
  })
}

// 修改用户聊天记录管理
export function updateRecord(data) {
  return request({
    url: '/system/messageRecord',
    method: 'put',
    data: data
  })
}

// 删除用户聊天记录管理
export function delRecord(id) {
  return request({
    url: '/system/messageRecord/' + id,
    method: 'delete'
  })
}
