import request from '@/utils/request'

// 查询用户文章管理列表
export function listArticle(query) {
  return request({
    url: `/system/article/list/${query.pageNum}/${query.pageSize}`,
    method: 'post',
    data:{
      categoryId: query.categoryId,
      userId: query.userId,
      title: query.title,
      content: query.content,
      isDeleted: query.isDeleted,
    }
  })
}

// 查询用户文章管理详细
export function getArticle(id) {
  return request({
    url: '/system/article/' + id,
    method: 'get'
  })
}

// 新增用户文章管理
export function addArticle(data) {
  return request({
    url: '/system/article',
    method: 'post',
    data: data
  })
}

// 修改用户文章管理
export function updateArticle(data) {
  return request({
    url: '/system/article',
    method: 'put',
    data: data
  })
}

// 删除用户文章管理
export function delArticle(id) {
  return request({
    url: '/system/article/' + id,
    method: 'delete'
  })
}
