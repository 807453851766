import request from '@/utils/request'
import cookie from "js-cookie";

export default {


    //登录
    toLogin(loginInfo) {
        return request({
            url: `/user/login`,
            method: 'post',
            data: loginInfo
        })
    },

    //根据id获取用户信息
    getUserInfo(id) {
        return request({
            url: `/user/info/${id}`,
            method: 'get',
        })
    },

    //根据token获取用户信息
    getLoginUserInfo() {
        return request({
            url: `/user/myInfo`,
            method: 'get',
            headers: {
                'token': cookie.get('qa_token')
            }
        })
    },

    updateOnlineStatus(status){
        return request({
            url: `/user/updateOnlineStatus?status=${status}`,
            method: 'put',
        })
    },

    //注册
    toRegister(registerInfo) {
        return request({
            url: `/user/register`,
            method: 'post',
            data: registerInfo
        })
    },

    //修改信息
    updateUserInfo(userInfo) {
        return request({
            url: `/user/update`,
            method: 'post',
            data: userInfo
        })
    },

    //发送验证码
    sendCode(email) {
        return request({
            url: `/user/sendEmail/${email}`,
            method: 'get',
        })
    },


    //发送手机验证码
    sendPhoneCode(phone) {
        return request({
            url: `/user/sendPhone/${phone}`,
            method: 'get',
        })
    },


    //绑定邮箱
    bindEmail(email, code) {
        return request({
            url: `/user/bindEmail`,
            method: 'post',
            params: {
                email,
                code
            }
        })
    },

    //绑定手机
    bindPhone(phone, code) {
        return request({
            url: `/user/bindPhone`,
            method: 'post',
            params: {
                phone,
                code
            }
        })
    },

    //修改密码
    updatePassword(oldPassword, newPassword) {
        return request({
            url: `/user/updatePassword`,
            method: 'post',
            params: {
                oldPassword,
                newPassword
            }
        })
    },


    //关键词搜索用户
    searchUser(keyword, pageNo, pageSize) {
        return request({
            url: `/user/searchUser/${pageNo}/${pageSize}`,
            method: 'get',
            params: {
                keyword
            }
        })
    },
    getUserHotArticle(userId) {
        return request({
            url: `/user/hotArticle`,
            method: 'get',
            params: {
                userId
            }
        })
    },
    addScore() {
        return request({
            url: `/user/addScore`,
            method: 'post',
        })
    },

    //随机推荐用户
    randomUser() {
        return request({
            url: `/user/remmendUser`,
            method: 'get',
        })
    },

    scoreRank() {
        return request({
            url: `/user/scoreRank`,
            method: 'get',
        })
    },


    //获取用户的评论列表
    getUserCommentList(userId,pageNo,pageSize){
        return request({
            url: `/user/commentList/${userId}/${pageNo}/${pageSize}`,
            method: 'get',
        })
    },

    //获取用户的得分列表
    getUserScoreList(pageNo,pageSize){
        return request({
            url: `/score/list/${pageNo}/${pageSize}`,
            method: 'get',
        })
    },
}
