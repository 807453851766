<template>
  <div class="test" style="height:100vh;display: flex;">
    <!--侧边栏-->
    <sidebar class="sidebar" :class="{showSideBar:tohide!=true}"></sidebar>
    <div style="width: 100%;overflow-y:auto;" :class="{darkBg:theme=='dark',lightBg:theme=='light'}">
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <div class="backSideBarRight"  @click="openSideBar()">
      <button style="background: none;border: none;z-index: 999">
        <i class="iconfont icon-jiantou_yemian_xiangyou" style="color: deepskyblue" :class="{hide:tohide!=true}"></i>
      </button>
    </div>
    <div class="backSideBarLeft"  @click="openSideBar()" >
      <button style="background: none;border: none;z-index: 999">
        <i class="iconfont icon-jiantou_yemian_xiangzuo" style="color: deepskyblue" :class="{hide:tohide==true}"></i>
      </button>
    </div>
  </div>
</template>
<script>

import cookie from "js-cookie";
import sidebar from "@/components/sidebar";

export default {
  components: {
    sidebar
  },
  data() {
    return {
      theme:'light',
      tohide: false,
      model: '夜间',
      isNight: false,
      menuBackgroundColor: '',
      menuTextColor:'',
      menuActiveTextColor: '',
    }
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  methods: {
    openSideBar(){
      this.tohide = !this.tohide;
    },
  }
}
</script>
<style scoped>
.darkBg {
  background-color: rgb(17,17,17);
}


.item{
  margin-right: 5px;
}
a{
  text-decoration: none;
}
/* 改变elementui 侧边栏移入颜色 */
/*.el-menu-item:hover{*/
/*  background: rgb(142, 166, 236) !important;*/
/*}*/

.hide{
  display: none;
}
.backSideBarRight{
  position: absolute;
  top:300px;
  left: -10px;
  display: none;
  z-index: 999;
}
.backSideBarLeft{
  position: absolute;
  top:300px;
  left: 70px;
  z-index: 999;
  display: none;
}
.showSideBar{
  display: block !important;
}

.hide{
  display: none;
}
@media screen and (max-width: 768px) {

  .sidebar{
    display: none;
    z-index: 99;

  }
  .backSideBarRight{
    display: block;
  }
  .backSideBarLeft{
    display: block;
  }
}
</style>
