import request from '@/utils/request'
export default {
    getMessageRecord(acceptId){
        return request({
            url: '/message/list',
            method: 'get',
            params: {
                acceptId
            }
        })
    },

    hasUnReadMessage(){
        return request({
            url: '/message/hasUnread',
            method: 'get',
        })
    },

    //删除聊天记录
    deleteMessageRecord(acceptId){
        return request({
            url: '/message/delete',
            method: 'delete',
            params: {
                acceptId
            }
        })
    },

    //删除单条聊天记录
    deleteSingleMessageRecord(messageId){
        return request({
            url: '/message/deleteOne',
            method: 'delete',
            params: {
                messageId
            }
        })
    },


    //设为已读
    readMessage(acceptId){
        return request({
            url: '/message/read',
            method: 'put',
            params: {
                acceptId
            }
        })
    }
}
