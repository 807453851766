<template>
  <el-menu
      :default-active="$route.path.substring(0, $route.path.lastIndexOf('/'))"
      class="el-menu-vertical-demo"
      :class="{darkBorder:isNight}"
      :background-color="menuBackgroundColor"
      :text-color="menuTextColor"
      :active-text-color="menuActiveTextColor"
      style="width: 100px;height: 100%;position: relative;">
    <el-menu-item v-for="(item,index) in sidebar" @click="handleSelect(item.to)" :key="index" :index="item.index">
      <el-badge is-dot v-if="item.name=='对话'&&hasUnreadMessage=='true'">
        <div>
          <i class="iconfont item" :class="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </div>
      </el-badge>
      <div v-else>
        <i class="iconfont item" :class="item.icon"></i>
        <span slot="title">{{ item.name }}</span>
      </div>

    </el-menu-item>

    <el-menu-item v-if="userInfo.role=='admin'" @click="$router.push({
        path: '/admin/user'
      });"  index="manage">
      <div>
        <i class="iconfont item icon-guanli"></i>
        <span slot="title">管理</span>
      </div>
    </el-menu-item>

    <el-menu-item v-if="userInfo.role=='common'" style="position: absolute; bottom: 80px;height: 60px; width: 100%" @click="switchModel">
      <div>
        <i class="iconfont item icon-yueliang" :class="{'hide': isNight}"></i>
        <i class="iconfont item icon-taiyang3" :class="{'hide': !isNight}"></i>
        <span slot="title">{{ model }}</span>
      </div>
    </el-menu-item>
    <el-menu-item style="position: absolute; bottom: 20px;height: 60px; width: 100%" @click.native="logout()"
                  index="logout">
      <div>
        <i class="iconfont item icon-tuichu1"></i>
        <span slot="title">退出</span>
      </div>
    </el-menu-item>
  </el-menu>
</template>
<script>


import userApi from "@/api/user";

export default {
  data() {
    return {
      hasUnreadMessage: false,
      model: '夜间',
      isNight: false,
      menuBackgroundColor: '',
      menuTextColor: '',
      menuActiveTextColor: '',
      sidebar: [
        {name: '广场', index: '/square', to: '/square/index', icon: 'icon-guangchang'},
        {name: '对话', index: '/mess', to: '/mess/index', icon: 'icon-duihua'},
        {name: '待办', index: '/todo', to: '/todo/index', icon: 'icon-beiwanglu1'},
        {name: '我的', index: '/self', to: '/self/info', icon: 'icon-shouye'},
      ]
    }
  },
  watch: {
    '$store.state.user.hasUnreadMessage': {
      handler: function (val) {
        this.hasUnreadMessage = val;
      },
      immediate: true
    },
    '$store.state.user.theme': {
      handler: function (val) {
        this.isNight = val == 'dark';
        this.model = this.isNight ? "白天" : "夜晚";
        if (val == 'dark') {
          this.menuTextColor = '#fff'
          this.menuBackgroundColor = 'rgb(17,17,17)'
          this.menuActiveTextColor = '#409EFF'
        } else {
          this.menuTextColor = ''
          this.menuBackgroundColor = ''
          this.menuActiveTextColor = ''
        }
      },
      immediate: true
    }
  },
  methods: {
    handleSelect(path) {
      this.$router.push({
        path: path
      });
    },
    logout() {
      this.$confirm('确定退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'logout-message-box'
      }).then(() => {
        //更新在线状态
        userApi.updateOnlineStatus(0).then(res=>{
          this.$message({
            type: "success",
            duration: 1000,
            message: "登出成功！",
          });
          this.$router.replace("/login");
          this.$store.dispatch("user/LOGOUT");
          if (!res.data.success){
            console.log(res);
          }
        })
        // cookie.remove('token');
      }).catch(() => {
        this.$message({
          type: 'info',
          duration: 1000,
          message: '已取消退出'
        });
      });
    },
    switchModel() {
      this.isNight = !this.isNight;
      this.model = this.isNight ? "白天" : "夜晚";
      const theme = this.isNight ? 'dark' : 'light';
      this.$store.state.user.theme = theme;
      //存入localstorage
      window.localStorage.setItem('theme', theme);

    }
  },
  mounted() {
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    }
  },
}
</script>
<style scoped>
.darkBorder {
  border-right: rgb(62, 62, 62) solid 1px
}

.item {
  margin-right: 5px;
}

a {
  text-decoration: none;
}

/* 改变elementui 侧边栏移入颜色 */
/*.el-menu-item:hover{*/
/*  background: rgb(142, 166, 236) !important;*/
/*}*/
.el-menu-vertical-demo >>> .el-badge__content.is-fixed.is-dot {
  right: 5px !important;
  top: 18px !important;
}

.hide {
  display: none;
}

@media screen and (max-width: 600px) {
  .logout-message-box {
    width: 300px !important;
  }
}


</style>
