import request from '@/utils/request'
export default {
    //获取所有关注用户
    getFollowList(pageNo,pagesize) {
        return request({
            url: `/follow/follow/list/${pageNo}/${pagesize}`,
            method: 'get'
        })
    },

    getFollowListById(userId,pageNo,pagesize) {
        return request({
            url: `/follow/follow/list/${userId}/${pageNo}/${pagesize}`,
            method: 'get'
        })
    },

    //获取所有粉丝
    getFansList(pageNo,pagesize) {
        return request({
            url: `/follow/followed/list/${pageNo}/${pagesize}`,
            method: 'get'
        })
    },

    getFansListById(userId,pageNo,pagesize) {
        return request({
            url: `/follow/followed/list/${userId}/${pageNo}/${pagesize}`,
            method: 'get'
        })
    },

    //关注/取关用户
    followOrUnFollow(userId) {
        return request({
            url: `/follow/${userId}`,
            method: 'post'
        })
    },
}
